const urlRouter = {
  home: '/',
  login: '/login',
  team: '/page-team',
  invitation: '/page-invitation',
  dashboard: '/',
  memberJourney: '/member-journey',
  lastLocation: '/last-location',
  customer: '/customer',
  lookup: '/lookup',
  report: '/report',
  checkFile: '/file/:doctype/:category/:id/:name/:page',
  notificationDetail: '/notifications-detail',
  notification: '/notification',

  // task
  taskMaster: '/task',
  createTask: '/task/create',
  editTask: '/task/edit/:id',
  taskType: '/task-type',
  taskMonitoring: '/task-monitoring',
  taskMonitoringDetailResult: '/task-monitoring/:id/:slug-task',

  taskSetting: '/task-setting',

  // item
  itemDashboard: '/item/dashboard',
  itemList: '/item-list',
  itemCreate: '/add-item',
  itemEdit: 'edit-item/:id/edit',

  // member
  memberMaster: '/member',
  memberPrivilage: '/member-privilage',
  memberGroup: '/group-member',
  memberSetting: '/member-setting',

  // attendance
  attendaceMonitoring: '/attendance/attendance-monitoring',
  attendaceSetting: '/attendance/attendance-settings',

  // cash advance
  cashAdvance: '/cash-advance',
  cashAdvanceTransaction: '/cash-advanced/transaction',

  // task discussion
  taskDiscussion: '/task-discussion',
  taskDiscussionDrawer: '/task-discussion/drawer',

  // builder
  builderDashboard: '/builder/dashboard-builder',
  builderReport: '/builder/report-builder',
  builderReportCreate: '/builder/report-builder/create',
  builderReportEdit: '/builder/report-builder/:id/:name/edit',

  // development
  devButton: '/development/button',
  devTypography: '/development/typography',


  // item
  dashboardItem: '/item/dashboard',

  // DESIGN SYSTEM
  DESIGN_SYSTEM: '/design-system',
  DESIGN_SYSTEM_TYPOGRAPHY: '/design-system/typography',
  DESIGN_SYSTEM_COLOR_GUIDANCE: '/design-system/color-guidance',
  DESIGN_SYSTEM_BUTTONS: '/design-system/buttons',
};

export default urlRouter;