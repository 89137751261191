import React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { Flex, Image, Text } from '@chakra-ui/react'
import {
    IC_ATTENDANCE_IN,
    IC_ATTENDANCE_OUT,
    IC_CHECK_IN,
    IC_CHECK_OUT,
    IC_AUTO_UPDATE,
    IC_DRAFT,
    IC_RESULT,
    IC_OUT_ZONE,
} from '../../../assets'

interface JourneyItemProps {
    mode: string
    time: string
    address: string
    label: string
}

const JourneyItem = (props: JourneyItemProps) => {
    const { t } = useTranslation()
    const { mode, time, address, label } = props

    function handleIcon() {
        switch (mode) {
            case "ABSEN IN":
                return IC_ATTENDANCE_IN
            case "ABSEN OUT":
                return IC_ATTENDANCE_OUT
            case "CHECK IN":
                return IC_CHECK_IN
            case "CHECK OUT":
                return IC_CHECK_OUT
            case "AUTO UPDATE":
                return IC_AUTO_UPDATE
            case "DRAFT":
                return IC_DRAFT
            case "RESULT":
                return IC_RESULT
            case "LIVE TRACKING OUT":
                return IC_OUT_ZONE
        }
    }

    function handleBorderColor() {
        switch (mode) {
            case "ABSEN IN":
                return '#0079d2'
            case "ABSEN OUT":
                return '#0079d2'
            case "CHECK IN":
                return '#23843e'
            case "CHECK OUT":
                return '#236d84'
            case "AUTO UPDATE":
                return '#505f75'
            case "DRAFT":
                return '#a5a821'
            case "RESULT":
                return '#23843e'
            case "LIVE TRACKING OUT":
                return '#ba1a1a'
        }
    }

    function handleText() {
        switch (mode) {
            case "ABSEN IN":
                return t('perjalanan_absen_masuk')
            case "ABSEN OUT":
                return t('perjalanan_absen_keluar')
            case "CHECK IN":
                return t('perjalanan_check_in')
            case "CHECK OUT":
                return t('perjalanan_check_out')
            case "AUTO UPDATE":
                return t('perjalanan_auto_update')
            case "DRAFT":
                return t('perjalanan_draft')
            case "RESULT":
                return t('perjalanan_hasil_tugas')
            case "LIVE TRACKING OUT":
                return t('perjalanan_keluar_zona_tracking')
        }
    }

    function handleLabelTrackingOut() {
        return  mode === 'LIVE TRACKING OUT' && label
                    ?   <Text fontSize='14px' fontWeight='500'>({label})</Text>
                    :   null 
    }

    function handleDate() {
        return format(new Date(time), 'HH:mm')
    }

    return (
        <Flex
            justifyContent="space-between"
            gap="24px"
            borderLeftColor={handleBorderColor()}
            borderLeftWidth="3px"
            pl="30px"
            pb="24px"
            ml="10.5px"
            position="relative"
        >
            <Image
                src={handleIcon()}
                height="24px"
                width="24px"
                position="absolute"
                left="-13.5px"
            />
            <Flex direction="column" gap="4px">
                <Text fontSize='14px' fontWeight='500'>
                    {handleText()}
                </Text>
                {handleLabelTrackingOut()}
                <Text fontSize='14px'>{address}</Text>
            </Flex>
            <Flex>
                <Text fontSize='12px'>{handleDate()}</Text>
            </Flex>
        </Flex>
    )
}

export default JourneyItem