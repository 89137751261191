import { Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/react'

interface JInputProps extends InputProps {
    leftIcon?: any
    rightIcon?: any
    wrapperWidth?: string
    _ref?: any
}

const JInput = (props: JInputProps) => {
    return (
        <InputGroup width={props.wrapperWidth}>
            {props.leftIcon && <InputLeftElement children={props.leftIcon} />}
            <Input
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                fontSize={'label-large'}
                {...props}
            />
            {props.rightIcon && <InputRightElement children={props.rightIcon} />}
        </InputGroup>
    )
}

export default JInput
