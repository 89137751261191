import { Flex, InputGroup, useToast} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingModal, PageTitle } from '../../components'
import { getMember } from '../../redux'
import style from './member-master.module.css'
import { useTranslation } from 'react-i18next'
import MasterMemberTable from './MemberMasterTable'
import { JInput, JGoogleIcon, JToast } from '../../components'
import Device from './MasterMemberModels/Device'
import { deleteMemberDeviceApiProps, deleteMemberDeviceApi } from '../../apis/memberDeviceApi'
import { useMutation } from 'react-query'
const MemberMaster = () => {
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const toast = useToast()
  const {
    loadingGetMember,
    member,
    memberMetadata,
  } = useSelector((state: any) => state.member)
  const [openModalDevice, setOpenModalDevice] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [device, setDevice] = useState('')
  const [userName, setUserName] = useState('')
  const resetDevice = useMutation(handleResetDevice)
  const [configResetDevice, setConfigResetDevice] = useState<deleteMemberDeviceApiProps>({
    user_id: '',
    toaster: toaster
  })
  async function handleResetDevice(){
    if(configResetDevice.user_id !== ''){
        return await deleteMemberDeviceApi(configResetDevice);
    }
  }
  function toaster (code:string, msg:string){
    toast({
      position: 'top-right',
      render: () => (
          <JToast
              type={code === "200"? 'success' : 'error'}
              message={code !== "200" ? msg : t('master_anggota_reset_sukses',{userName: userName,})}
          />
      ),
      duration: 1500,
      isClosable: true,
    })
  }
  function handleReset(){
    resetDevice.mutate();
    setOpenModalDevice(false);
    setOpenModalConfirm(false);
    const data = {
      page: memberMetadata.current_page,
      show: 10,
    }
    dispatch(getMember(data))
  }
  function handleGetMember() {
    const data = {
      page: 1,
      show: 10,
    }
    dispatch(getMember(data))
  }
  function handlePagination(type: any) {
    const data = {
      page: type === 'next' ? memberMetadata.current_page + 1 : memberMetadata.current_page - 1,
      show: 10,
    }
    dispatch(getMember(data))
  }
  function handleSearch(keyword: "") {
    const data: any = {
      page: memberMetadata.current_page,
      show: 10,
    }
    if (keyword !== "") data.search = keyword
    dispatch(getMember(data))
  }
  function handleClickDevice(id: any){
    setTimeout(()=>{
      setOpenModalDevice(true);
    }, 1)
    setUserName(id.user.display_name);
    setConfigResetDevice({
      user_id: id.user.id,
      toaster: toaster
    })
    setDevice(id.user.device)
  }
  useEffect(() => {
    handleGetMember()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Flex className={`${style.member_master}`}>
      <PageTitle text={t("master_anggota_judul")} />

      <InputGroup width="max-content">
        <JInput
          type="search"
          leftIcon={<JGoogleIcon name='search' />}
          width="max-content"
          placeholder={t("master_anggota_pencarian_placeholder")}
          className={`${style.fs14}`}
          onKeyDown={(e: any) => e.code === "Enter" && handleSearch(e.target.value)}
        />
      </InputGroup>
      {!loadingGetMember && member !== null &&
        <MasterMemberTable
          data = {member}
          metadata = {memberMetadata}
          handlePrevious = {()=>handlePagination("prev")}
          handleNext = {()=>handlePagination("next")}
          isLoading = {loadingGetMember}
          handleClickDevice = {handleClickDevice}
        />
      }
      {
        <Device
          show={openModalDevice}
          name={device}
          userName = {userName}
          resetDevice = {handleReset}
          openModalConfirm = {openModalConfirm}
          setOpenModalConfirm = {setOpenModalConfirm}
          onClose={()=>setOpenModalDevice(false)}
        />
      }
      <LoadingModal show={loadingGetMember} />
    </Flex>
  )
}

export default MemberMaster