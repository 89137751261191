import { Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { BG_LOGIN, BG_LOGIN_FORM, lOGO_JARIVIS } from "../../assets";
import style from "./login.module.css";
import { FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { login, reset } from '../../redux'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { urlRouter } from "../../router";
import { LoadingModal } from "../../components";
import {GoogleLogin} from '@react-oauth/google';
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { isLoading, isSuccess } = useSelector((state: any) => state.auth)
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const {t} = useTranslation()

  useEffect(() => {
    if (isSuccess) {
      navigate(urlRouter.team, { replace: true })
      dispatch(reset())
      return
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <Flex minH="100vh" minW="100vw">
      <Flex backgroundImage={BG_LOGIN} className={style.login}>
      </Flex>
      <Flex className={style.login_form_wrapper}>
        <Flex className={style.login_banner_wrapper}>
          <Image src={BG_LOGIN_FORM} className={style.login_banner} />
        </Flex>
        <Flex className={style.login_form}>
          <Image src={lOGO_JARIVIS} className={style.jarivis_logo} />
          <Text textAlign="center">{t("masuk_label")}</Text>
          <GoogleLogin
            onSuccess={credentialResponse => {
              dispatch(login(credentialResponse.credential))
            }}
            onError={() => {
              console.error('Login Failed');
            }} 
          />

          <Flex className={style.login_form_footer}>
            <Flex className={style.social_button_wrapper}>
              <IconButton 
                as="a"
                href="https://www.linkedin.com/company/jarivis"
                target="_blank"
                icon={<FaLinkedinIn />} 
                variant="outline" 
                className={style.social_button} 
                aria-label="linkdin" 
              />
              <IconButton 
                as="a"
                href="https://www.instagram.com/jari.visibility/"
                target="_blank"
                icon={<FaInstagram />} 
                variant="outline" 
                className={style.social_button} 
                aria-label="instagram" 
              />
            </Flex>
            <Text textAlign="center">{t("masuk_footer_kontak")} ©2021 Jarivis</Text>
          </Flex>
        </Flex>
      </Flex>

      <LoadingModal show={isLoading} />
    </Flex>
  );
};

export default Login;