import React from 'react'
import { JGoogleIcon } from '../../../components'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'

interface ISearchbarMember {
    onChange: any
    value: string
    onSubmit: any
}

const SearchbarMember = (props: ISearchbarMember) => {
    const { onChange, value, onSubmit } = props

    return (
        <InputGroup>
            <InputRightElement
                children={<JGoogleIcon name={'search'} cursor={'pointer'} onClick={() => onSubmit()} />}
            />
            <Input
                type="search"
                onChange={onChange}
                onKeyDown={(e: any) => e.code === "Enter" && onSubmit()}
                value={value}
                height="40px"
            />
        </InputGroup>
    )
}

export default SearchbarMember