import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { taskSettingEditRadiusApi, taskSettingToggleCheckinApi, taskTypeApi, urlApi } from '../../apis';

const initialState = {
  // task type
  loadingGetTaskType: false,
  successGetTaskType: false,
  errorGetTaskType: false,
  messageGetTaskType: '',
  taskType: null,
  taskTypeMetadata: null,

  // task setting checkin
  loadingUpdateTaskSettingCheckIn: false,
  successUpdateTaskSettingCheckIn: false,
  errorUpdateTaskSettingCheckIn: false,
  messageUpdateTaskSettingCheckIn: '',

  // task setting radius
  loadingUpdateTaskSettingRadius: false,
  successUpdateTaskSettingRadius: false,
  errorUpdateTaskSettingRadius: false,
  messageUpdateTaskSettingRadius: '',
}

interface getTaskTypeProps {
  page: any,
  show: any,
  order_by: any,
  order_type: any,
  search: any,
  is_active: any,
}

export const getTaskType = createAsyncThunk(urlApi.taskType, async (data: getTaskTypeProps, thunkAPI) => {
  const { 
    page,
    show,
    order_by,
    order_type,
    search,
    is_active,
  } = data
  try {
    const team = localStorage.getItem('current_team') || ""
    const response: any = await taskTypeApi(team, page, show, order_by, order_type, search, is_active)
    return response
  } catch (error) {
    const message = "error get task type"
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateTaskSettingCheckIn = createAsyncThunk(urlApi.taskSettingToggleCheckIn, async (isOn: number, thunkAPI) => {
  try {
    const response: any = await taskSettingToggleCheckinApi(isOn)
    return response
  } catch (error) {
    const message = "error edit task setting"
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateTaskSettingRadius = createAsyncThunk(urlApi.taskSettingEditRadius, async (radius: number, thunkAPI) => {
  try {
    const response: any = await taskSettingEditRadiusApi(radius)
    return response
  } catch (error) {
    const message = "error edit task setting radius"
    return thunkAPI.rejectWithValue(message)
  }
})

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // task type
    resetGetTaskType: (state) => {
      state.loadingGetTaskType = false
      state.successGetTaskType = false
      state.errorGetTaskType = false
      state.messageGetTaskType = ''
      state.taskType = null
      state.taskTypeMetadata = null
    },

    // task setting check in
    resetUpdateTaskSettingCheckin: (state) => {
      state.loadingUpdateTaskSettingCheckIn = false
      state.successUpdateTaskSettingCheckIn = false
      state.errorUpdateTaskSettingCheckIn = false
      state.messageUpdateTaskSettingCheckIn = ''
    },

    // task setting radius
    resetUpdateTaskSettingRadius: (state) => {
      state.loadingUpdateTaskSettingRadius = false
      state.successUpdateTaskSettingRadius = false
      state.errorUpdateTaskSettingRadius = false
      state.messageUpdateTaskSettingRadius = ''
    }
  },
  extraReducers: (builder) => {
    builder
      // task type
      .addCase(getTaskType.pending, (state) => {
        state.loadingGetTaskType = true
      })
      .addCase(getTaskType.fulfilled, (state, action) => {
        state.loadingGetTaskType = false
        state.successGetTaskType = true
        state.taskType = action.payload.data
        state.taskTypeMetadata = action.payload.metadata
      })
      .addCase(getTaskType.rejected, (state, action: any) => {
        state.loadingGetTaskType = false
        state.errorGetTaskType = true
        state.messageGetTaskType = 'error get task type'
      })

      // task setting checkin
      .addCase(updateTaskSettingCheckIn.pending, (state) => {
        state.loadingUpdateTaskSettingCheckIn = true
      })
      .addCase(updateTaskSettingCheckIn.fulfilled, (state, action) => {
        state.loadingUpdateTaskSettingCheckIn = false
        state.successUpdateTaskSettingCheckIn = true
      })
      .addCase(updateTaskSettingCheckIn.rejected, (state, action: any) => {
        state.loadingUpdateTaskSettingCheckIn = false
        state.errorUpdateTaskSettingCheckIn = true
        state.messageUpdateTaskSettingCheckIn = 'error task setting checkin'
      })

      // task setting radius
      .addCase(updateTaskSettingRadius.pending, (state) => {
        state.loadingUpdateTaskSettingRadius = true
      })
      .addCase(updateTaskSettingRadius.fulfilled, (state, action) => {
        state.loadingUpdateTaskSettingRadius = false
        state.successUpdateTaskSettingRadius = true
      })
      .addCase(updateTaskSettingRadius.rejected, (state, action: any) => {
        state.loadingUpdateTaskSettingRadius = false
        state.errorUpdateTaskSettingRadius = true
        state.messageUpdateTaskSettingRadius = 'error task setting radius'
      })
  },
})

export const { resetGetTaskType, resetUpdateTaskSettingCheckin,resetUpdateTaskSettingRadius } = taskSlice.actions
export default taskSlice.reducer