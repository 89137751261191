import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { loginApi, urlApi } from '../../apis';

const initialState = {
  token: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const login = createAsyncThunk(urlApi.auth, async (token: any, thunkAPI) => {
  try {
    const response: any = await loginApi(token)
    localStorage.setItem('user_data', JSON.stringify(response))
    return response
  } catch (error) {
    const message = "error login"
    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    logout: (state) => {
      state.token = null
      localStorage.removeItem('access_token')
      localStorage.removeItem('current_team')
      localStorage.removeItem('current_team_data')
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.token) {
          state.isLoading = false
          state.isSuccess = true
          state.token = action.payload.token
          localStorage.setItem('access_token', action.payload.token)
        } else {
          state.isLoading = false
          state.isError = true
          state.message = action.payload.message
        }
      })
      .addCase(login.rejected, (state, action: any) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.token = null
      })
  },
})

export const { reset, logout } = authSlice.actions
export default authSlice.reducer