import {
    Divider,
    Flex,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Switch,
    useToast,
    Avatar,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete'
import {
    AttendanceSetting_Create_AttendanceLocation_ApiProps,
} from '../../apis/attendanceSettingApi'
import {
    JButton,
    JGoogleIcon,
    JInput,
    JTypography
} from '../../components'
import JToast from '../../components/JToast/JToast'
import {
    useAttendaceSetting_get_attendanceLocationList,
    useAttendaceSetting_get_memberList,
    useAttendaceSetting_get_configuration,
    useAttendaceSetting_update_configuration,
    useAttendaceSetting_update_status,
    useAttendaceSetting_update_attendanceLocationStatus,
    useAttendaceSetting_create_location,
    useAttendaceSetting_delete_location,
    useAttendaceSetting_update_location,
} from '../../query'
import { Configuration } from '../../query/attendanceSettingQuery'
import Area from './components/Area'
import { 
    AttendanceMandatory as AttendanceMandatoryModal,
    WorkingHours as WorkingHoursModal,
    AttendanceLocation as AttendanceLocationModal,
    LocationStatus as LocationStatusModal,
    DeleteLocation as DeleteLocationModal,
    SpecifyLocation as SpecifyLocationModal,
    DefineMember as DefineMemberModal,
    LocationInformation as LocationInformationModal,
} from './modals'

const AttendanceSetting = () => {
    const { t } = useTranslation()
    const toast = useToast()

    const [searchPlace, setSearchPlace] = useState('')
    const [searchChooseMember, setSearchChooseMember] = useState('')
    const [searchArea, setSearchArea] = useState('')
    const [area, setArea] = useState<any>(null)
    const [isUpdateFlow, setIsUpdateFlow] = useState<any>(null)

    const [modalToggleWajibAbsen, setModalToggleWajibAbsen] = useState(false)
    const [modalToggleJamKerja, setModalToggleJamKerja] = useState(false)
    const [modalToggleLokasiAbsen, setModalToggleLokasiAbsen] = useState(false)
    const [modalToggleLokasiAbsenStatus, setModalToggleLokasiAbsenStatus] = useState(false)
    const [modalToggleDeleteLocation, setModalToggleDeleteLocation] = useState(false)

    const [modalCariLokasi, setModalCariLokasi] = useState(false)
    const [modalTentukanAnggota, setModalTentukanAnggota] = useState(false)
    const [modalLabelLokasi, setModalLabelLokasi] = useState(false)

    const [configuration, setConfiguration] = useState<Configuration>({
        is_absence_strict_location: 0,
        is_absence_mandatory_for_check_in: 0,
        is_absence_strict_time: 0,
        limit_clock_in: '',
        limit_clock_out: '',
    })
    const [initialArea, setInitialArea] = useState<AttendanceSetting_Create_AttendanceLocation_ApiProps>({
        label: '',
        radius: 50,
        latitude: -6.2213866,
        longitude: 106.7796252,
        address: '',
        member_profile_id: [],
    })

    const {
        data: attendanceSettingMemberList
    } = useAttendaceSetting_get_memberList()

    const {
        data: attendanceSettingAttendanceLocationList,
        refetch: getAttendanceSettingAttendanceLocationList,
        isFetching: fetchingAttendanceSettingAttendanceLocationList
    } = useAttendaceSetting_get_attendanceLocationList()

    const {
        data: attendanceSettingConfiguration,
        isFetching: fetchingAttendanceSettingConfiguration,
        refetch: getAttendanceSettingConfiguration,
    } = useAttendaceSetting_get_configuration()

    const {
        refetch: updateAttendanceSettingStatus,
        isFetching: fetchingUpdateAttendanceSettingStatus,
    } = useAttendaceSetting_update_status({
        is_absence_strict_location: configuration.is_absence_strict_location === 1 ? 0 : 1
    })

    const {
        refetch: updateAttendanceSettingMandatoryCheckIn,
        isFetching: fetchingUpdateAttendanceSettingMandatoryCheckIn,
    } = useAttendaceSetting_update_configuration({
        ...configuration,
        is_absence_mandatory_for_check_in: configuration.is_absence_mandatory_for_check_in === 1 ? 0 : 1,
    })

    const {
        refetch: updateAttendanceSettingStrictTime,
        isFetching: fetchingUpdateAttendanceSettingStrictTime,
    } = useAttendaceSetting_update_configuration({
        ...configuration,
        is_absence_strict_time: configuration.is_absence_strict_time === 1 ? 0 : 1,
    })

    const {
        refetch: updateAttendanceSettingLimitClock,
        isFetching: fetchingUpdateAttendanceSettingLimitClock,
    } = useAttendaceSetting_update_configuration({
        ...configuration,
    })

    const {
        refetch: updateAttendanceSettingLocationStatus,
        isFetching: fetchingUpdateAttendanceSettingLocationStatus,
    } = useAttendaceSetting_update_attendanceLocationStatus({
        absence_location_id: area?.id,
        is_active: area?.is_active === 0 ? 1 : 0
    })

    const {
        refetch: createAttendanceSettingLocation,
        isFetching: fetchingCreateAttendanceSettingLocation,
    } = useAttendaceSetting_create_location(initialArea)

    const {
        refetch: deleteAttendanceSettingLocation,
        isFetching: fetchingDeleteAttendanceSettingLocation,
    } = useAttendaceSetting_delete_location(area)

    const {
        refetch: updateAttendanceSettingLocation,
        isFetching: fetchingUpdateAttendanceSettingLocation
    } = useAttendaceSetting_update_location({
        ...initialArea,
        absence_location_id: isUpdateFlow?.id
    })

    function handleSelectMember(user: any) {
        const userId = user.id
        const tempMembers: any[] = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.id === userId) {
                    member.checked = !member.checked
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                member_profile_id: tempMembers
            }
        })
    }

    function handleSelectGroupMember(group: any) {
        const groupCheck = group.checked
        const groupMember = group.members.map((member: any) => member.id)
        const tempMembers: any = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (groupMember.includes(member.id)) {
                    member.checked = !groupCheck
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                member_profile_id: tempMembers
            }
        })
    }

    function handleDeleteMember(member: any) {
        const memberId = member?.id
        const tempMembers: any = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.id === memberId) {
                    member.checked = !member.checked
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                member_profile_id: tempMembers
            }
        })
    }

    function RenderSelectedMembers() {
        const selectedMembers: any = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.checked === true) {
                    if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                }
            })
        })

        return (
            <Flex
                direction={'column'}
                gap={'16px'}
            >
                <JTypography size={'title-medium'}>{t('pengaturan_anggota_anggota_terdaftar')}</JTypography>
                {selectedMembers.map((member: any) => (
                    <Flex
                        key={member.id}
                        gap={'24px'}
                        alignItems={'center'}
                    >
                        <Avatar name={member.display_name} />
                        <Flex
                            direction={'column'}
                            gap={'4px'}
                        >
                            <JTypography
                                size='title-medium'
                                wordBreak={'break-word'}
                            >
                                {member.display_name}
                            </JTypography>
                            <JTypography
                                size='body-medium'
                                wordBreak={'break-word'}
                            >
                                {member.username}
                            </JTypography>
                        </Flex>
                        <JGoogleIcon
                            name='delete'
                            cursor={'pointer'}
                            onClick={() => handleDeleteMember(member)}
                        />
                    </Flex>
                ))}
            </Flex>
        )
    }

    function RenderSelectedChooseMember() {
        const selectedMembers: any = []
        initialArea.member_profile_id.map((group: any) => {
            group.members.map((member: any) => {
                if (member.checked && selectedMembers.find((sm: any) => sm.id === member.id) === undefined) selectedMembers.push(member)
            })
        })
        return (
            <Flex alignItems={'center'}>
                {selectedMembers.map((member: any, index: number) => {
                    if (index < 3)
                        return (
                            <Avatar
                                width={'24px'}
                                height={'24px'}
                                key={index}
                                name={member.display_name}
                                src={member.photo_url}
                                marginLeft={'-6px'}
                            />
                        )
                })}
                <JTypography
                    size={'label-medium'}
                    marginLeft={'16px'}
                >
                    {t('pengaturan_absen_area_anggota_ditetepankan', { total: selectedMembers.length })}
                </JTypography>
            </Flex>
        )
    }

    async function handleCreateLocation() {
        await createAttendanceSettingLocation()
        await getAttendanceSettingAttendanceLocationList()
        setModalLabelLokasi(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t('pengaturan_absen_sukses_tambahkan_lokasi_absen_area')}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    function handlePrepareaUpdate(area: any) {
        const tempMembers = attendanceSettingMemberList
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if (area.members.find((m: any) => m.id === member.id)) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
            if (group.members.find((m: any) => m.checked === false) === undefined) {
                group.checked = true
            } else {
                group.checked = false
            }
        })

        setInitialArea({
            address: area.address,
            label: area.label,
            latitude: area.latitude,
            longitude: area.longitude,
            member_profile_id: tempMembers,
            radius: area.radius,
        })
        setIsUpdateFlow(area)
        setSearchPlace(area.address)
        setSearchChooseMember('')
        setModalCariLokasi(true)
    }

    async function handleUpdateLocation() {
        await updateAttendanceSettingLocation()
        await getAttendanceSettingAttendanceLocationList()
        setModalLabelLokasi(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t('pengaturan_absen_sukses_update_lokasi_absen_area')}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    async function submitAttendanceMandatoryModal() {
        await updateAttendanceSettingMandatoryCheckIn()
        await getAttendanceSettingConfiguration()
        setModalToggleWajibAbsen(false)
    }

    async function submitWorkingHoursModal() {
        await updateAttendanceSettingStrictTime()
        await getAttendanceSettingConfiguration()
        setModalToggleJamKerja(false)
    }

    async function submitAttendanceLocationModal() {
        await updateAttendanceSettingStatus()
        await getAttendanceSettingConfiguration()
        setModalToggleLokasiAbsen(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t(
                        configuration.is_absence_strict_location
                            ? 'pengaturan_absen_sukses_matikan_lokasi_absen'
                            : 'pengaturan_absen_sukses_nyalakan_lokasi_absen'
                    )}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    async function submitLocationStatusModal() {
        await updateAttendanceSettingStatus()
        await getAttendanceSettingConfiguration()
        setModalToggleLokasiAbsen(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t(
                        configuration.is_absence_strict_location
                            ? 'pengaturan_absen_sukses_matikan_lokasi_absen'
                            : 'pengaturan_absen_sukses_nyalakan_lokasi_absen'
                    )}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    async function submitDeleteLocationModal() {
        await deleteAttendanceSettingLocation()
        await getAttendanceSettingAttendanceLocationList()
        setModalToggleDeleteLocation(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t('pengaturan_absen_sukses_hapus_lokasi_absen_area')}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    function onNextSpecifyLocationModal() {
        setModalCariLokasi(false)
        setModalTentukanAnggota(true)
    }

    async function placesOnSelectSpecifyLocationModal(e: any) {
        const results = await geocodeByAddress(e)
        const latLng = await getLatLng(results[0])
        setInitialArea((prev: any) => {
            return {
                ...prev,
                latitude: latLng.lat,
                longitude: latLng.lng,
                address: e,
            }
        })
        setSearchPlace(e)
    }

    function onBackDefineMember() {
        setModalTentukanAnggota(false)
        setModalCariLokasi(true)
    }

    function onNextDefineMember() {
        setModalTentukanAnggota(false)
        setModalLabelLokasi(true)
    }

    function onChangeLabelLocation(e: any) {
        setInitialArea((prev: any) => {
            return {
                ...prev,
                label: e.target.value,
            }
        })
    }

    function onBackLocationInformation() {
        setModalLabelLokasi(false)
        setModalTentukanAnggota(true)
    }

    useEffect(() => {
        if (attendanceSettingConfiguration) setConfiguration(attendanceSettingConfiguration)
        if (attendanceSettingMemberList) {
            setInitialArea((prev: any) => {
                return {
                    ...prev,
                    member_profile_id: attendanceSettingMemberList
                }
            })
        }
    }, [attendanceSettingConfiguration, attendanceSettingMemberList])

    return (
        <Flex
            direction={'column'}
            gap={'24px'}
            width={'100%'}
        >
            <JTypography size='title-medium'>
                {t('pengaturan_absensi_judul')}
            </JTypography>
            <Flex
                padding={'24px 16px'}
                borderRadius={'16px'}
                backgroundColor={'surfacePlus1'}
                gap={'24px'}
                width={'100%'}
            >
                <Tabs width={'100%'}>
                    <Flex gap={'24px'}>
                        <TabList
                            flexDirection={'column'}
                            width={'55%'}
                            border={'none'}
                        >
                            <Tab
                                as={Flex}
                                padding={'14px 16px'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                _selected={{
                                    borderLeftColor: 'primary',
                                    borderLeftWidth: '2px',
                                }}
                                cursor={'pointer'}
                            >
                                <JTypography size={'label-large'}>{t('pengaturan_absensi_umum')}</JTypography>
                                <JGoogleIcon name={'navigate_next'} />
                            </Tab>
                            <Tab
                                as={Flex}
                                padding={'14px 16px'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                _selected={{
                                    borderLeftColor: 'primary',
                                    borderLeftWidth: '2px',
                                }}
                                cursor={'pointer'}
                            >
                                <JTypography size={'label-large'}>{t('pengaturan_absensi_lokasi_absen')}</JTypography>
                                <Flex
                                    gap={'16px'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-small'}>{t('pengaturan_absensi_lokasi_absen_aktif')}</JTypography>
                                    <JGoogleIcon name={'navigate_next'} />
                                </Flex>
                            </Tab>
                        </TabList>

                        <TabPanels
                            borderLeftColor={'outline'}
                            borderLeftWidth={'1px'}
                            paddingLeft={'24px'}
                        >
                            {/* tab general */}
                            <TabPanel padding={'0px'}>
                                <JTypography size={'title-medium'}>{t('pengaturan_absensi_umum')}</JTypography>
                                <Flex
                                    marginTop={'38.5px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-medium'}>{t('pengaturan_absensi_wajib_absen')}</JTypography>
                                    <Switch
                                        isChecked={configuration?.is_absence_mandatory_for_check_in === 1
                                            ? true
                                            : false
                                        }
                                        onClickCapture={() => setModalToggleWajibAbsen(true)}
                                    />
                                </Flex>
                                <JTypography size={'body-medium'} marginTop={'22.5px'}>{t('pengaturan_absensi_lokasi_absen_subjudul')}</JTypography>
                                <Divider marginTop={'24px'} borderColor={'outline'} />
                                <Flex
                                    marginTop={'30.5px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-medium'}>{t('pengaturan_absensi_jam_kerja')}</JTypography>
                                    <Switch
                                        isChecked={configuration?.is_absence_strict_time === 1
                                            ? true
                                            : false
                                        }
                                        onClickCapture={() => setModalToggleJamKerja(true)}
                                    />
                                </Flex>
                                <JTypography size={'body-medium'} marginTop={'22.5px'}>{t('pengaturan_absensi_jam_kerja_sublabel')}</JTypography>
                                <Flex
                                    marginTop={'16px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-small'}>{t('pengaturan_absensi_batas_absen_masuk')}</JTypography>
                                    <JInput
                                        type={'time'}
                                        wrapperWidth={'min-content'}
                                        value={configuration?.limit_clock_in}
                                        onChange={(e: any) => {
                                            setConfiguration((prev: any) => {
                                                return {
                                                    ...prev,
                                                    limit_clock_in: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </Flex>
                                <Flex
                                    marginTop={'16px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-small'}>{t('pengaturan_absensi_batas_absen_keluar')}</JTypography>
                                    <JInput
                                        type={'time'}
                                        wrapperWidth={'min-content'}
                                        value={configuration?.limit_clock_out}
                                        onChange={(e: any) => {
                                            setConfiguration((prev: any) => {
                                                return {
                                                    ...prev,
                                                    limit_clock_out: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </Flex>

                                {
                                    attendanceSettingConfiguration?.limit_clock_in !== configuration.limit_clock_in ||
                                        attendanceSettingConfiguration?.limit_clock_out !== configuration.limit_clock_out
                                        ? (
                                            <Flex width={'100%'}>
                                                <JButton
                                                    marginTop={'32px'}
                                                    size={'normal'}
                                                    variant={'j-primary'}
                                                    marginX={'auto'}
                                                    onClick={async () => {
                                                        await updateAttendanceSettingLimitClock()
                                                        await getAttendanceSettingConfiguration()
                                                    }}
                                                    isLoading={fetchingUpdateAttendanceSettingLimitClock}
                                                >
                                                    {t('pengaturan_absen_simpan')}
                                                </JButton>
                                            </Flex>
                                        )
                                        : null
                                }
                            </TabPanel>

                            {/* tab attendance location */}
                            <TabPanel
                                as={Flex}
                                flexDirection={'column'}
                                padding={'0px'}
                                gap={'24px'}
                            >
                                <Flex justifyContent={'space-between'} alignItems={'center'}>
                                    <JTypography size={'title-medium'}>{t('pengaturan_absensi_lokasi_absen')}</JTypography>
                                    <Switch
                                        isChecked={configuration?.is_absence_strict_location === 1 ? true : false}
                                        onClickCapture={() => setModalToggleLokasiAbsen(true)}
                                    />
                                </Flex>
                                <JTypography size={'body-medium'} marginTop={'-15px'}>{t('pengaturan_absensi_lokasi_absen_subjudul')}</JTypography>
                                <Divider borderColor={'outline'} />
                                <Flex justifyContent={'space-between'} alignItems={'center'}>
                                    <JTypography size={'label-large'}>{t('pengaturan_absensi_daftar_lokasi_absen')}</JTypography>
                                    <JButton
                                        variant={'j-primary'}
                                        size={'normal'}
                                        leftIcon={<JGoogleIcon name={'add'} />}
                                        isDisabled={fetchingAttendanceSettingConfiguration || configuration?.is_absence_strict_location === 0}
                                        onClick={() => {
                                            const tempMembers = attendanceSettingMemberList
                                            tempMembers.map((group: any) => {
                                                group.checked = false
                                                group.members.map((member: any) => {
                                                    member.checked = false
                                                })
                                            })
                                            setSearchPlace('')
                                            setSearchChooseMember('')
                                            setIsUpdateFlow(null)
                                            setInitialArea((prev: any) => {
                                                return {
                                                    ...prev,
                                                    label: '',
                                                    radius: 50,
                                                    latitude: -6.2213866,
                                                    longitude: 106.7796252,
                                                    address: '',
                                                    member_profile_id: tempMembers,
                                                }
                                            })
                                            setModalCariLokasi(true)
                                        }}
                                    >
                                        <JTypography size={'label-large'}>{t('pengaturan_absensi_tambah_lokasi_absens')}</JTypography>
                                    </JButton>
                                </Flex>
                                <JInput
                                    type={'search'}
                                    placeholder={t('pengaturan_absensi_pencarian_lokasi_absen')}
                                    leftIcon={<JGoogleIcon name={'search'} />}
                                    value={searchArea}
                                    onChange={(e: any) => setSearchArea(e.target.value)}
                                />
                                <Flex direction={'column'} gap={'10px'}>
                                    {attendanceSettingAttendanceLocationList?.map((location: any, index: number) => {
                                        if (searchArea === '') {
                                            return (
                                                <Area
                                                    key={index}
                                                    {...location}
                                                    onClickSwitch={() => {
                                                        setArea(location)
                                                        setModalToggleLokasiAbsenStatus(true)
                                                    }}
                                                    onClickDelete={() => {
                                                        setArea(location)
                                                        setModalToggleDeleteLocation(true)
                                                    }}
                                                    onClickUpdate={() => handlePrepareaUpdate(location)}
                                                />
                                            )
                                        } else {
                                            if (location.label.toLowerCase().includes(searchArea)) {
                                                return (
                                                    <Area
                                                        key={index}
                                                        {...location}
                                                        onClickSwitch={() => {
                                                            setArea(location)
                                                            setModalToggleLokasiAbsenStatus(true)
                                                        }}
                                                        onClickDelete={() => {
                                                            setArea(location)
                                                            setModalToggleDeleteLocation(true)
                                                        }}
                                                        onClickUpdate={() => handlePrepareaUpdate(location)}
                                                    />
                                                )
                                            }
                                        }
                                    })}
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Flex>
                </Tabs>
            </Flex>

            <AttendanceMandatoryModal
                configuration={configuration}
                is_loading={fetchingUpdateAttendanceSettingMandatoryCheckIn}
                is_open={modalToggleWajibAbsen}
                on_cancel={() => setModalToggleWajibAbsen(false)}
                on_close={() => setModalToggleWajibAbsen(false)}
                on_toggle={submitAttendanceMandatoryModal}
            />

            <WorkingHoursModal
                configuration={configuration}
                is_loading={fetchingUpdateAttendanceSettingStrictTime}
                is_open={modalToggleJamKerja}
                on_cancel={() => setModalToggleJamKerja(false)}
                on_close={() => setModalToggleJamKerja(false)}
                on_toggle={submitWorkingHoursModal}
            />

            <AttendanceLocationModal
                configuration={configuration}
                is_loading={fetchingUpdateAttendanceSettingStatus}
                is_open={modalToggleLokasiAbsen}
                on_cancel={() => setModalToggleLokasiAbsen(false)}
                on_close={() => setModalToggleLokasiAbsen(false)}
                on_toggle={submitAttendanceLocationModal}
            />

            <LocationStatusModal
                area={area}
                is_loading={fetchingUpdateAttendanceSettingLocationStatus || fetchingAttendanceSettingAttendanceLocationList}
                is_open={modalToggleLokasiAbsenStatus}
                on_cancel={() => setModalToggleLokasiAbsenStatus(false)}
                on_close={() => setModalToggleLokasiAbsenStatus(false)}
                on_toggle={submitLocationStatusModal}
            />

            <DeleteLocationModal
                area={area}
                is_loading={fetchingDeleteAttendanceSettingLocation || fetchingAttendanceSettingAttendanceLocationList}
                is_open={modalToggleDeleteLocation}
                on_cancel={() => setModalToggleDeleteLocation(false)}
                on_close={() => setModalToggleDeleteLocation(false)}
                on_toggle={submitDeleteLocationModal}
            />

            <SpecifyLocationModal
                initial_area={initialArea}
                set_initial_area={setInitialArea}
                is_open={modalCariLokasi}
                on_close={() => setModalCariLokasi(false)}
                on_next={onNextSpecifyLocationModal}
                places_value={searchPlace}
                places_on_change={(e: any) => setSearchPlace(e)}
                places_on_select={placesOnSelectSpecifyLocationModal}
            />

            <DefineMemberModal
                group_on_check={(member: any) => handleSelectGroupMember(member)}
                initial_area={initialArea}
                is_open={modalTentukanAnggota}
                member_on_check={(user: any) => handleSelectMember(user)}
                on_back={onBackDefineMember}
                on_close={() => setModalTentukanAnggota(false)}
                on_next={onNextDefineMember}
                search_on_change={setSearchChooseMember}
                search_value={searchChooseMember}
                selected_members={RenderSelectedMembers}
            />

            <LocationInformationModal
                initial_area={initialArea}
                is_loading={isUpdateFlow ? fetchingUpdateAttendanceSettingLocation || fetchingAttendanceSettingAttendanceLocationList : fetchingCreateAttendanceSettingLocation || fetchingAttendanceSettingAttendanceLocationList}
                is_open={modalLabelLokasi}
                label_on_change={onChangeLabelLocation}
                label_value={initialArea?.label}
                on_back={onBackLocationInformation}
                on_close={() => setModalLabelLokasi(false)}
                on_submit={() => isUpdateFlow ? handleUpdateLocation() : handleCreateLocation()}
                render_selected_members={RenderSelectedChooseMember}
            />
        </Flex >
    )
}

export default AttendanceSetting
