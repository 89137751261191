import React, { useEffect } from 'react'
import { setDarkColor, setLightColor } from "./theme"
import { Router } from "./router"
import HotjarProvider from './configs/hotjar'
import { useColorMode } from '@chakra-ui/color-mode'

const App = () => {
    const { colorMode } = useColorMode()

    useEffect(() => {
        if (colorMode === 'light') {
            setLightColor()
        } else {
            setDarkColor()
        }
    }, [colorMode])

    return (
        <React.Fragment>
            <HotjarProvider />
            <Router />
        </React.Fragment>
    )
}

export default App