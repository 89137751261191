import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { BiChevronDown } from 'react-icons/bi'
import { FiMoon, FiSun } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { logout } from '../../../redux'
import { urlRouter } from '../../../router'
import { setDarkColor, setLightColor } from '../../../theme'
import style from "../header.module.css"
import { 
    Link, 
    useLocation, 
    useNavigate 
} from 'react-router-dom'
import { 
    Avatar, 
    Box, 
    Button, 
    Divider, 
    Flex, 
    IconButton, 
    Menu, 
    MenuButton, 
    MenuItem, 
    MenuList, 
    Text, useColorMode 
} from '@chakra-ui/react'

const UserButton = () => {
    const { t } = useTranslation()
    let stringUserData: string = localStorage.getItem('user_data') || ""
    const userData = JSON.parse(stringUserData)
    const { colorMode, toggleColorMode } = useColorMode()
    const location = useLocation()
    const isBlacklistPage = location.pathname === urlRouter.team
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    function toggleTheme() {
        if (colorMode === 'light') {
            setDarkColor()
        } else {
            setLightColor()
        }
        toggleColorMode()
    }

    function handleLogout() {
        dispatch(logout())
        navigate(urlRouter.login, { replace: true })
    }

    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<BiChevronDown />} className={style.profile_dropdown}>
                <Flex alignItems="center" gap="10px">
                    <Avatar
                        name={userData.display_name}
                        src={userData.photo_url}
                        className={style.profile_picture}
                        fontSize="10px"
                    />
                    <Text as="span" className={style.profile_name}>{userData.display_name}</Text>
                </Flex>
            </MenuButton>
            <MenuList className={style.profile_dropdown_content} shadow='md'>
                <MenuItem className={`${style.profile_dropdown_content_item} ${style.mobile_only}`}>
                    <IconButton as={Flex} ml="auto" aria-label="notification" icon={colorMode === 'light' ? <FiMoon /> : <FiSun />} className={`${style.header_button} ${style.theme}`} onClick={toggleTheme} />
                </MenuItem>
                <Divider className={style.mobile_only} my="8px" />
                {!isBlacklistPage &&
                    <Link to={urlRouter.team}>
                        <MenuItem className={style.profile_dropdown_content_item} backgroundColor='surfacePlus1'>
                            <span className={`material-symbols-outlined`}>change_circle</span>
                            <Text className={style.language_text}>{t("header_ganti_tim")}</Text>
                        </MenuItem>
                    </Link>
                }
                <Menu placement="bottom">
                    <MenuButton className={`${style.profile_dropdown_content_item}`}>
                        <Flex gap="16px">
                            <span className={`material-symbols-outlined`}>language</span>
                            <Text className={style.language_text}>{t("header_ganti_bahasa")}</Text>
                            <Box as="span" ml="auto" className={`material-symbols-outlined`}>navigate_next</Box>
                        </Flex>
                    </MenuButton>
                    <MenuList shadow="2xl" className={`${style.profile_dropdown_content}`}>
                        <MenuItem className={`${style.profile_dropdown_content_item}`} onClick={() => i18next.changeLanguage('id')} >
                            <Text className={style.language_text}>{t("header_ganti_bahasa_indonesia")}</Text>
                        </MenuItem>
                        <MenuItem className={`${style.profile_dropdown_content_item}`} onClick={() => i18next.changeLanguage('en')}>
                            <Text className={style.language_text}>{t("header_ganti_bahasa_inggris")}</Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
                <MenuItem className={`${style.profile_dropdown_content_item} ${style.logout}`} onClick={handleLogout} backgroundColor='surfacePlus1'>
                    <span className={`material-symbols-outlined`}>logout</span>
                    <Text className={style.language_text}>{t("header_keluar")}</Text>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserButton