import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTeams } from '../../redux'
import { useNavigate } from 'react-router-dom'
import { urlRouter } from '../../router'
import { Flex, Text } from '@chakra-ui/react'
import style from './team.module.css'
import { TeamCard, TeamSkeleton } from './TeamComponent'
import { useTranslation } from 'react-i18next'

const Team = () => {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isLoading, list } = useSelector((state: any) => state.team)

    const handleSelectTeam = (team: any) => {
        const teamData = {
            id: team.id,
            logo: team.profile_globalconfig,
            name: team.profile_globalconfig.display_name,
        }
        localStorage.setItem('current_team', JSON.stringify(team.id))
        localStorage.setItem('current_team_data', JSON.stringify(teamData))
        localStorage.setItem('team_config', JSON.stringify(team))
        navigate(urlRouter.home, { replace: true })
    }

    useEffect(() => {
        dispatch(getTeams())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex
            direction='column'
            w='100%'
        >
            <Text className={style.title}>{t('tim_pilih_tim')}</Text>
            {isLoading ? (
                <Flex className={style.teams}>
                    <TeamSkeleton />
                </Flex>
            ) : (
                <Flex className={style.teams}>
                    {list !== null &&
                        list.length !== 0 &&
                        list.map((team: any, index: number) => {
                            return (
                                <TeamCard
                                    key={index}
                                    _key={index}
                                    onSelect={() => handleSelectTeam(team)}
                                    text={team.profile_globalconfig.display_name}
                                    img={team.profile_globalconfig.logo}
                                />
                            )
                        })}
                </Flex>
            )}
        </Flex>
    )
}

export default Team
