import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { lastLocationListApi, urlApi } from '../../apis';

const initialState = {
  loadingGetLastLocation: false,
  successGetLastLocation: false,
  errorGetLastLocation: false,
  messageGetLastLocation: '',
  lastLocation: null,lastLocationMetadata: null,
}

export const getLastLocation = createAsyncThunk(urlApi.last_location_list, async (_, thunkAPI) => {
  try {
    const team = localStorage.getItem('current_team') || ""
    const response: any = await lastLocationListApi(team)
    return response
  } catch (error) {
    const message = "error get member privilages"
    return thunkAPI.rejectWithValue(message)
  }
})

export const lastLocationSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    resetGetLastLocation: (state) => {
      state.loadingGetLastLocation = false
      state.successGetLastLocation = false
      state.errorGetLastLocation = false
      state.messageGetLastLocation = ''
      state.lastLocation = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLastLocation.pending, (state) => {
        state.loadingGetLastLocation = true
        state.successGetLastLocation = false
        state.lastLocation = null
        state.errorGetLastLocation = false
        state.messageGetLastLocation = ''
      })
      .addCase(getLastLocation.fulfilled, (state, action) => {
        state.loadingGetLastLocation = false
        state.successGetLastLocation = true
        state.lastLocation = action.payload
      })
      .addCase(getLastLocation.rejected, (state, action: any) => {
        state.loadingGetLastLocation = false
        state.errorGetLastLocation = true
        state.messageGetLastLocation = 'error get member privilages'
      })
  },
})

export const { resetGetLastLocation } = lastLocationSlice.actions
export default lastLocationSlice.reducer