import { 
    useRef, 
    useState, 
    useEffect,
} from 'react'
import style from './header.module.css'
import { motion } from 'framer-motion'
import { setDarkColor, setLightColor } from '../../theme'
import { lOGO_JARIVIS, LOGO_JARIVIS_ONLY } from '../../assets'
import { useLocation, useNavigate } from 'react-router-dom'
import { urlRouter } from '../../router'
import { getChatsApi, readChatApi } from '../../apis/taskDiscussionApi'
import ChatListDrawer from './components/ChatListDrawer'
import MessageListDrawer from './components/MessageListDrawer'
import JToast from '../JToast/JToast'
import NotificationButton from './components/NotificationButton'
import ChatButton from './components/ChatButton'
import UserButton from './components/UserButton'
import { 
    useMutation, 
    useQuery, 
    useQueryClient 
} from 'react-query'
import { 
    Flex, 
    IconButton, 
    Image, 
    useColorMode, 
    useDisclosure, 
    useToast 
} from '@chakra-ui/react'
import JGoogleIcon from '../JGoogleIcon/JGoogleIcon'

const Header = () => {
    const { colorMode, toggleColorMode } = useColorMode()
    const navigate = useNavigate()
    const location = useLocation()
    const isBlacklistPage = location.pathname === urlRouter.team
    const queryClient = useQueryClient()
    const toast = useToast()
    const [chatId, setChatId] = useState<any>(null)
    const [taskId, setTaskId] = useState<any>(null)

    const { isOpen: isOpenChatList, onOpen: onOpenChatList, onClose: onCloseChatList } = useDisclosure()
    const btnRefChatList: any = useRef()

    const { isOpen: isOpenMessageList, onOpen: onOpenMessageList, onClose: onCloseMessageList } = useDisclosure()
    const btnRefMessageList: any = useRef()
    const chats = useQuery('header-get-chats', handleGetChats)
    const readChat = useMutation(handleReadChat, {
        onSuccess: () => {
            queryClient.invalidateQueries('header-get-chats')
        }
    })

    async function handleGetChats() {
        return await getChatsApi({})
    }

    async function handleReadChat(chatId: any) {
        return await readChatApi({ chat_id: chatId })
    }

    function toggleTheme() {
        if (colorMode === 'light') {
            setDarkColor();
        } else {
            setLightColor();
        };
        if(location.pathname === '/'){
            window.location.reload();
        }
        toggleColorMode();
    };

    function toggleMobileSidebar() {
        const sidebarMobile = document.documentElement.style.getPropertyValue('--sidebarMobile');
        if (sidebarMobile === '0px') {
            document.documentElement.style.setProperty('--sidebarMobile', '-100%');
        } else {
            document.documentElement.style.setProperty('--sidebarMobile', '0px');
        }
    }

    function handleClickChatItem(chatId: any, taskId: any) {
        setChatId(chatId)
        setTaskId(taskId)
        onOpenMessageList()
        readChat.mutate(chatId)
    }

    function handleExitChatRoom() {
        setChatId(null)
        setTaskId(null)
        onCloseMessageList()
    }

    async function handleIncomingChatNotification() {
        chatId && queryClient.invalidateQueries(`header-message-list-drawer-get-messages-${chatId}`)
        chatId && readChat.mutate(chatId)
        !chatId && queryClient.invalidateQueries('header-get-chats')
    }

    useEffect(() => {
        const handler = async () => {
            if (document.visibilityState === 'visible') await handleIncomingChatNotification()
        }

        document.addEventListener('visibilitychange', handler)
        return () => {
            document.removeEventListener('visibilitychange', handler)
        }
    }, [chatId])

    return (
        <Flex
            as={motion.div}
            className={style.header}
            transition="0.3s"
        >
            {!isBlacklistPage && <span className={`material-symbols-outlined ${style.hamburger}`} onClick={toggleMobileSidebar}>menu</span>}
            <Image onClick={() => navigate(urlRouter.home)} src={lOGO_JARIVIS} className={`${style.header_logo} ${style.header_logo_name}`} />
            <Image onClick={() => navigate(urlRouter.home)} src={LOGO_JARIVIS_ONLY} className={`${style.header_logo} ${style.header_logo_only}`} />
            
            <Flex gap="24px">
                <IconButton 
                    aria-label="notification" 
                    backgroundColor={'surfacePlus1'} 
                    icon={colorMode === 'light' ? <JGoogleIcon fontSize={'20px'} name={'dark_mode'} /> : <JGoogleIcon fontSize={'20px'} name={'light_mode'} />} 
                    onClick={toggleTheme} 
                    borderRadius={'12px'}
                />
                {!isBlacklistPage && <NotificationButton />}
                {!isBlacklistPage && <ChatButton btnRefChatList={btnRefChatList} onOpenChatList={onOpenChatList} chats={chats} />}
                <UserButton />
            </Flex>

            <ChatListDrawer
                isOpen={isOpenChatList}
                onClose={onCloseChatList}
                chats={chats?.data}
                onClick={handleClickChatItem}
                setChatId={setChatId}
                _ref={btnRefChatList}
                _message_ref={btnRefMessageList}
            />

            <MessageListDrawer
                chatId={chatId}
                taskId={taskId}
                isOpen={isOpenMessageList}
                onClose={handleExitChatRoom}
                _ref={btnRefMessageList}
            />
        </Flex>
    );
};

export default Header