import { Flex, Image, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import style from './team-card.module.css'

interface TeamCardProps {
  text: string
  img: any
  onSelect: any
  _key: number
}

const TeamCard = (props: TeamCardProps) => {
  return (
    <Flex 
      className={style.team_card} 
      onClick={props.onSelect}
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Image src={props?.img}  className={style.team_img} />
      <Text className={style.team_text}>{props.text}</Text>
    </Flex>
  )
}

export default TeamCard