import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { memberList, memberPrivilageList, urlApi } from '../../apis';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',

  loadingGetMemberPrivilages: false,
  successGetMemberPrivilages: false,
  errorGetMemberPrivilages: false,
  messageGetMemberPrivilages: '',
  memberPrivilages: null,
  memberPrivilagesMetadata: null,

  loadingGetMember: false,
  successGetMember: false,
  errorGetMember: false,
  messageGetMember: '',
  member: null,
  memberMetadata: null,
}

interface getMemberPrivilagesProps {
  page?: any
  show?: any
  is_active?: any
  search?: any
  with_columns?: any
}

export const getMemberPrivilages = createAsyncThunk(urlApi.member_privilage_list, async (data: getMemberPrivilagesProps, thunkAPI) => {
  const { page, show, is_active, search, with_columns } = data
  try {
    const team = localStorage.getItem('current_team') || ""
    const response: any = await memberPrivilageList(team, page, show, is_active, search, with_columns)
    return response
  } catch (error) {
    const message = "error get member privilages"
    return thunkAPI.rejectWithValue(message)
  }
})

interface getMemberProps {
  page?: any
  show?: any
  is_active?: any
  search?: any
  with_columns?: any
}

export const getMember = createAsyncThunk(urlApi.member, async (data: getMemberProps, thunkAPI) => {
  const { page, show, is_active, search, with_columns } = data
  try {
    const team = localStorage.getItem('current_team') || ""
    const response: any = await memberList(team, page, show, is_active, search, with_columns)
    return response
  } catch (error) {
    const message = "error get member"
    return thunkAPI.rejectWithValue(message)
  }
})

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    resetGetMemberPrivilages: (state) => {
      state.loadingGetMemberPrivilages = false
      state.successGetMemberPrivilages = false
      state.errorGetMemberPrivilages = false
      state.messageGetMemberPrivilages = ''
      state.memberPrivilages = null
      state.memberPrivilagesMetadata = null
    },
    resetGetMember: (state) => {
      state.loadingGetMember = false
      state.successGetMember = false
      state.errorGetMember = false
      state.messageGetMember = ''
      state.member = null
      state.memberMetadata = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMemberPrivilages.pending, (state) => {
        state.loadingGetMemberPrivilages = true
      })
      .addCase(getMemberPrivilages.fulfilled, (state, action) => {
        state.loadingGetMemberPrivilages = false
        state.successGetMemberPrivilages = true
        state.memberPrivilages = action.payload.data
        state.memberPrivilagesMetadata = action.payload.metadata
      })
      .addCase(getMemberPrivilages.rejected, (state, action: any) => {
        state.loadingGetMemberPrivilages = false
        state.errorGetMemberPrivilages = true
        state.messageGetMemberPrivilages = 'error get member privilages'
      })

      .addCase(getMember.pending, (state) => {
        state.loadingGetMember = true
      })
      .addCase(getMember.fulfilled, (state, action) => {
        state.loadingGetMember = false
        state.successGetMember = true
        state.member = action.payload.data
        state.memberMetadata = action.payload.metadata
      })
      .addCase(getMember.rejected, (state, action: any) => {
        state.loadingGetMember = false
        state.errorGetMember = true
        state.messageGetMember = 'error get member'
      })
  },
})

export const { resetGetMemberPrivilages, resetGetMember } = memberSlice.actions
export default memberSlice.reducer