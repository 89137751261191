import http from "./http";
import urlApi from "./url";

interface IGetMembers {
    search?: string
}

interface IGetJournies {
    member_id: any
    tanggal: any
}

interface IGetStraightDistances {
    member_id: any
    start_date: any
    end_date: any
}

interface IGetElapsedTimes {
    member_id: any
    start_date: any
    end_date: any
}

interface IGetLiveTrackingAreas {
    member_id: any
}

interface IGetCustomers {
    member_id: any
    tanggal: any
}

export async function getMembersApi(props: IGetMembers) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            page: 1,
            show: 1000,
            is_active: '',
            with_columns: '',
            ...props
        }
        const response: any = await http(true).post(urlApi.memberJourney.post.getMembers, payload)
        console.log({ RESPONSE_GET_MEMBERS: response })
        if (response.code !== '200') throw 'Error get members'
        return response.data
    } catch (error) {
        throw 'Error get members'
    }
}

export const getJourniesApi = async (props: IGetJournies) => {
    try {
        const team = localStorage.getItem('current_team') || ""
        const payload = {
            profile_id: team,
            ...props
        }

        let response: any = await http(true).post(urlApi.memberJourney.post.getJournies, payload)
        console.log({ RESPONSE_GET_JOURNIES: response })
        return response.data
    } catch (error) {
        console.error(error);
        return null
    }
}

export const getStraightDistancesApi = async (props: IGetStraightDistances) => {
    try {
        const team = localStorage.getItem('current_team') || ""
        const payload = {
            profile_id: team,
            ...props
        }

        let response: any = await http(true).post(urlApi.memberJourney.post.getStraightDistances, payload)
        console.log({ RESPONSE_GET_STRAIGHT_DISTANCES: response })
        return response.data
    } catch (error) {
        console.error(error);
        return null
    }
}

export const getElapsedTimesApi = async (props: IGetElapsedTimes) => {
    try {
        const team = localStorage.getItem('current_team') || ""
        const payload = {
            profile_id: team,
            ...props
        }

        let response: any = await http(true).post(urlApi.memberJourney.post.getElapsedTimes, payload)
        console.log({ RESPONSE_GET_ELAPSED_TIMES: response })
        return response.data
    } catch (error) {
        console.error(error);
        return null
    }
}

export async function getLiveTrackingAreasApi(props: IGetLiveTrackingAreas) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberJourney.post.getLiveTrackingAreas, payload)
        console.log({ RESPONSE_GET_LIVE_TRACKING_AREAS: response })
        if (response.code !== '200') throw 'Error get live tracking area'
        return response.data
    } catch (error) {
        throw 'Error get live tracking area'
    }
}

export async function getCustomersApi(props: IGetCustomers) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberJourney.post.getCustomers, payload)
        console.log({ RESPONSE_GET_CUSTOMERS: response })
        if (response.code !== '200') throw 'Error get customers point'
        return response.data
    } catch (error) {
        throw 'Error get customers point'
    }
}