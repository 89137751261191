import PublicRoute from './PublicRoute'
import urlRouter from './url'
import { lazy } from 'react'
import { DesignSystemMainLayout } from '../designSystem/layouts'
import { Routes, Route } from 'react-router-dom'
import { 
  Login, 
  MainApp, 
  Team, 
} from '../pages'
import { 
  DesignSystemButtons, 
  DesignSystemColorGuidance, 
  DesignSystemTypography, 
} from '../designSystem/pages'

const Router = () => {
  // code splitting
  const LastLocation = lazy(() => import('../pages/LastLocation/LastLocation'))
  const MemberJourney = lazy(() => import('../pages/MemberJourney/MemberJourney'))
  const Report = lazy(() => import('../pages/Report/Report'))
  const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'))
  const Notification = lazy(() => import('../pages/Notification/Notification'))

  // attendance
  const AttendanceSetting = lazy(() => import('../pages/AttendanceSetting/AttendanceSetting'))
  const AttendanceMonitoring = lazy(() => import('../pages/AttendanceMonitoring/AttendanceMonitoring'))

  // member
  const MemberMaster = lazy(() => import('../pages/MemberMaster/MemberMaster'))
  const MemberGroup = lazy(() => import('../pages/MemberGroup/MemberGroup'))
  const MemberList = lazy(() => import('../pages/MemberList/MemberList'))
  const MemberSetting = lazy(() => import('../pages/MemberSetting/MemberSetting'))

  // task
  const MasterTask = lazy(() => import('../pages/MasterTask/MasterTask'))
  const TaskType = lazy(() => import('../pages/TaskType/TaskType'))
  const TaskSetting = lazy(() => import('../pages/TaskSetting/TaskSetting'))

  // configuration
  const Lookup = lazy(() => import('../pages/Lookup/Lookup'))

  // item
  const AddItem = lazy(() => import('../pages/AddItem/AddItem'))
  const ItemList = lazy(() => import('../pages/ItemList/ItemList'))

  // cash advanced
  const CashAdvancedMaster = lazy(() => import('../pages/CashAdvancedMaster/CashAdvancedMaster'))
  const CashAdvancedTransaction = lazy(() => import('../pages/CashAdvancedTransaction/CashAdvancedTransaction'))

  // development
  const ButtonPage = lazy(() => import('../pages/Development/ButtonPage/ButtonPage'))
  const TypographyPage = lazy(() => import('../pages/Development/TypographyPage/TypographyPage'))

  // item
  const DashboardItem = lazy(() => import('../pages/Item/DashboardItem/DashboardItem'))

  // Customer
  const Customer = lazy(() => import('../pages/Customer/Customer'))
  
  //Task
  const TaskMonitoring = lazy(()=> import('../pages/TaskMonitoring/TaskMonitoring'))
  const TaskTabs = lazy(()=>import('../pages/TaskMonitoring/actions/Tabs'))
  const CreateTask = lazy (()=>import('../pages/CreateTask/CreateTask'))
  const EditTask = lazy (()=> import('../pages/EditTask/EditTask') )
  // builder
  const DashboardBuilder = lazy(() => import('../pages/DashboardBuilder/DashboardBuilder'))
  const BuilderReport = lazy(() => import('../pages/BuilderReport/BuilderReport'))

  return (
    <Routes>
      <Route path={urlRouter.home} element={<MainApp />}>
        <Route path={urlRouter.dashboard} element={<Dashboard />} />
        <Route path={urlRouter.customer} element={<Customer/>} />
        <Route path={urlRouter.lastLocation} element={<LastLocation />} />
        <Route path={urlRouter.memberJourney} element={<MemberJourney />} />
        <Route path={urlRouter.report} element={<Report />} />
        <Route path={urlRouter.team} element={<Team />} />
        <Route path={urlRouter.notification} element={<Notification />} />

        {/* task */}
        <Route path={urlRouter.taskMaster} element={<MasterTask />} />
        <Route path={urlRouter.createTask} element={<CreateTask/>}/>
        <Route path={urlRouter.taskType} element={<TaskType />} />
        <Route path={urlRouter.taskMonitoring} element={<TaskMonitoring />} />
        <Route path={urlRouter.taskMonitoringDetailResult} element={<TaskTabs />} />
        {/* <Route path={urlRouter.taskMonitoringResult} element={<TaskTabs/>} /> */}
        <Route path={urlRouter.taskSetting} element={<TaskSetting />} />
        <Route path={urlRouter.editTask} element={<EditTask/>}/>

        {/* item */}
        <Route path={urlRouter.itemDashboard} element={<DashboardItem/>} />
        <Route path={urlRouter.itemList} element={<ItemList />} />
        <Route path={urlRouter.itemCreate} element={<AddItem />} />

        {/* cash advanced */}
        <Route path={urlRouter.cashAdvance} element={<CashAdvancedMaster />} />
        <Route path={urlRouter.cashAdvanceTransaction} element={<CashAdvancedTransaction />} />

        {/* member */}
        <Route path={urlRouter.memberMaster} element={<MemberMaster />} />
        <Route path={urlRouter.memberGroup} element={<MemberGroup />} />
        <Route path={urlRouter.memberPrivilage} element={<MemberList />} />
        <Route path={urlRouter.memberSetting} element={<MemberSetting />} />

        {/* attendance */}
        <Route path={urlRouter.attendaceMonitoring} element={<AttendanceMonitoring />} />
        <Route path={urlRouter.attendaceSetting} element={<AttendanceSetting />} />

        {/* configuration */}
        <Route path={urlRouter.lookup} element={<Lookup />} />

        {/* builder */}
        <Route path={urlRouter.builderReport} element={<BuilderReport />} />
        <Route path={urlRouter.builderDashboard} element={<DashboardBuilder />} />
        <Route path={urlRouter.builderReport} element={<></>} />

        {/* development */}
        {process.env.REACT_APP_NODE_ENV === "development" 
          &&  <>
                <Route path={urlRouter.devButton} element={<ButtonPage />} />
                <Route path={urlRouter.devTypography} element={<TypographyPage />} />
              </>
        }
      </Route>

      <Route path={urlRouter.login} element={<PublicRoute />}>
        <Route path={urlRouter.login} element={<Login />} />
      </Route>

      <Route path={urlRouter.DESIGN_SYSTEM} element={<DesignSystemMainLayout />}>
        <Route path={urlRouter.DESIGN_SYSTEM_TYPOGRAPHY} element={<DesignSystemTypography />} />
        <Route path={urlRouter.DESIGN_SYSTEM_COLOR_GUIDANCE} element={<DesignSystemColorGuidance />} />
        <Route path={urlRouter.DESIGN_SYSTEM_BUTTONS} element={<DesignSystemButtons />} />
      </Route>
    </Routes>
  )
}

export default Router