import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import teamReducer from './slices/teamSlice'
import menuReducer from './slices/menuSlice'
import memberReducer from './slices/memberSlice'
import lastLocationReducer from './slices/lastLocationSlice'
import attendanceReducer from './slices/attendanceSlice'
import taskReducer from './slices/taskSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    team: teamReducer,
    menu: menuReducer,
    member: memberReducer,
    lastLocation: lastLocationReducer,
    attendance: attendanceReducer,
    task: taskReducer,
  },
})
