import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Flex,
} from '@chakra-ui/react'
import JTypography from '../../JTypography/JTypography'
import { useTranslation } from 'react-i18next'
import JButton from '../../JButton/JButton'

interface IModalDeleteMessage {
    isOpen: boolean
    onClose: any
    handleDelete: any
    isLoading: boolean
}

const ModalDeleteMessage = (props: IModalDeleteMessage) => {
    const { 
        isOpen, 
        onClose,
        handleDelete,
        isLoading,
    } = props
    const { t } = useTranslation()

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent padding='0px' width='343px' backgroundColor='surface'>
                <ModalBody padding='24px 16px'>
                    <JTypography 
                        size='headline-small' 
                        lineHeight='36px' 
                        color='onSurface'
                    >
                        {t('diskusi_tugas_modal_hapus_pesan_judul')}
                    </JTypography>

                    <JTypography 
                        size='body-medium' 
                        lineHeight='20px' 
                        color='onSurfaceVariant'
                        marginTop='16px'
                    >
                        {t('diskusi_tugas_modal_hapus_pesan_subjudul')}
                    </JTypography>

                    <Flex 
                        gap='16px' 
                        marginTop='24px' 
                        justifyContent='end'
                    >
                        <JButton 
                            variant='j-text' 
                            size='normal'
                            onClick={onClose}
                        >
                            {t('diskusi_tugas_modal_hapus_pesan_tombol_batal')}
                        </JButton>
                        <JButton 
                            variant='j-text' 
                            size='normal'
                            onClick={handleDelete}
                            isLoading={isLoading}
                        >
                            {t('diskusi_tugas_modal_hapus_pesan_tombol_hapus')}
                        </JButton>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ModalDeleteMessage