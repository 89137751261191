import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { menuListApi, urlApi } from '../../apis';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',

  list: null
}

export const getMenus = createAsyncThunk(urlApi.menu_list, async (_, thunkAPI) => {
  try {
    const team = localStorage.getItem('current_team')
    const response: any = await menuListApi(team || "")
    return response
  } catch (error) {
    const message = "error get menus"
    return thunkAPI.rejectWithValue(message)
  }
})

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    resetMenuSlice: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.list = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMenus.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.list = action.payload
      })
      .addCase(getMenus.rejected, (state, action: any) => {
        state.isLoading = false
        state.isError = true
        state.message = 'error get menus'
      })
  },
})

export const { resetMenuSlice } = menuSlice.actions
export default menuSlice.reducer