import http from "./http";
import urlApi from "./url";

export const lastLocationListApi = async (team: any) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)

    let response: any = await http(true).post(urlApi.last_location_list, bodyPayload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}