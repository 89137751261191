import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  JButton,
  JTypography,
} from "../../../components";

const ConfirmRemove = (props: any) => {
    const {
        show,
        onClose,
        resetDevice
    } = props;
    const {t} = useTranslation();
  return (
    <Modal isOpen={show} onClose={onClose} size={"lg"}>
      <ModalOverlay/>
      <ModalContent borderRadius={'16px'} backgroundColor={"background"} padding={"24px"}>
        <ModalHeader padding={"0px"} mt={'10px'} mb ={2} />
        <ModalCloseButton top={"24px"} right={"24px"} />
        <ModalBody
          as={Flex}
          flexDirection={"column"}
          gap={"16px"}
          padding={"0px"}
          marginTop={"24px"}
        >
          <Flex direction={'column'}>
              <JTypography size="title-large" mb={4} > {t("anggota_perangkat_keluar_konfirmasi1")} </JTypography>
              <JTypography size="body-medium" color={'onSurface'}> {t("anggota_perangkat_keluar_konfirmasi2")} </JTypography>
          </Flex>
        </ModalBody>
        <ModalFooter
          gap={"12px"}
          padding={"0px"}
          marginTop={"24px"}
          justifyContent={"end"}
        >
          <Flex direction={'row'}>
              <JButton onClick={onClose} size="normal" variant="j-text" mb={2} mr={4}> {t("anggota_perangkat_keluar_batal")} </JButton>
              <JButton onClick={resetDevice} size="normal" variant="j-primary" backgroundColor={'errorContainer'} color={'error'} _focus={{ opacity: '1.5' }} _hover={{ opacity: '0.7' }}> {t("anggota_perangkat_keluar")} </JButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmRemove;
