import http from "./http"
import urlApi from "./url"

export interface deleteMemberDeviceApiProps {
    user_id: string;
    toaster: any;
}

export async function deleteMemberDeviceApi(props: deleteMemberDeviceApiProps){
    const team = localStorage.getItem('current_team') || ''
    const payload = {
        profile_id: team,
        ...props,
    }
    const response: any = await http(true).post(urlApi.device.delete, payload)
    if(response.code === '200'){
        props.toaster('200', response.message)
    } else{
        props.toaster('0', response.message)
    }
    return response
}

