import React from 'react'
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react'

const SkeletonJourneyItem = () => {
    return (
        <Flex
            justifyContent="space-between"
            gap="24px"
            pl="30px"
            pb="24px"
            ml="10.5px"
            position="relative"
        >
            <SkeletonCircle
                height="24px"
                width="24px"
                position="absolute"
                left="-10px"
                zIndex="999"
            />
            <Flex direction="column" gap="4px">
                <Skeleton height="21px" width="150px" />
                <Skeleton height="100px" width="150px" />
            </Flex>
            <Flex>
                <Skeleton height="21px" width="60px" />
            </Flex>
        </Flex>
    )
}

export default SkeletonJourneyItem