import { Heading, HeadingProps } from "@chakra-ui/react"

interface JTypographyProps extends HeadingProps {
    size:   "display-large" |
            "display-medium" |
            "display-small" |
            "headline-large" |
            "headline-medium" |
            "headline-small" |
            "title-large" |
            "title-medium" |
            "title-small" |
            "label-large" |
            "label-medium" |
            "label-small" |
            "body-large" |
            "body-medium" |
            "body-small" 
}

const JTypography = (props: JTypographyProps) => {
    return (
        <Heading lineHeight={`100%`} {...props}>{props.children}</Heading>
    )
}

export default JTypography