import React, { useEffect, useRef, useState } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    Flex,
    IconButton,
    Box,
    Avatar,
    Menu,
    MenuButton,
    Button,
    MenuList,
    Input,
    Text,
    Center,
} from '@chakra-ui/react'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import { deleteChatApi, getChatApi, getChatDetailApi, sendChatApi } from '../../../apis/taskDiscussionApi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import JTypography from '../../JTypography/JTypography'
import OutcomingMessage from './OutcomingMessage'
import IncomingMessage from './IncomingMessage'
import moment from 'moment'
import 'moment/locale/id'
import 'moment/locale/en-au'
import { useTranslation } from 'react-i18next'
import { add, format } from 'date-fns'
import ModalDeleteMessage from './ModalDeleteMessage'

interface IMessageListDrawer {
    isOpen: any
    onClose: any
    _ref: any
    chatId: any
    taskId: any
}

const MessageListDrawer = (props: IMessageListDrawer) => {
    const {
        isOpen,
        onClose,
        _ref,
        chatId,
        taskId,
    } = props
    const userData = JSON.parse(localStorage.getItem('user_data') || '')
    const { t } = useTranslation()
    const bottomRef: any = useRef()
    const inputMessageRef: any = useRef()
    const [newMessage, setNewMessage] = useState('')
    const queryClient = useQueryClient()

    const [targetDelete, setTargetDelete] = useState('') 

    const [modalDelete, setModalDelete] = useState(false)

    const messages = useQuery(`header-message-list-drawer-get-messages-${chatId}`, handleGetMessages, {
        onSuccess: (resp: any) => {
            return resp.reverse()
        },
        enabled: chatId ? true : false,
    })
    const chatDetail = useQuery(`header-message-list-drawer-get-chat-detail-${taskId}`, handleGetChatDetail, {
        enabled: taskId ? true : false,
    })
    const sendMessage = useMutation(handleSendMessage, {
        onSuccess: () => {
            queryClient.invalidateQueries(`header-message-list-drawer-get-messages-${chatId}`)
            queryClient.invalidateQueries(`header-get-chats`)
            setNewMessage('')
            inputMessageRef.current.focus()
        }
    })
    const deleteMessage = useMutation(handleDeleteMessage, {
        onSuccess: () => {
            queryClient.invalidateQueries(`header-message-list-drawer-get-messages-${chatId}`)
            queryClient.invalidateQueries(`header-get-chats`)
            setTargetDelete('')
            setModalDelete(false)
        }
    })

    async function handleGetMessages() {
        return await getChatApi({ chat_id: chatId })
    }
    async function handleGetChatDetail() {
        return await getChatDetailApi({ task_id: taskId })
    }
    async function handleSendMessage() {
        return await sendChatApi({
            chat_id: chatId,
            data: JSON.stringify({ text: newMessage }),
            type: 'text',
            code: `${Math.floor(new Date().getTime() / 1000)}@${userData.code}@${localStorage.getItem('current_team')}`,
        })
    }
    async function handleDeleteMessage() {
        return await deleteChatApi({ chat_message_id: targetDelete })
    }

    function handleSelectMessage(id: any) {
        if (targetDelete) setTargetDelete('')
        else setTargetDelete(id)
    }

    useEffect(() => {
        const locale = document.cookie.replace(/(?:(?:^|.*;\s*)i18next\s*\=\s*([^;]*).*$)|^.*$/, "$1")
        if (locale === 'id') moment.locale('id')
        else moment.locale('en-au')
    }, [t])

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior : 'smooth' })
        inputMessageRef?.current?.focus()
    }, [messages])

    useEffect(() => {
        setNewMessage('')
    }, [chatId])

    useEffect(() => {
        isOpen && chatId && queryClient.invalidateQueries(`header-message-list-drawer-get-messages-${chatId}`)
        isOpen && taskId && queryClient.invalidateQueries(`header-message-list-drawer-get-chat-detail-${taskId}`)
    } , [isOpen])

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={_ref}
            size='messageDrawer'
        >
            <DrawerOverlay />
            <DrawerContent backgroundColor={'background'} width='372px'>
                <Flex 
                    backgroundColor={'background'} 
                    padding={'8px 16px'} 
                    alignItems={'center'}
                    gap={'12px'}
                    shadow='lg'
                >
                    <JGoogleIcon name={'arrow_back'} fontSize='24px' cursor={'pointer'} onClick={onClose} />
                    
                    {targetDelete
                        ?   <Flex alignItems='center' height='48px'>
                                <JTypography
                                    size='title-medium'
                                    fontWeight='500'
                                    color='onSurface'
                                >
                                    {t('diskusi_tugas_1_pesan_terpilih')}
                                </JTypography>
                            </Flex>
                        :   <Flex alignItems={'center'} gap={'12px'}>
                                <Avatar 
                                    name={chatDetail?.data?.metadata?.task?.customer?.name} 
                                    src={chatDetail?.data?.metadata?.task?.customer?.photo} 
                                    height='48px'
                                    width='48px'
                                />
                                <Flex flexDirection={'column'} gap={'5px'} position={'relative'}>
                                    <JTypography
                                        overflow={'hidden'} 
                                        whiteSpace={'nowrap'} 
                                        maxWidth={'160px'}  
                                        size={'title-medium'}
                                        color='onSurface'
                                        fontWeight='500'
                                    >
                                        {chatDetail?.data?.title} ({chatDetail?.data?.member_list?.length})
                                    </JTypography>
                                    <JTypography
                                        overflow={'hidden'} 
                                        whiteSpace={'nowrap'} 
                                        maxWidth={'160px'}  
                                        size={'body-medium'}
                                        fontWeight='400'
                                        color='onSurfaceVariant'
                                    >
                                        {chatDetail?.data?.description}
                                    </JTypography>
                                </Flex>
                            </Flex>
                    }
                    
                    {
                        !targetDelete
                            ?   <Menu placement='auto'>
                                    <MenuButton as={Center} marginLeft={'auto'} cursor='pointer' height='24px'>
                                        <Center>
                                            <JGoogleIcon name='keyboard_arrow_down' fontSize='24px' marginY='auto' cursor='pointer' />
                                        </Center>
                                    </MenuButton>
                                    <MenuList 
                                        backgroundColor='background' 
                                        zIndex='9999' 
                                        as={Flex} 
                                        flexDirection='column' 
                                        gap='16px' 
                                        padding='16px'
                                        marginTop='12px'
                                        width='372px'
                                        borderColor='background'
                                        borderRadius='0px 0px 16px 16px'
                                    >
                                        <Flex gap='8px' flexDirection='column'>
                                            <JTypography 
                                                size='title-small' 
                                                fontWeight='500' 
                                                lineHeight='16.41px'
                                            >
                                                {t('diskusi_tugas_detail_percakapan_tanggal_dibuat')}
                                            </JTypography>
                                            <JTypography 
                                                size='body-large' 
                                                fontWeight='400'
                                                lineHeight='24px'
                                            >
                                                {moment(chatDetail?.data?.created_at).format('LL')}
                                            </JTypography>
                                        </Flex>

                                        <Flex gap='8px' flexDirection='column'>
                                            <JTypography 
                                                size='title-small' 
                                                fontWeight='500' 
                                                lineHeight='16.41px'
                                            >
                                                {t('diskusi_tugas_detail_percakapan_id_tugas')}
                                            </JTypography>
                                            <JTypography 
                                                size='body-large' 
                                                fontWeight='400'
                                                lineHeight='24px'
                                            >
                                                {chatDetail?.data?.metadata?.task?.code}
                                            </JTypography>
                                        </Flex>

                                        <Flex gap='8px' flexDirection='column'>
                                            <JTypography 
                                                size='title-small' 
                                                fontWeight='500' 
                                                lineHeight='16.41px'
                                            >
                                                {t('diskusi_tugas_detail_percakapan_nama_tipe_tugas')}
                                            </JTypography>
                                            <JTypography 
                                                size='body-large' 
                                                fontWeight='400'
                                                lineHeight='24px'
                                            >
                                                {chatDetail?.data?.metadata?.task?.task_type?.name}
                                            </JTypography>
                                        </Flex>

                                        <Flex gap='8px' flexDirection='column'>
                                            <JTypography 
                                                size='title-small' 
                                                fontWeight='500' 
                                                lineHeight='16.41px'
                                            >
                                                {t('diskusi_tugas_detail_percakapan_pelanggan')}
                                            </JTypography>
                                            <JTypography 
                                                size='body-large' 
                                                fontWeight='400'
                                                lineHeight='24px'
                                            >
                                                {chatDetail?.data?.metadata?.task?.customer?.name}
                                            </JTypography>
                                        </Flex>

                                        <Flex gap='8px' flexDirection='column'>
                                            <JTypography 
                                                size='title-small' 
                                                fontWeight='500' 
                                                lineHeight='16.41px'
                                            >
                                                {t('diskusi_tugas_detail_percakapan_ditugaskan_oleh')}
                                            </JTypography>
                                            <Flex gap='16px' alignItems='center'>
                                                <Avatar 
                                                    width={'24px'} 
                                                    height={'24px'} 
                                                    name={chatDetail?.data?.created_by?.display_name} 
                                                    src={chatDetail?.data?.created_by?.photo_url} 
                                                />
                                                <JTypography 
                                                    size='body-large' 
                                                    fontWeight='400'
                                                    lineHeight='24px'
                                                >
                                                    {chatDetail?.data?.created_by?.display_name}
                                                </JTypography>
                                            </Flex>
                                        </Flex>

                                        <Flex gap='16px' flexDirection='column'>
                                            <JTypography 
                                                size='title-small' 
                                                fontWeight='500' 
                                                lineHeight='16.41px'
                                            >
                                                {t('diskusi_tugas_detail_percakapan_anggota')}
                                            </JTypography>
                                            <Flex flexDirection='column' gap='8px'>
                                                {chatDetail?.data?.member_list?.map((member: any, index: number) => {
                                                    return (
                                                        <Flex gap='16px' alignItems='center'>
                                                            <Avatar 
                                                                width={'48px'} 
                                                                height={'48px'} 
                                                                name={member?.display_name} 
                                                                src={member?.photo_url}
                                                            />
                                                            <JTypography 
                                                                size='body-large' 
                                                                fontWeight='400'
                                                                lineHeight='24px'
                                                            >
                                                                {member?.display_name} {member?.id === userData?.id ? `(${t('diskusi_tugas_detail_percakapan_saya')})` : ''}
                                                            </JTypography>
                                                        </Flex>
                                                    ) 
                                                })}
                                            </Flex>
                                        </Flex>
                                    </MenuList>
                                </Menu>
                            :   <JGoogleIcon 
                                    name='delete' 
                                    marginLeft='auto' 
                                    cursor='pointer' 
                                    onClick={() => setModalDelete(true)}
                                />
                    }
                </Flex>

                <DrawerBody 
                    as={Flex} 
                    flexDirection='column'
                    gap='24px'
                    padding='16px'
                >
                    {messages?.data?.map((message: any, index: number) => {
                        const parsedMessage = JSON.parse(message.data)

                        return (
                            <>
                                {index === 0
                                    ?   <Flex 
                                            backgroundColor={'surfacePlus2'} 
                                            borderRadius={'40px'} 
                                            maxWidth={'239px'} 
                                            padding={'12px'}
                                            marginX={'auto'}
                                            textAlign={'center'}
                                            position={'sticky'}
                                            top={'0px'}
                                            zIndex={index + 1}
                                            shadow='base'
                                        >
                                            <JTypography color='onSurface' size={'label-small'} key={index} lineHeight={'125%'}>
                                                {moment(message?.datetime).format('LL')}
                                            </JTypography>
                                        </Flex>
                                    :   null
                                }

                                {index !== 0 && message.datetime.substr(0, 10) !== messages.data[index - 1].datetime.substr(0, 10)
                                    ?   <Flex 
                                            backgroundColor={'surfacePlus2'} 
                                            borderRadius={'40px'} 
                                            maxWidth={'239px'} 
                                            padding={'12px'}
                                            marginX={'auto'}
                                            textAlign={'center'}
                                            position={'sticky'}
                                            top={'0px'}
                                            zIndex={index + 1}
                                            shadow='base'
                                        >
                                            <JTypography color='onSurface' size={'label-small'} key={index} lineHeight={'125%'}>
                                                {moment(message?.datetime).format('LL')}
                                            </JTypography>
                                        </Flex>
                                    :   null
                                }

                                {message.type === 'reassign'
                                    ?   <Flex 
                                            backgroundColor={'surfacePlus2'} 
                                            borderRadius={'40px'} 
                                            maxWidth={'239px'} 
                                            padding={'12px'}
                                            marginX={'auto'}
                                            textAlign={'center'}
                                        >
                                            <JTypography color='onSurface' size={'label-small'} key={index} lineHeight={'125%'}>
                                                {/* {parsedMessage.text} */}
                                                {t('diskusi_tugas_dialihkan', {
                                                    from: message?.created_by?.display_name                                                    ,
                                                    to: message?.assigned_to,
                                                    time: format(add(new Date(message?.created_at), { hours: -7 }), 'HH:mm'),
                                                })}
                                            </JTypography>
                                        </Flex>
                                    :   null
                                }

                                {message.type === 'text'
                                    ?   message.created_by.username === userData.username
                                            ?   <OutcomingMessage
                                                    key={index}
                                                    sender={message?.created_by?.display_name}
                                                    message={parsedMessage.text}
                                                    time={message?.datetime}
                                                    isReadAll={message?.is_read_all}
                                                    onSelectMessage={() => handleSelectMessage(message._id)}
                                                    targetDelete={targetDelete}
                                                    id={message._id}
                                                    isDeleted={message?.deleted_at}
                                                />
                                            :   <IncomingMessage
                                                    key={index}
                                                    sender={message?.created_by?.display_name}
                                                    message={parsedMessage.text}
                                                    time={message?.datetime}
                                                    isDeleted={message?.deleted_at}
                                                />
                                    :   null
                                }
                            </>
                        )
                    })}

                    <Box marginTop={'50px'} ref={bottomRef} />

                    <Flex 
                        as={'form'}
                        margin={'-12px'} 
                        marginTop={'12px'} 
                        padding={'14px 16px'} 
                        gap={'16px'} 
                        backgroundColor={'surfacePlus3'}
                        position={'fixed'}
                        bottom={'12px'}
                        width={'100%'}
                        zIndex='9999'
                    >
                        <Input
                            type={'text'} 
                            placeholder={t('diskusi_tugas_kirim_pesan_placeholder')} 
                            value={newMessage}
                            onChange={(e: any) => setNewMessage(e.target.value)}
                            isDisabled={sendMessage.isLoading || deleteMessage.isLoading}
                            ref={inputMessageRef}
                            backgroundColor='surfacePlus1'
                            fontSize='label-large'
                            borderColor='outline'
                        />
                        <IconButton 
                            variant='ghost'
                            type={'submit'}
                            aria-label={'send-chat'} 
                            icon={<JGoogleIcon name={'send'} color='primary' />} 
                            isLoading={sendMessage.isLoading}
                            isDisabled={sendMessage.isLoading || deleteMessage.isLoading}
                            onClick={() => {
                                if (!newMessage) return
                                sendMessage.mutate()
                            }}
                        />
                    </Flex>

                    <ModalDeleteMessage 
                        isOpen={modalDelete} 
                        onClose={() => setModalDelete(false)} 
                        handleDelete={() => deleteMessage.mutate()}
                        isLoading={deleteMessage.isLoading}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default MessageListDrawer