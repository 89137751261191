import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { theme } from './theme'
import 'material-symbols'
import { Provider } from 'react-redux'
import { store } from './redux'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { QueryClientProvider, QueryClient } from 'react-query'

console.log({ theme })

i18next
    .use(HttpApi)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['id', 'en'],
        fallbackLng: 'en',
        debug: false,
        // Options for language detector
        detection: {
            order: ['cookie', 'htmlTag'],
            caches: ['cookie'],
        },
        // react: { useSuspense: false },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
    })

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID || ''}>
                <Provider store={store}>
                    <BrowserRouter>
                        <ChakraProvider theme={theme} resetCSS>
                            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                            <App />
                        </ChakraProvider>
                    </BrowserRouter>
                </Provider>
            </GoogleOAuthProvider>
        </QueryClientProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
