import axios from "axios"

const httpNotif = (withAuth = false) => {
    const config: any = {
        baseURL: 'https://staging.api.notification.jarivis.com',
        headers: {}
    }

    if (withAuth) config.headers.token = localStorage.getItem('access_token')

    const axiosInstance = axios.create(config)
    axiosInstance.interceptors.response.use(res => res.data)

    return axiosInstance
}

export default httpNotif