import React from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'

const SkeletonMemberItem = () => {
    return (
        <Flex flexDirection='column' gap='10px'>
            <Skeleton borderRadius="5px" height="48px" width="100%"></Skeleton>
            <Skeleton borderRadius="5px" height="48px" width="100%"></Skeleton>
            <Skeleton borderRadius="5px" height="48px" width="100%"></Skeleton>
        </Flex>
    )
}

export default SkeletonMemberItem