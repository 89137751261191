import http from './http';
import urlApi from './url';

const team = localStorage.getItem('current_team') || ''

export const attendaceEditConfigApi = async (is_absence_mandatory_for_check_in: any, limit_clock_in: any, limit_clock_out: any) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)
    bodyPayload.set('is_absence_mandatory_for_check_in', is_absence_mandatory_for_check_in)
    bodyPayload.set('limit_clock_in', limit_clock_in)
    bodyPayload.set('limit_clock_out', limit_clock_out)
    const response: any = await http(true).post(urlApi.attendance_edit_config, bodyPayload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}

export const attendaceMonitorApi = async (search: string, start_date: any, end_date: any, page: any) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)
    bodyPayload.set('search', search)
    bodyPayload.set('start_date', start_date)
    bodyPayload.set('end_date', end_date)
    bodyPayload.set('page', page)
    const data: any = {}
    const response: any = await http(true).post(urlApi.attendance_monitor, bodyPayload)
    data.data = response.data
    data.metadata = response.metadata
    return data
  } catch (error) {
    console.error(error);
    return null
  }
}