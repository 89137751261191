import React from 'react'
import { Flex, Heading, Stack } from '@chakra-ui/react'
import { theme } from '../../../theme'

const DesignSystemColorGuidance: React.FC = () => {
    return (
        <Stack spacing='2rem'>
            <Stack>
                <Heading size='chakra_title_large'>Tonal Palletes</Heading>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.primary)?.map((primary: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={primary[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{primary[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.secondary)?.map((secondary: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={secondary[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{secondary[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.tertiary)?.map((tertiary: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={tertiary[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{tertiary[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.error)?.map((error: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={error[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{error[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.neutral)?.map((neutral: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={neutral[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{neutral[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.neutralVariant)?.map((neutralVariant: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={neutralVariant[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{neutralVariant[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.success)?.map((success: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={success[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 5 ? 'background' : 'onBackground'}
                                >{success[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
                <Stack direction='row' spacing='0px'>
                    {Object?.entries(theme?.colors?.warning)?.map((warning: any, index: number) => {
                        return (
                            <Stack
                                key={index} 
                                width='61px' 
                                height='108px' 
                                padding='10px'
                                backgroundColor={warning[1]}
                            >
                                <Heading 
                                    size='chakra_title_medium' 
                                    marginX='auto'
                                    color={index > 4 ? 'background' : 'onBackground'}
                                >{warning[0]}</Heading>
                            </Stack>
                        )
                    })}
                </Stack>
            </Stack>

            <Stack>
                <Heading size='chakra_title_large'>Ligth</Heading>
                <Stack>
                    {/* PRIMARY LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.primary?._light}
                        >
                            <Heading size='chakra_title_medium'>Primary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                            >{theme?.semanticTokens?.colors?.primary?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onPrimary?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.primary?._light}>On Primary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.primary?._light}
                            >{theme?.semanticTokens?.colors?.onPrimary?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.primaryContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onPrimaryContainer?._light}>Primary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onPrimaryContainer?._light}
                            >{theme?.semanticTokens?.colors?.primaryContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onPrimaryContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onPrimary?._light}>On Primary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onPrimary?._light}
                            >{theme?.semanticTokens?.colors?.onPrimaryContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SECONDARY LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.secondary?._light}
                        >
                            <Heading size='chakra_title_medium'>Secondary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                            >{theme?.semanticTokens?.colors?.secondary?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSecondary?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.secondary?._light}>On Secondary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.secondary?._light}
                            >{theme?.semanticTokens?.colors?.onSecondary?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.secondaryContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondaryContainer?._light}>Secondary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondaryContainer?._light}
                            >{theme?.semanticTokens?.colors?.secondaryContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSecondaryContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondary?._light}>On Secondary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondary?._light}
                            >{theme?.semanticTokens?.colors?.onSecondaryContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* TERTIARY LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.tertiary?._light}
                        >
                            <Heading size='chakra_title_medium'>Tertiary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                            >{theme?.semanticTokens?.colors?.tertiary?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onTertiary?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.tertiary?._light}>On Tertiary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.tertiary?._light}
                            >{theme?.semanticTokens?.colors?.onTertiary?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.tertiaryContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondaryContainer?._light}>Tertiary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondaryContainer?._light}
                            >{theme?.semanticTokens?.colors?.tertiaryContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onTertiaryContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondary?._light}>On Tertiary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondary?._light}
                            >{theme?.semanticTokens?.colors?.onTertiaryContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* ERROR LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.error?._light}
                        >
                            <Heading size='chakra_title_medium'>Error</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                            >{theme?.semanticTokens?.colors?.error?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onError?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.error?._light}>On Error</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.error?._light}
                            >{theme?.semanticTokens?.colors?.onError?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.errorContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onErrorContainer?._light}>Error Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onErrorContainer?._light}
                            >{theme?.semanticTokens?.colors?.errorContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onErrorContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onError?._light}>On Error Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onError?._light}
                            >{theme?.semanticTokens?.colors?.onErrorContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SUCCESS LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.success?._light}
                        >
                            <Heading size='chakra_title_medium'>Success</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                            >{theme?.semanticTokens?.colors?.success?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSuccess?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.success?._light}>On Success</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.success?._light}
                            >{theme?.semanticTokens?.colors?.onSuccess?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.successContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSuccessContainer?._light}>Success Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSuccessContainer?._light}
                            >{theme?.semanticTokens?.colors?.successContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSuccessContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSuccess?._light}>On Success Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSuccess?._light}
                            >{theme?.semanticTokens?.colors?.onSuccessContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* WARNING LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.warning?._light}
                        >
                            <Heading size='chakra_title_medium'>Warning</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                            >{theme?.semanticTokens?.colors?.warning?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onWarning?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.warning?._light}>On Warning</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.warning?._light}
                            >{theme?.semanticTokens?.colors?.onWarning?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.warningContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onWarningContainer?._light}>Warning Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onWarningContainer?._light}
                            >{theme?.semanticTokens?.colors?.warningContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onWarningContainer?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onWarning?._light}>On Warning Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onWarning?._light}
                            >{theme?.semanticTokens?.colors?.onWarningContainer?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* BACKGROUND & SURFACE LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.background?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onBackground?._light}>Background</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onBackground?._light}
                            >{theme?.semanticTokens?.colors?.background?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onBackground?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.background?._light}>On Background</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.background?._light}
                            >{theme?.semanticTokens?.colors?.onBackground?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surface?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSurface?._light}>Surface</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSurface?._light}
                            >{theme?.semanticTokens?.colors?.surface?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSurface?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._light}>On Surface</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._light}
                            >{theme?.semanticTokens?.colors?.onSurface?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SURAFACE VARIANT & OUTLINE LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfaceVariant?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSurfaceVariant?._light}>Surface Variant</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSurfaceVariant?._light}
                            >{theme?.semanticTokens?.colors?.surfaceVariant?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSurfaceVariant?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surfaceVariant?._light}>On Surface Variant</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surfaceVariant?._light}
                            >{theme?.semanticTokens?.colors?.onSurfaceVariant?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='432px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.outline?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.background?._light}>Surface</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.background?._light}
                            >{theme?.semanticTokens?.colors?.outline?._light?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SURAFACE PLUS LIGHT */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus1?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._dark}>Surface at +1</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._dark}
                            >+ 5% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus2?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._dark}>Surface at +2</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._dark}
                            >+ 8% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus3?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._dark}>Surface at +3</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._dark}
                            >+ 11% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus4?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._dark}>Surface at +4</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._dark}
                            >+ 12% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus5?._light}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._dark}>Surface at +5</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._dark}
                            >+ 14% Primary</Heading>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

            <Stack>
                <Heading size='chakra_title_large'>Dark</Heading>
                <Stack>
                    {/* PRIMARY DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.primary?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onPrimary?._dark}>Primary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onPrimary?._dark}
                            >{theme?.semanticTokens?.colors?.primary?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onPrimary?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.primary?._dark}>On Primary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.primary?._dark}
                            >{theme?.semanticTokens?.colors?.onPrimary?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.primaryContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onPrimaryContainer?._dark}>Primary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onPrimaryContainer?._dark}
                            >{theme?.semanticTokens?.colors?.primaryContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onPrimaryContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onPrimary?._dark}>On Primary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onPrimary?._dark}
                            >{theme?.semanticTokens?.colors?.onPrimaryContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SECONDARY DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.secondary?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondary?._dark}>Secondary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondary?._dark}
                            >{theme?.semanticTokens?.colors?.secondary?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSecondary?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.secondary?._dark}>On Secondary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.secondary?._dark}
                            >{theme?.semanticTokens?.colors?.onSecondary?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.secondaryContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondaryContainer?._dark}>Secondary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondaryContainer?._dark}
                            >{theme?.semanticTokens?.colors?.secondaryContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSecondaryContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondary?._dark}>On Secondary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondary?._dark}
                            >{theme?.semanticTokens?.colors?.onSecondaryContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* TERTIARY DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.tertiary?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onTertiary?._dark}>Tertiary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onTertiary?._dark}
                            >{theme?.semanticTokens?.colors?.tertiary?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onTertiary?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.tertiary?._dark}>On Tertiary</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.tertiary?._dark}
                            >{theme?.semanticTokens?.colors?.onTertiary?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.tertiaryContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondaryContainer?._dark}>Tertiary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondaryContainer?._dark}
                            >{theme?.semanticTokens?.colors?.tertiaryContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onTertiaryContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSecondary?._dark}>On Tertiary Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSecondary?._dark}
                            >{theme?.semanticTokens?.colors?.onTertiaryContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* ERROR DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.error?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onError?._dark}>Error</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onError?._dark}
                            >{theme?.semanticTokens?.colors?.error?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onError?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.error?._dark}>On Error</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.error?._dark}
                            >{theme?.semanticTokens?.colors?.onError?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.errorContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onErrorContainer?._dark}>Error Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onErrorContainer?._dark}
                            >{theme?.semanticTokens?.colors?.errorContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onErrorContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onError?._dark}>On Error Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onError?._dark}
                            >{theme?.semanticTokens?.colors?.onErrorContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SUCCESS DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.success?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSuccess?._dark}>Success</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSuccess?._dark}
                            >{theme?.semanticTokens?.colors?.success?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSuccess?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.success?._dark}>On Success</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.success?._dark}
                            >{theme?.semanticTokens?.colors?.onSuccess?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.successContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSuccessContainer?._dark}>Success Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSuccessContainer?._dark}
                            >{theme?.semanticTokens?.colors?.successContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSuccessContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSuccess?._dark}>On Success Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSuccess?._dark}
                            >{theme?.semanticTokens?.colors?.onSuccessContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* WARNING DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.warning?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onWarning?._dark}>Warning</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onWarning?._dark}
                            >{theme?.semanticTokens?.colors?.warning?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onWarning?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.warning?._dark}>On Warning</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.warning?._dark}
                            >{theme?.semanticTokens?.colors?.onWarning?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.warningContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onWarningContainer?._dark}>Warning Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onWarningContainer?._dark}
                            >{theme?.semanticTokens?.colors?.warningContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onWarningContainer?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onWarning?._dark}>On Warning Container</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onWarning?._dark}
                            >{theme?.semanticTokens?.colors?.onWarningContainer?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* BACKGROUND & SURFACE DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.background?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onBackground?._dark}>Background</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onBackground?._dark}
                            >{theme?.semanticTokens?.colors?.background?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onBackground?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.background?._dark}>On Background</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.background?._dark}
                            >{theme?.semanticTokens?.colors?.onBackground?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surface?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSurface?._dark}>Surface</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSurface?._dark}
                            >{theme?.semanticTokens?.colors?.surface?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSurface?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._dark}>On Surface</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._dark}
                            >{theme?.semanticTokens?.colors?.onSurface?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SURAFACE VARIANT & OUTLINE DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfaceVariant?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.onSurfaceVariant?._dark}>Surface Variant</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.onSurfaceVariant?._dark}
                            >{theme?.semanticTokens?.colors?.surfaceVariant?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.onSurfaceVariant?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surfaceVariant?._dark}>On Surface Variant</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surfaceVariant?._dark}
                            >{theme?.semanticTokens?.colors?.onSurfaceVariant?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                        <Stack
                            width='432px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.outline?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.background?._dark}>Surface</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.background?._dark}
                            >{theme?.semanticTokens?.colors?.outline?._dark?.replace('.', ' ')}</Heading>
                        </Stack>
                    </Stack>

                    {/* SURAFACE PLUS DARK */}
                    <Stack direction='row' spacing='0px'>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus1?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._light}>Surface at +1</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._light}
                            >+ 5% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus2?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._light}>Surface at +2</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._light}
                            >+ 8% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus3?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._light}>Surface at +3</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._light}
                            >+ 11% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus4?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._light}>Surface at +4</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._light}
                            >+ 12% Primary</Heading>
                        </Stack>
                        <Stack
                            width='216px' 
                            height='108px' 
                            padding='10px'
                            justifyContent='space-between'
                            backgroundColor={theme?.semanticTokens?.colors?.surfacePlus5?._dark}
                        >
                            <Heading size='chakra_title_medium' color={theme?.semanticTokens?.colors?.surface?._light}>Surface at +5</Heading>
                            <Heading 
                                size='chakra_title_medium' 
                                textAlign='right'
                                textTransform='capitalize'
                                color={theme?.semanticTokens?.colors?.surface?._light}
                            >+ 14% Primary</Heading>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default DesignSystemColorGuidance