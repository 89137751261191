import { Flex, Input, InputGroup, InputRightElement, Skeleton } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  PageTitle } from '../../components'
import { getTaskType } from '../../redux'
import style from './task-type.module.css'
import { useTranslation } from 'react-i18next'
import TableTaskType from './components/TableTaskType'

const TaskType = () => {
    const dispatch = useDispatch<any>()
    const { t } = useTranslation()
    const {
        loadingGetTaskType,
        taskType,
        taskTypeMetadata,
    } = useSelector((state: any) => state.task)

    function handleGetTaskType() {
        const payload = {
            page: 1,
            show: 10,
            order_by: 'name',
            order_type: 'ASC',
            search: '',
            is_active: 1,
        }
        dispatch(getTaskType(payload))
    }

    function handlePagination(type: any) {
        const data = {
            page: type === 'next' ? taskTypeMetadata.current_page + 1 : taskTypeMetadata.current_page - 1,
            show: 10,
            order_by: 'name',
            order_type: 'ASC',
            search: '',
            is_active: 1,
        }
        dispatch(getTaskType(data))
    }

    function handleSearch(keyword: any) {
        const data: any = {
            page: taskType.current_page,
            show: 10,
            order_by: 'name',
            order_type: 'ASC',
            search: '',
            is_active: 1,
        }
        if (keyword !== "") data.search = keyword
        dispatch(getTaskType(data))
    }

    useEffect(() => {
        handleGetTaskType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex className={`${style.task_type}`}>
            <PageTitle text={t("tipe_tugas_judul")} />

            <InputGroup width="max-content">
                <InputRightElement
                    children={<span className={`material-symbols-outlined ${style.icon}`}>search</span>}
                />
                <Input
                    type="search"
                    width="max-content"
                    placeholder={t("tipe_tugas_pencarian_placeholder")}
                    className={`${style.fs14}`}
                    onKeyDown={(e: any) => e.code === "Enter" && handleSearch(e.target.value)}
                />
            </InputGroup>

            {taskType
                ?   <TableTaskType
                        data={taskType}
                        metadata={taskTypeMetadata}
                        isLoading={loadingGetTaskType}
                        handleNext={() => handlePagination('next')}
                        handlePrevious={() => handlePagination('previous')}
                    />
                :   <Skeleton height={"571px"} width={"100%"} /> 
            }
        </Flex>
    )
}

export default TaskType