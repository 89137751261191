import http from "./http"
import urlApi from "./url"

export interface IGetChats {}

export interface IGetChat {
    chat_id: string
    // order_type: 'DESC'
}

export interface IGetUnreadChat {
    chat_id: string
    after: any
}

export interface IGetChatDetail {
    task_id: string
}

export interface IReadChat {
    chat_id: string
}

export interface IDeleteChat {
    chat_message_id: string
}

export interface ISendChat {
    chat_id: string
    code: string
    type: string
    data: any
}

export async function getChatsApi(props: IGetChats) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.get.chats, payload)
        return response.data
    } catch (error) {
        throw 'error get chats'
    }
}

export async function getChatApi(props: IGetChat) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            order_by: 'ASC',
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.get.chat, payload)
        return response.data
    } catch (error) {
        throw 'error get chat'
    }
}

export async function getUnreadChat(props: IGetUnreadChat) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.get.unread, payload)
        return response.data
    } catch (error) {
        throw 'error get unread chat'
    }
}

export async function getChatDetailApi(props: IGetChatDetail) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.get.chatDetail, payload)
        return response.data
    } catch (error) {
        throw 'error get chat detail'
    }
}

export async function readChatApi(props: IReadChat) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.read.chat, payload)
        return response.data
    } catch (error) {
        throw 'error read chat'
    }
}

export async function deleteChatApi(props: IDeleteChat) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.delete.chat, payload)
        return response.data
    } catch (error) {
        throw 'error delete chat'
    }
}

export async function sendChatApi(props: ISendChat) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.taskDiscussion.send.chat, payload)
        return response.data
    } catch (error) {
        throw 'error send chat'
    }
}