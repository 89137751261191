import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { 
    attendanceSetting_get_attendanceLocationListApi, 
    attendanceSetting_get_memberListApi,
    attendanceSetting_get_ConfigurationApi, 
    attendanceSetting_create_attendanceLocationApi,
    attendanceSetting_update_status,
    attendanceSetting_update_configuration,
    attendanceSetting_delete_attendanceLocation,
    attendanceSetting_update_attendanceLocationStatus,
    attendanceSetting_update_attendanceLocation, 
} from '../apis'
import { 
    AttendanceSetting_Create_AttendanceLocation_ApiProps,
    AttendanceSetting_Update_AttendanceLocationStatus_ApiProps, 
    AttendanceSetting_Update_Configuration_ApiProps, 
    AttendanceSetting_Update_Status_ApiProps 
} from '../apis/attendanceSettingApi'

export interface Configuration {
    is_absence_strict_location: number
    is_absence_mandatory_for_check_in: number
    is_absence_strict_time: number
    limit_clock_in: string
    limit_clock_out: string
}

export function useAttendaceSetting_get_memberList() {
    const { t } = useTranslation()
    return useQuery(
        'attendance-setting-get-member-list',
        async () => {
            const response: any = await attendanceSetting_get_memberListApi()
            if (response.code === '200') {
                const data: any = []
                response.data.map((g: any) => {
                    const group: any = {}
                    group.id = g.id
                    group.checked = false
                    group.display_name = g.type === 'group' ? g.display_name : t('pengaturan_anggota_lainnya')
                    const members: any = []
                    g.members.map((m: any) => {
                        const member: any = {}
                        member.checked = false
                        member.id = g.type === 'group' ? m.user_mapping_profile.id : m.id
                        member.display_name = g.type === 'group' ? m.user_mapping_profile.user.display_name : m.user.display_name
                        member.photo_url = g.type === 'group' ? m.user_mapping_profile.user.photo_url : m.user.photo_url
                        member.username = g.type === 'group' ? m.user_mapping_profile.user.username : m.user.username
                        members.push(member)
                    })
                    group.members = members
                    data.push(group)
                })
                return data
            }
            else console.error('error attendance-setting-get-member-list')
        },
        {
            refetchOnWindowFocus: false,
        },
    )
}

export function useAttendaceSetting_get_attendanceLocationList() {
    return useQuery(
        'attendance-setting-get-attendance-location-list',
        async () => {
            const response: any = await attendanceSetting_get_attendanceLocationListApi()
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-get-attendance-location-list')
        },
        {
            refetchOnWindowFocus: false,
        },
    )
}

export function useAttendaceSetting_get_configuration() {
    return useQuery(
        'attendance-setting-get-status',
        async () => {
            const response: any = await attendanceSetting_get_ConfigurationApi()
            if (response.code === '200') {
                const data = response.data
                const team = data.find((x: any) => x.id === Number(localStorage.getItem('current_team')))
                const {
                    is_absence_strict_location,
                    is_absence_mandatory_for_check_in,
                    is_absence_strict_time,
                    clock_in_time,
                    clock_out_time,
                } = team.profile_mobileconfig
                const configuration: Configuration = {
                    is_absence_strict_location,
                    is_absence_mandatory_for_check_in,
                    is_absence_strict_time,
                    limit_clock_in: clock_in_time,
                    limit_clock_out: clock_out_time,
                }
                return configuration
            } else {
                console.error('error attendance-setting-get-status')
            }
        },
        {
            refetchOnWindowFocus: false,
        },
    )
}

export function useAttendaceSetting_create_location(payload: AttendanceSetting_Create_AttendanceLocation_ApiProps) {
    return useQuery(
        'attendance-setting-create-location',
        async () => {
            const {
                address,
                label,
                latitude,
                longitude,
                radius,
                member_profile_id,
            } = payload
            const tempMembers: any = []
            member_profile_id.map((group: any) => {
                group.members.map((member: any) => {
                    if (member.checked) {
                        if (tempMembers.find((id: number) => id === member.id) === undefined) tempMembers.push(member.id)
                    }
                })
            })
            const response: any = await attendanceSetting_create_attendanceLocationApi({
                address,
                label,
                latitude,
                longitude,
                radius,
                member_profile_id: tempMembers
            })
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-create-location')
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    )
}

export function useAttendaceSetting_update_status(payload: AttendanceSetting_Update_Status_ApiProps) {
    return useQuery(
        'attendance-setting-update-status',
        async () => {
            const response: any = await attendanceSetting_update_status(payload)
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-update-status')
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    )
}

export function useAttendaceSetting_update_configuration(payload: AttendanceSetting_Update_Configuration_ApiProps) {
    return useQuery(
        'attendance-setting-update-configuration',
        async () => {
            const response: any = await attendanceSetting_update_configuration(payload)
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-update-configuration')
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    )
}

export function useAttendaceSetting_update_attendanceLocationStatus(payload: AttendanceSetting_Update_AttendanceLocationStatus_ApiProps) {
    return useQuery(
        'attendance-setting-update-attendance-location-status',
        async () => {
            const response: any = await attendanceSetting_update_attendanceLocationStatus(payload)
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-update-attendance-location-status')
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    )
}

export function useAttendaceSetting_update_location(payload: any) {
    return useQuery(
        'attendance-setting-update-location',
        async () => {
            const {
                address,
                label,
                latitude,
                longitude,
                radius,
                absence_location_id,
                member_profile_id,
            } = payload
            const tempMembers: any = []
            member_profile_id.map((group: any) => {
                group.members.map((member: any) => {
                    if (member.checked) {
                        if (tempMembers.find((id: number) => id === member.id) === undefined) tempMembers.push(member.id)
                    }
                })
            })
            const response: any = await attendanceSetting_update_attendanceLocation({
                label,
                radius,
                latitude,
                longitude,
                address,
                member_profile_id: JSON.stringify(tempMembers),
                absence_location_id,
            })
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-update-location')
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    )
}

export function useAttendaceSetting_delete_location(payload: any) {
    return useQuery(
        'attendance-setting-delete-location',
        async () => {
            const response: any = await attendanceSetting_delete_attendanceLocation({
                absence_location_id: payload.id
            })
            if (response.code === '200') return response.data
            else console.error('error attendance-setting-delete-location')
        },
        {
            refetchOnWindowFocus: false,
            enabled: false,
        },
    )
}