import style from './sidebar.module.css'
import JGoogleIcon from '../JGoogleIcon/JGoogleIcon'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { urlRouter } from '../../router'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { 
    Avatar, 
    Divider, 
    Flex, 
    Heading, 
    Link,
} from '@chakra-ui/react'

const Sidebar = () => {
    const [reveal, setReveal] = useState(true)
    const teamDataString = localStorage.getItem('current_team_data') || ''
    const teamData = JSON.parse(teamDataString)
    const { list } = useSelector((state: any) => state.menu)
    const location = useLocation()
    const { t } = useTranslation()

    const menus = [
        {
            "access": 1009,
            "_class": "beranda",
            "name": t('sidebar_beranda'),
            "icon": "dashboard",
            "to": urlRouter.dashboard
        },
        {
            "access": 1008,
            "name": t('sidebar_pelanggan'),
            "icon": "support_agent",
            "to": urlRouter.customer
        },
        {
            "access": 1013,
            "_class": "tugas",
            "name": t('sidebar_tugas'),
            "icon": "task",
            "submenu": [
                { "name": t('sidebar_master_tugas'), "to": urlRouter.taskMaster },
                { "name": t('sidebar_tipe_tugas'), "to": urlRouter.taskType },
                { "name": t('sidebar_pemantauan_tugas'), "to": urlRouter.taskMonitoring },
                { "name": t('sidebar_pengaturan_tugas'), "to": urlRouter.taskSetting },
            ],
        },
        {
            "access": 1013,
            "_class": "barang",
            "name": t('sidebar_barang'),
            "icon": "category",
            "submenu": [
                { "name": t('sidebar_dasbor_barang'), "to": urlRouter.itemDashboard },
                { "name": t('sidebar_daftar_barang'), "to": urlRouter.itemList },
                { "name": t('sidebar_tambah_barang'), "to": urlRouter.itemCreate },
            ],
        },
        {
            "access": 1014,
            "_class": "cash_advanced",
            "name": t('sidebar_cash_advanced'),
            "icon": "payments",
            "submenu": [
                { "name": t('sidebar_cash_advanced_master'), "to": urlRouter.cashAdvance },
                { "name": t('sidebar_cash_advanced_transaction'), "to": urlRouter.cashAdvanceTransaction },
            ],
        },
        {
            "name": "divider",
        },
        {
            "access": 1017,
            "_class": "anggota",
            "name": t('sidebar_anggota'),
            "icon": "groups",
            "submenu": [
                { "name": t('sidebar_anggota_master'), "to": urlRouter.memberMaster },
                { "name": t('sidebar_grup_anggota'), "to": urlRouter.memberGroup },
                { "name": t('sidebar_anggota_anggota'), "to": urlRouter.memberPrivilage },
                { "name": t('sidebar_pengaturan_anggota'), "to": urlRouter.memberSetting },
            ],
        },
        {
            "access": 1020,
            "_class": "absensi",
            "name": t('sidebar_absensi'),
            "icon": "co_present",
            "submenu": [
                { "name": t('sidebar_absensi_monitoring'), "to": urlRouter.attendaceMonitoring },
                { "name": t('sidebar_absensi_pengaturan'), "to": urlRouter.attendaceSetting },
            ],
        },
        {
            "access": 4,
            "_class": "lokasi",
            "name": t('sidebar_lokasi'),
            "icon": "location_on",
            "to": urlRouter.lastLocation
        },
        {
            "access": 3,
            "_class": "perjalanan",
            "name": t('sidebar_perjalanan'),
            "icon": "map",
            "to": urlRouter.memberJourney
        },
        {
            "name": "divider",
        },
        {
            "access": 1012,
            "_class": "konfigurasi",
            "name": t('sidebar_konfigurasi'),
            "icon": "tune", "submenu": [
                { "name": t('sidebar_konfigurasi_lookup'), "to": urlRouter.lookup }
            ],
        },
        {
            "access": 1016,
            "_class": "builder",
            "name": t('sidebar_builder'),
            "icon": "construction",
            "submenu": [
                { "name": t('sidebar_dashboard_builder'), "to": urlRouter.builderDashboard },
                { "name": t('sidebar_report_builder'), "to": urlRouter.builderReport },
            ],
        },
        {
            "access": 1001,
            "_class": "laporan",
            "name": t('sidebar_report'),
            "icon": "report",
            "to": urlRouter.report
        },
    ]

    function toggleSidebar() {
        if (reveal) {
            const sidebarMenus = document.querySelectorAll(`.${style.menu_item_wrapper}`)
            sidebarMenus.forEach((element: any) => {
                element.style.height = 48 + 'px'
                element.setAttribute('data-collapsed', 'false')
            })
            document.documentElement.style.setProperty('--sidebarWidth', '84.4px')
        } else {
            document.documentElement.style.setProperty('--sidebarWidth', '248px')
        }
        setReveal(!reveal)
    }

    function showSubmenu(element: any, target: any) {
        var sectionHeight = element.scrollHeight
        var elementTransition = element.style.transition
        element.style.transition = ''
        requestAnimationFrame(function () {
            element.style.height = sectionHeight + 'px'
            element.style.transition = elementTransition
            requestAnimationFrame(function () {
                const targetMenu = menus.find((menu: any) => menu._class === target)
                const child: number = targetMenu?.submenu?.length || 1
                element.style.height = (child + 1) * 48 + 'px'
                const chevron = document.querySelectorAll(`.${target} .${style.menu_item} .${style.menu_icon_chevron}`)
                chevron.forEach((el: any) => {
                    el.style.transform = "rotate(180deg)"
                })
            })
        })
        element.setAttribute('data-collapsed', 'true')
    }

    function hideSubMenu(element: any, target: any) {
        var sectionHeight = element.scrollHeight
        var elementTransition = element.style.transition
        requestAnimationFrame(function () {
            element.style.height = sectionHeight + 'px'
            element.style.transition = elementTransition
            requestAnimationFrame(function () {
                element.style.height = 48 + 'px'
                const chevron = document.querySelectorAll(`.${target} .${style.menu_item} .${style.menu_icon_chevron}`)
                chevron.forEach((el: any) => {
                    el.style.transform = "rotate(0deg)"
                })
            })
        })
        element.setAttribute('data-collapsed', 'false')
    }

    function toggleSubMenu(target: string) {
        var menu: any = document.querySelector(`.${target}`)
        var isCollapsed: any = menu?.getAttribute('data-collapsed')
        if (isCollapsed === null || isCollapsed === 'false') {
            showSubmenu(menu, target)
        } else {
            hideSubMenu(menu, target)
        }
    }

    return (
        <Flex
            as={motion.div}
            direction='column'
            className={style.sidebar}
            w='var(--sidebarWidth)'
            transition='0.3s'
        >
            <span className={`material-symbols-outlined ${style.hamburger}`} onClick={toggleSidebar}>menu</span>
            <Flex className={style.team}>
                <Avatar name={teamData.name} src={teamData.logo} className={style.team_logo} />
                <Heading
                    size='chakra_title_medium'
                    as={motion.p}
                    className={style.team_name}
                    visibility={reveal ? 'visible' : 'hidden'}
                    opacity={reveal ? '1' : '0'}
                    transition='visibility 0s, opacity 0.5s linear'
                    transitionDelay={reveal ? '0.3s' : '0s'}
                >
                    {teamData.name}
                </Heading>
            </Flex>
            <Flex direction='column' gap='8px'>
                {list && menus?.map((menu: any, index: number) => {
                    if (menu.name === 'divider') {
                        return <Divider key={index} borderColor='var(--colorOnSurface)' />
                    } else {
                        const granted = list.find((x: any) => x.id === menu.access)
                        if (granted) {
                            const subRedirects: any[] = []
                            menu.submenu && menu.submenu.map((sub: any) => {
                                return subRedirects.push(sub.to)
                            })
                            return (
                                menu.submenu
                                    ?   <Flex
                                            key={index}
                                            className={`${style.menu_item_wrapper} ${menu._class}`}
                                            as={motion.div}
                                            initial={{ x: '-100%' }}
                                            animate={{ x: 0 }}
                                            cursor='pointer'
                                        >
                                            <Flex
                                                className={`${style.menu_item} ${style.menu_icon}`}
                                                onClick={() => {
                                                    if (menu.hasOwnProperty('submenu') && reveal) {
                                                        toggleSubMenu(menu._class)
                                                    } else {
                                                        return
                                                    }
                                                }}
                                                justifyContent={reveal ? 'flex-start' : 'center'}
                                                color={subRedirects.includes(location.pathname) ? 'var(--colorPrimary) !important' : 'unset'}
                                                alignItems='center'
                                            >
                                                <JGoogleIcon name={menu.icon} />
                                                {
                                                    reveal && 
                                                        <Heading 
                                                            size='chakra_title_medium' 
                                                            whiteSpace='nowrap'
                                                            lineHeight='150%'
                                                        >
                                                            {menu.name}
                                                        </Heading>
                                                }
                                                {reveal && menu.submenu && <span className={`material-symbols-outlined ${style.menu_icon_chevron}`}>expand_more</span>}
                                            </Flex>
                                            {menu?.submenu?.map((sub: any, indexSub: number) => {
                                                return (
                                                    <Link
                                                        key={indexSub}
                                                        as={NavLink}
                                                        paddingRight={0}
                                                        to={sub.to}
                                                        _activeLink={{ borderLeft: '3px solid var(--colorPrimary)' }}
                                                        _hover={{ textDecoration: 'none' }}
                                                        transition='.3s'
                                                        className={style.menu_sub_item}
                                                        end
                                                    >
                                                        <Heading 
                                                            size='chakra_title_small'
                                                            cursor='pointer'
                                                            overflow='hidden'
                                                            whiteSpace='nowrap'
                                                            lineHeight='150%'
                                                        >{sub.name}</Heading>
                                                    </Link>
                                                )
                                            })}
                                        </Flex>
                                    :   <Link
                                            key={index}
                                            as={NavLink}
                                            to={menu.to}
                                            _activeLink={{ color: 'var(--colorPrimary) !important' }}
                                            _hover={{ textDecoration: 'none' }}
                                            transition='.3s'
                                            className={`${style.menu_item} ${style.menu_text} ${style.menu_icon}`}
                                            end
                                            justifyContent={reveal ? 'flex-start' : 'center'}
                                        >
                                            <Flex
                                                gap='16px'
                                                as={motion.div}
                                                initial={{ x: '-100%' }}
                                                animate={{ x: 0 }}
                                            >
                                                <span className={`material-symbols-outlined`}>{menu.icon}</span>
                                                {reveal && <Heading size='chakra_title_medium' lineHeight='150%'>{menu.name}</Heading>}
                                            </Flex>
                                        </Link>
                            )
                        }
                        return null
                    }
                })}
            </Flex>
        </Flex >
    )
}

export default Sidebar