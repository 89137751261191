import { useState, useEffect } from 'react'
import SkeletonJourneyItem from './components/SkeletonJourneyItem'
import SearchbarMember from './components/SearchbarMember'
import SkeletonMemberItem from './components/SkeletonMemberItem'
import JourneyLegend from './components/JourneyLegend'
import MemberItem from './components/MemberItem'
import JourneyItem from './components/JourneyItem'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { format, isSameDay, startOfToday } from 'date-fns'
import { 
    Divider, 
    Flex, 
    Image,
    Skeleton, 
    Text,
} from '@chakra-ui/react'
import { 
    Button, 
    JGoogleIcon, 
    JInput, 
    JTypography, 
    PageTitle, 
} from '../../components'
import { 
    getMembersApi,
    getJourniesApi,
    getStraightDistancesApi,
    getElapsedTimesApi,
    getLiveTrackingAreasApi,
    getCustomersApi,
} from '../../apis/memberJourneyApi'
import { 
    Circle,
    GoogleMap, 
    InfoWindow, 
    MarkerF, 
    Polyline, 
    useLoadScript,
} from '@react-google-maps/api'
import { 
    IC_ATTENDANCE_IN, 
    IC_ATTENDANCE_OUT, 
    IC_AUTO_UPDATE, 
    IC_CHECK_IN, 
    IC_CHECK_OUT, 
    IC_CUSTOMER, 
    IC_DRAFT, 
    IC_OUT_ZONE, 
    IC_RESULT,
    IMG_NO_JOURNEY, 
} from '../../assets'

const MemberJourney = () => {
    const { t } = useTranslation()
    const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyBm33hfh55CLR5RH168wBbiFCxCNn06_pc" })
    const centerDefault = { lat: -6.2213866, lng: 106.7796252 }
    const zoomDefault = 10

    const [selectedMember, setSelectedMember] = useState<any>(null)
    const [selectedDate, setSelectedDate] = useState<any>(null)
    const [search, setSearch] = useState<any>("")
    const [infoWindow, setInfoWindow] = useState<any>(null)
    const [center, setCenter] = useState<any>(centerDefault)
    const [zoom, setZoom] = useState<any>(zoomDefault)
    const [modalLegend, setModalLegend] = useState<boolean>(false)
    
    async function handleGetMembers(props: { search?: string }) {
        const { search } = props
        return await getMembersApi({ search })
    }
    
    async function handleGetJournies(props: { member_id: any, tanggal: any }) {
        const { member_id, tanggal } = props
        return await getJourniesApi({ member_id, tanggal })
    }

    async function handleGetStraightDistances(props: { member_id: any, start_date: any, end_date: any }) {
        const { member_id, start_date, end_date } = props
        return await getStraightDistancesApi({ member_id, start_date, end_date })
    }
    
    async function handleGetElapsedTimes(props: { member_id: any, start_date: any, end_date: any }) {
        const { member_id, start_date, end_date } = props
        return await getElapsedTimesApi({ member_id, start_date, end_date })
    }
    
    async function handleGetLiveTrackingAreas(props: { member_id: any }) {
        const { member_id } = props
        return await getLiveTrackingAreasApi({ member_id })
    }

    async function handleGetCustomers(props: { member_id: any, tanggal: any }) {
        const { member_id, tanggal } = props
        return await getCustomersApi({ member_id, tanggal })
    }

    const members = useMutation(handleGetMembers, {
        onSuccess: (data: any) => handleRedirectFromNotification({ data })
    })
    const journies = useMutation(handleGetJournies)
    const straightDistances = useMutation(handleGetStraightDistances)
    const elapsedTimes = useMutation(handleGetElapsedTimes)
    const liveTrackingAreas = useMutation(handleGetLiveTrackingAreas)
    const customersPoint = useMutation(handleGetCustomers)

    function handleClearJourneyLocalStorage() {
        localStorage.removeItem('journeyDate')
        localStorage.removeItem('journeyUser')
    }

    function handleRedirectFromNotification(props: { data: any[] }) {
        const { data } = props
        const user = localStorage.getItem('journeyUser')
        const date = localStorage.getItem('journeyDate')
        if (!user || !date) return handleClearJourneyLocalStorage()
        const member = data.find((m: any) => m.user.id === Number(user))
        if (!member) return handleClearJourneyLocalStorage()
        const member_id = member.user.id
        const tanggal = date
        const start_date = date
        const end_date = date
        setSelectedMember(member)
        setSelectedDate(date)
        journies.mutate({ member_id, tanggal })
        straightDistances.mutate({ member_id, start_date, end_date })
        elapsedTimes.mutate({ member_id, start_date, end_date })
        liveTrackingAreas.mutate({ member_id })
        customersPoint.mutate({ member_id, tanggal })
        handleClearJourneyLocalStorage()
    }

    function handleClickMemberItem(props: { member: any }) {
        const { member } = props
        const member_id = member.user.id
        const tanggal = format(startOfToday(), 'yyyy-MM-dd') 
        const start_date = format(startOfToday(), 'yyyy-MM-dd') 
        const end_date = format(startOfToday(), 'yyyy-MM-dd') 
        setSelectedMember(member)
        setSelectedDate(format(startOfToday(), 'yyyy-MM-dd'))
        journies.mutate({ member_id, tanggal })
        straightDistances.mutate({ member_id, start_date, end_date })
        elapsedTimes.mutate({ member_id, start_date, end_date })
        liveTrackingAreas.mutate({ member_id })
        customersPoint.mutate({ member_id, tanggal })
    }

    function handleChangeDate(props: { event: any }) {
        const { event } = props
        const date = event.target.value
        if (date === '') return
        const member_id = selectedMember.user.id
        const tanggal = date
        const start_date = date
        const end_date = date
        setSelectedDate(date)
        journies.mutate({ member_id, tanggal })
        straightDistances.mutate({ member_id, start_date, end_date })
        elapsedTimes.mutate({ member_id, start_date, end_date })
        liveTrackingAreas.mutate({ member_id })
        customersPoint.mutate({ member_id, tanggal })
    }

    function handleUpdateLocation() {
        const member_id = selectedMember.user.id
        const tanggal = selectedDate
        const start_date = selectedDate
        const end_date = selectedDate
        journies.mutate({ member_id, tanggal })
        straightDistances.mutate({ member_id, start_date, end_date })
        elapsedTimes.mutate({ member_id, start_date, end_date })
        liveTrackingAreas.mutate({ member_id })
        customersPoint.mutate({ member_id, tanggal })
    }

    function handleChangeSearch(props: { event: any }) {
        const { event } = props
        setSearch(event.target.value)
        members.mutate({ search: event.target.value })
    }

    function handleBack() {
        setSelectedMember(null)
        setSelectedDate(null)
        setSearch('')
        setCenter(centerDefault)
        setZoom(zoomDefault)
        journies.reset()
        straightDistances.reset()
        elapsedTimes.reset()
        liveTrackingAreas.reset()
        customersPoint.reset()
        members.mutate({ search: '' })
    }

    useEffect(() => {
        members.mutate({ search: '' })
    }, [])

    return (
        <Flex
            direction='column'
            width='100%'
            gap='16px'
            backgroundColor='background'
        >
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <PageTitle text={t("perjalanan_judul")} />

                <Flex>
                    <Button
                        type='text'
                        text={t("perjalanan_legenda")}
                        leftIcon='info'
                        onClick={() => setModalLegend(true)}
                    />
                    {selectedMember &&
                        <Button
                            type='outline'
                            text={t("perjalanan_perbarui_lokasi")}
                            leftIcon='sync'
                            onClick={handleUpdateLocation}
                        />
                    }
                </Flex>
            </Flex>

            <Flex 
                width='100%' 
                gap='24px' 
                height='calc(100vh - var(--headerHeight) - 33px - 16px - 24px - 32px - 48px)'
            >
                <Flex 
                    direction='column'
                    borderRadius='16px'
                    backgroundColor='surfacePlus1'
                    padding='24px'
                    height='100%'
                    gap='24px'
                    overflowY='auto'
                    width='30%'
                >
                    {selectedMember === null && 
                        <>
                            <SearchbarMember
                                value={search}
                                onChange={(event: any) => handleChangeSearch({ event })}
                                onSubmit={() => members.mutate({ search })}
                            />

                            {members?.isLoading
                                ?   <SkeletonMemberItem />
                                :   members?.data?.map((member: any, index: number) => {
                                        return (
                                            <MemberItem
                                                key={index}
                                                profile_picture={member.user.photo_url}
                                                fullname={member.user.display_name}
                                                email={member.user.username}
                                                onClick={() => handleClickMemberItem({ member })}
                                            />
                                        )
                                    })
                            }
                        </>
                    }

                    {selectedMember !== null &&
                        <Flex direction="column" gap="24px" overflow="scroll" height="100%">
                            <Flex direction="column" gap="24px">
                                <Flex gap="16px" alignItems="center">
                                    <JGoogleIcon 
                                        name='arrow_back' 
                                        cursor='pointer'
                                        onClick={handleBack}
                                    />
                                    <JTypography size='body-large'>
                                        {t("perjalanan_pengguna", { user: selectedMember?.user?.display_name })}
                                    </JTypography>
                                </Flex>
                                {!isSameDay(new Date(selectedDate), new Date()) && 
                                    <JTypography 
                                        size='body-medium'
                                        color='primary'
                                        textAlign='right'
                                        cursor='pointer'
                                        onClick={() => handleClickMemberItem({ member: selectedMember })}
                                    >
                                        {t('perjalanan_reset_tanggal')}
                                    </JTypography>
                                }
                                <JInput
                                    type='date'
                                    value={selectedDate}
                                    onChange={(event: any) => handleChangeDate({ event })}
                                />
                                <Flex gap="16px" direction="column">
                                    <Flex justifyContent="space-between">
                                        <Text fontSize='14px'>{t("perjalanan_total_jarak")}</Text>
                                        {straightDistances?.isLoading
                                            ?   <Skeleton borderRadius="5px" height="21px" width="60px"></Skeleton>
                                            :   straightDistances?.data?.shown_value === 0
                                                    ? <Text fontSize='14px'>-</Text>
                                                    : <Text fontSize='14px'>{straightDistances?.data?.shown_value} {straightDistances?.data?.shown_unit_of_measure}</Text>
                                        }
                                    </Flex>
                                    <Flex justifyContent="space-between">
                                        <Text fontSize='14px'>{t("perjalanan_total_waktu")}</Text>
                                        {elapsedTimes?.isLoading
                                            ?   <Skeleton borderRadius="5px" height="21px" width="60px"></Skeleton>
                                            :   elapsedTimes?.data?.shown_hour === "00" && elapsedTimes?.data?.shown_minute === "00" && elapsedTimes?.data?.shown_second === "00"
                                                    ? <Text fontSize='14px'>-</Text>
                                                    : <Text fontSize='14px'>{elapsedTimes?.data?.shown_hour}:{elapsedTimes?.data?.shown_minute}:{elapsedTimes?.data?.shown_second}</Text>
                                        }
                                    </Flex>
                                </Flex>
                                <Divider borderColor='onSurface' />
                            </Flex>

                            {/* historical */}
                            <Flex direction="column">
                                {journies?.isLoading
                                    ?   <SkeletonJourneyItem />
                                    :   journies?.data?.length === 0
                                            ?   <Image src={IMG_NO_JOURNEY} />
                                            :   journies?.data?.map((journey: any, index: number) => (
                                                    <JourneyItem
                                                        key={index}
                                                        address={journey?.address}
                                                        mode={journey?.mode}
                                                        time={journey?.datetime}
                                                        label={journey?.live_tracking_location_label}
                                                    />
                                                ))
                                }
                            </Flex>
                        </Flex>
                    }
                </Flex>

                {/* maps */}
                <Flex
                    direction='column'
                    borderRadius='16px'
                    backgroundColor='surfacePlus1'
                    padding='24px'
                    height='100%'
                    gap='24px'
                    width='70%'
                >
                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "16px",
                            }}
                            center={center}
                            zoom={zoom}
                            clickableIcons={false}
                        >
                            {liveTrackingAreas?.data?.map((liveTrackingArea: any, index: number) => {
                                return (
                                    <Circle
                                        key={index}
                                        center={{
                                            lat: liveTrackingArea?.latitude,
                                            lng: liveTrackingArea?.longitude,
                                        }}
                                        radius={liveTrackingArea?.radius}
                                        options={{
                                            fillColor: 'transparent',
                                            strokeColor: '#B1B1B1',
                                        }}
                                    />
                                )
                            })}
                            {customersPoint?.data?.map((customerPoint: any, index: number) => {
                                return (
                                    <MarkerF
                                        key={index}
                                        position={{
                                            lat: Number(customerPoint?.customer_address[0]?.latitude),
                                            lng: Number(customerPoint?.customer_address[0]?.longitude),
                                        }}
                                        icon={{
                                            url: IC_CUSTOMER,
                                            rotation: 1,
                                            scaledSize: { width: 35, height: 35, equals: (() => true) },
                                        }}
                                        onClick={() => {
                                            setInfoWindow(customerPoint)
                                            setCenter({
                                                lat: Number(customerPoint?.customer_address[0]?.latitude),
                                                lng: Number(customerPoint?.customer_address[0]?.longitude),
                                            })
                                            setZoom(15)
                                        }}
                                    />
                                )
                            })}
                            {journies?.data
                                ?   <Polyline
                                        path={journies?.data?.map((j: any) => {
                                            return {
                                                lat: Number(j.latitude),
                                                lng: Number(j.longitude),
                                            }
                                        })}
                                        options={{
                                            strokeColor: '#000000',
                                            strokeOpacity: 0.8,
                                            strokeWeight: 2,
                                            clickable: false,
                                            draggable: false,
                                            editable: false,
                                            visible: true,
                                            zIndex: 1,
                                        }}
                                    />
                                :   null
                            }
                            {journies?.data?.map((j: any, i: number) => {
                                return (
                                    <>
                                        {j.geofence_radius !== 0 && j.mode === 'CHECK IN' || j.geofence_radius !== 0 && j.mode === 'ABSEN IN'
                                            ?   <Circle
                                                    center={{
                                                        lat: j?.geofence_latitude,
                                                        lng: j?.geofence_longitude,
                                                    }}
                                                    radius={j?.geofence_radius}
                                                    options={{
                                                        fillColor: j.mode === 'CHECK IN' ? '#6C5677' : '#0079D2',
                                                        strokeColor: "transparent",
                                                        fillOpacity: 0.19
                                                    }}
                                                />
                                            : null
                                        }
                                        <MarkerF
                                            key={i}
                                            position={{
                                                lat: Number(j.latitude),
                                                lng: Number(j.longitude),
                                            }}
                                            icon={{
                                                url: j.mode === 'ABSEN IN'
                                                    ? IC_ATTENDANCE_IN
                                                    : j.mode === 'ABSEN OUT'
                                                        ? IC_ATTENDANCE_OUT
                                                        : j.mode === 'AUTO UPDATE'
                                                            ? IC_AUTO_UPDATE
                                                            : j.mode === 'CHECK IN'
                                                                ? IC_CHECK_IN
                                                                : j.mode === 'CHECK OUT'
                                                                    ? IC_CHECK_OUT
                                                                    : j.mode === 'DRAFT'
                                                                        ? IC_DRAFT
                                                                        : j.mode === 'RESULT'
                                                                            ? IC_RESULT
                                                                            : IC_OUT_ZONE,
                                                rotation: 1,
                                                scaledSize: { width: 35, height: 35, equals: (() => true) },
                                            }}
                                            onClick={() => {
                                                setInfoWindow(j)
                                                setCenter({
                                                    lat: Number(j.latitude),
                                                    lng: Number(j.longitude),
                                                })
                                                setZoom(15)
                                            }}
                                        >
                                            {infoWindow?.id === j?.id &&
                                                <InfoWindow
                                                    position={{
                                                        lat: Number(j.latitude),
                                                        lng: Number(j.longitude),
                                                    }}
                                                    onCloseClick={() => {
                                                        if (infoWindow.user.id === j.user.id) {
                                                            setInfoWindow(null)
                                                        }
                                                    }}
                                                >
                                                    <Flex color="black" p="16px" direction="column" gap="10px">
                                                        <Flex direction="column" gap="5px">
                                                            <Text fontSize='14px' fontWeight='500'>{j.mode}</Text>
                                                        </Flex>
                                                        <Flex direction="column" gap="5px">
                                                            <Text fontSize='14px' fontWeight='500'>Status Time</Text>
                                                            <Text fontSize='14px'>{j.datetime}</Text>
                                                        </Flex>
                                                        <Flex direction="column" gap="5px">
                                                            <Text fontSize='14px' fontWeight='500'>Battery Level</Text>
                                                            <Text fontSize='14px'>{j.battery_level}</Text>
                                                        </Flex>
                                                        <Flex direction="column" gap="5px">
                                                            <Text fontSize='14px' fontWeight='500'>Device Name</Text>
                                                            <Text fontSize='14px'>{j.device_name}</Text>
                                                        </Flex>
                                                        <Flex direction="column" gap="5px">
                                                            <Text fontSize='14px' fontWeight='500'>Signal Strength</Text>
                                                            <Text fontSize='14px'>{j.signal_strength}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </InfoWindow>
                                            }
                                        </MarkerF>
                                    </>
                                )
                            })}
                        </GoogleMap>
                    )}
                </Flex>
            </Flex>

            <JourneyLegend isOpen={modalLegend} onClose={() => setModalLegend(false)} />
        </Flex>
    )
}

export default MemberJourney