import { Button, ButtonProps } from '@chakra-ui/react'

interface JButtonProps extends ButtonProps {
    variant: 'j-primary' | 'j-outline' | 'j-text' | 'j-tonal'
    size: 'normal' | 'small'
}

const JButton = (props: JButtonProps) => {
    return (
        <Button
            {...props}
        >
            {props.children}
        </Button>
    )
}

export default JButton