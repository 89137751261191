import { Flex, Skeleton } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { JTable, JTypography, JButton, JGoogleIcon } from "../../components";

interface MasterMemberTableProps {
  data: any;
  metadata: any;
  isLoading: boolean;
  handlePrevious: any;
  handleNext: any;
  handleClickDevice: any;
}

const MasterMemberTable = (props: MasterMemberTableProps) => {
  const { t } = useTranslation();
  const { data, metadata, isLoading, handlePrevious, handleNext, handleClickDevice } =
    props;
  console.log(data)
  const tableData = useMemo(() => [...data], [data]);
  const [openInfo, setOpenInfo] = useState(false);
  const tableColumns = useMemo(
    () => [
      {
        Header: t("master_anggota_tabel_nama"),
        accessor: "user.display_name",
        // Cell: (props: any) => {
        //   return (
        //     <p style={{ width:'100px', wordBreak:'break-word', paddingTop:'0px', overflowX:'scroll', overflowY:'hidden' }}>
        //       {props.value}
        //     </p>
        //   );
        // }
      },
      {
        Header: t("master_anggota_kode"),
        accessor: "user.code",
      },
      {
        Header: t("master_anggota_tabel_telepon"),
        accessor: "user.phone",
      }, 

    ],
    [t, data]
  );
  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((tableColumns: any) => [
      ...tableColumns,
      { 
        id:t("master_anggota_NIP"),
        Header:t("master_anggota_NIP"),
        Cell: ({ row }: any) => row.original.user.nip === null ? <JTypography size="body-medium" > {t("master_anggota_device_no_nip")} </JTypography> : <JTypography size="body-medium" > {row.original.user.nip}</JTypography> 
      },
      { 
        id: t("master_anggota_device"),
        Header:t("master_anggota_device"),
        Cell: ({ row }: any) => row.original.user.device === null ? <JTypography pr={0} size="body-medium" > {t("master_anggota_device_not_available")} </JTypography> : <JTypography pr={0} size="body-medium" > {t("master_anggota_device_available")} </JTypography> 
      },
      { 
        id: t("master_anggota_device1"),
        Header: '',
        Cell: ({ row }: any) => <JButton p={0} isDisabled={row.original.user.device === null} onClick={()=>handleClickDevice(row.original)} size="normal" variant="j-text" > {t('master_anggota_lihat')} </JButton>,
      },
    ]);
  };

  return (
    <Flex direction={"column"} gap={"0px"} w={"100%"}>
      {isLoading ? (
        <Skeleton height={"571px"} width={"100%"} />
      ) : (
        <>
          <JGoogleIcon onMouseOver={()=>setOpenInfo(true)} onMouseOut={()=>setOpenInfo(false)} name="help" position={'absolute'} top={'18.8%'} right={'3%'} />
          {openInfo && 
              <Flex  
                hidden = {!openInfo}
                position = {'absolute'}
                borderRadius = {'8px'}
                padding = {'16px'}
                right={'3%'}
                top={'9.5%'}
                backgroundColor = {'surface'}
                wordBreak = {'break-word'}
                width = {'500px'}
              >
                  <JTypography size={"body-medium"}>
                  {t("master_anggota_info")}
                </JTypography>
              </Flex>
          }
          <JTable
            data={tableData}
            columns={tableColumns}
            hooks={tableHooks}
            max_width={`calc(100vw - 47px - 248px)`}
          />
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            backgroundColor={"surfacePlus4"}
            padding={"8px 16px"}
            borderBottomLeftRadius={"16px"}
            borderBottomRightRadius={"16px"}
          >
            {isLoading || !data || !metadata ? (
              <Skeleton height={"15px"} width={"215px"} />
            ) : (
              <JTypography size={"body-medium"}>
                {t("master_anggota_keterangan", {
                  from: metadata?.from,
                  total: metadata?.total,
                })}
              </JTypography>
            )}
            <Flex gap={"10px"}>
              <JButton
                size={"small"}
                variant={"j-primary"}
                isDisabled={isLoading || metadata?.current_page === 1}
                onClick={handlePrevious}
              >
                {t("daftar_barang_tabel_sebelumnya")}
              </JButton>
              <JButton
                size={"small"}
                variant={"j-primary"}
                isDisabled={
                  isLoading || metadata?.current_page === metadata?.last_page
                }
                onClick={handleNext}
              >
                {t("daftar_barang_tabel_selanjutnya")}
              </JButton>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default MasterMemberTable;
