import http from "./http";
import urlApi from "./url";

export const menuListApi = async (team: string) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)
    let response: any = await http(true).post(urlApi.menu_list, bodyPayload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}