import { Fragment } from "react"
import { Divider, Flex } from "@chakra-ui/react"
import { JTypography } from "../../../components"
import { useTranslation } from "react-i18next"

const TypographyPage = () => {
    const { t } = useTranslation()
    const data = [
        { size: "display-large", text: "Display Large", },
        { size: "display-medium", text: "Display Medium", },
        { size: "display-small", text: "Display Small", },

        { size: "headline-large" , text: "Headline Large", },
        { size: "headline-medium" , text: "Headline Medium", },
        { size: "headline-small" , text: "Headline Small", },

        { size: "title-large" , text: "Title Large", },
        { size: "title-medium" , text: "Title Medium", },
        { size: "title-small" , text: "Title Small", },

        { size: "label-large" , text: "Label Large", },
        { size: "label-medium" , text: "Label Medium", },
        { size: "label-small" , text: "Label Small", },

        { size: "body-large" , text: "Body Large", },
        { size: "body-medium" , text: "Body Medium", },
        { size: "body-small" , text: "Body Small", },

    ]

    return (
        <Flex 
            direction={`column`} 
            gap={`12px`} 
            width={`100%`}
        >
            <JTypography size="headline-large">{t("sidebar_pengembangan_tulisan")}</JTypography>
            {data?.map((d: any, index: number) => {
                return (
                    <Fragment key={index}>
                        <JTypography size={d.size}>{d.text}</JTypography>
                        {(index + 1) % 3 === 0 && <Divider orientation="horizontal" />}
                    </Fragment>
                )
            })}
        </Flex>
    )
}

export default TypographyPage