import { Avatar, Flex } from '@chakra-ui/react'
import { JTypography } from '../../../components'

interface MemberItemProps {
    profile_picture?: string
    fullname?: string
    email?: string
    onClick?: any
}

const MemberItem = (props: MemberItemProps) => {
    const {
        profile_picture,
        fullname,
        email,
        onClick,
    } = props

    return (
        <Flex
            width='100%'
            gap='12px'
            cursor='pointer'
            alignItems='center'
            onClick={onClick}
        >
            <Avatar src={profile_picture} name={fullname} />
            <Flex color='onSurface' direction='column'>
                <JTypography size='body-medium'>
                    {fullname}
                </JTypography>
                <JTypography size='body-small'>
                    {email}
                </JTypography>
            </Flex>
        </Flex>
    )
}

export default MemberItem