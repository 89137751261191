import { 
  Flex, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Table, 
  TableContainer, 
  Tbody, 
  Td, 
  Text, 
  Thead, 
  Tr, 
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LoadingModal, PageTitle } from '../../components'
import { useEffect, useState } from 'react'
import style from './attendance-monitoring.module.css'
import { getAttendanceMonitor } from '../../redux'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const AttendanceMonitoring = () => {
  const [attendanceDate, setAttendanceDate] = useState({
    start_date: format(new Date(), 'yyyy-MM-dd'),
    end_date: format(new Date(), 'yyyy-MM-dd'),
  })
  const [search, setSearch] = useState('')
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const {
    attendanceMonitorMetadata,
    loadingGetAttendanceMonitor,
    attendanceMonitor,
  } = useSelector((state: any) => state.attendance)
  const tableConfigHeader = [
    {name: t("monitoring_absensi_tabel_nama"), key: ""},
    {name: t("monitoring_absensi_tabel_absen_masuk"), key: ""},
    {name: t("monitoring_absensi_tabel_tanggal_masuk"), key: ""},
    {name: t("monitoring_absensi_tabel_lokasi_absen_masuk"), key: ""},
    {name: t("monitoring_absensi_tabel_absen_keluar"), key: ""},
    {name: t("monitoring_absensi_tabel_tanggal_keluar"), key: ""},
    {name: t("monitoring_absensi_tabel_lokasi_absen_keluar"), key: ""},
    {name: t("monitoring_absensi_tabel_total_jam_kerja"), key: ""},
  ]

  function handleGetAttendanceMonitor() {
    const data = {
      start_date: attendanceDate.start_date,
      end_date: attendanceDate.end_date,
      page: 1,
    }
    dispatch(getAttendanceMonitor(data))
  }

  function handlePagination(type: any) {
    const data = {
      start_date: attendanceDate.start_date,
      end_date: attendanceDate.end_date,
      page: type === 'next' ? attendanceMonitorMetadata.current_page + 1 : attendanceMonitorMetadata.current_page - 1,
    }
    dispatch(getAttendanceMonitor(data))
  }
  function handleSearch(keyword: any) {
    console.log({keyword})
    setSearch(keyword?.target?.value)
    // const data = {
    //   search: keyword.target.value,
    //   start_date: attendanceDate.start_date,
    //   end_date: attendanceDate.end_date,
    //   page: attendanceMonitorMetadata.current_page
    // }
    // if(keyword.type === 'click' || keyword.key === "Enter"){
    //   dispatch(getAttendanceMonitor(data))
    // } 
    
  }
  function handleChangeAttendanceDate(e: any) {
    if (e.target.value === '') return
    setAttendanceDate((previous: any) => {
      return {
        ...previous,
        [e.target.name]: e.target.value,
      }
    })
  }

  useEffect(() => {
    handleGetAttendanceMonitor()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceDate])
  return (
    <Flex className={`${style.attendance_monitoring}`}>
      <PageTitle text={t("monitoring_absensi")} />

      <Flex className={`${style.controls}`}>
        <InputGroup width="max-content" className={`${style.searchbar}`}>
          <InputRightElement
            children={<span className='material-symbols-outlined' style={{ cursor: 'default' }} onClick={(e:any)=>handleSearch(e)}>search</span>}
          />
          <Input
            type="search"
            width="max-content"
            placeholder={t("monitoring_absensi_pencarian_placeholder")}
            value={search}
            onChange={(e: any)=>handleSearch(e)}
            className={`${style.fs14}`}
            onKeyDown={(keyword: any) => {
              const data = {
                search: keyword.target.value,
                start_date: attendanceDate.start_date,
                end_date: attendanceDate.end_date,
                page: attendanceMonitorMetadata.current_page
              }
              if(keyword.type === 'click' || keyword.key === "Enter"){
                dispatch(getAttendanceMonitor(data))
              } 
            }}
          />
        </InputGroup>
        <Flex className={`${style.control_date}`}>
          <Text whiteSpace="nowrap" className={`${style.fs14}`}>{t("monitoring_absensi_berdasarkan_tanggal")}</Text>
          <Flex alignItems="center" gap="8px">
            <Text className={`${style.fs14} ${style.since}`}>{t("monitoring_absensi_tanggal_mulai")}</Text>
            <Input 
              width="max-content" 
              type="date" 
              className={`${style.fs14}`} 
              defaultValue={attendanceDate.start_date}
              name="start_date"
              onChange={handleChangeAttendanceDate}
            />
          </Flex>
          <Text className={`${style.control_date_separator}`}>-</Text>
          <Flex alignItems="center" gap="8px">
            <Text className={`${style.fs14} ${style.since}`}>{t("monitoring_absensi_tanggal_sampai")}</Text>
            <Input 
              width="max-content" 
              type="date" 
              className={`${style.fs14}`} 
              defaultValue={attendanceDate.end_date}
              name="end_date"
              onChange={handleChangeAttendanceDate}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex className={`${style.table_wrapper}`}>
        <TableContainer width="100%">
          <Table variant='striped' width={`${tableConfigHeader.length * 145.56}px`}>
            <Thead>
              <Tr>
                {tableConfigHeader.map((config: any, index: number) => (
                  <Td key={index} className={`${style.fs16} ${style.th}`}>{config.name}</Td>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {attendanceMonitor?.map((attendance: any) => (
                <Tr>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.user?.display_name?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.clock_in?.datetime.split(" ")[1]?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.clock_in?.datetime.split(" ")[0]?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.clock_in?.address?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.clock_out?.datetime.split(" ")[1]?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.clock_out?.datetime.split(" ")[0]?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>{attendance?.clock_out?.address?? "-"}</Td>
                  <Td className={`${style.fs14_400} ${style.td}`}>
                      {
                        `${Math.floor(
                          moment(attendance?.clock_out !== null ? new Date(attendance?.clock_out?.datetime) : new Date()).diff(
                            moment(attendance?.clock_in?.datetime)
                          ) / 3600000)} ${t('jam_kerja')}`
                      }         
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      {attendanceMonitorMetadata !== null &&
        <Flex alignItems="center" justifyContent="space-between">
          <Text className={`${style.fs14}`}>{t("monitoring_absensi_tabel_informasi", {from: attendanceMonitorMetadata.from, to: attendanceMonitorMetadata.to, total: attendanceMonitorMetadata.total})}</Text>
          <Flex gap="8px">
            <Button 
              type='text' 
              text={t("monitoring_absensi_tabel_pagination_kembali")} 
              disable={attendanceMonitorMetadata.current_page === 1} 
              onClick={() => handlePagination('previous')}
            />
            <Button 
              type='text' 
              text={t("monitoring_absensi_tabel_pagination_lanjut")} 
              disable={attendanceMonitorMetadata.current_page === attendanceMonitorMetadata.last_page} 
              onClick={() => handlePagination('next')}
            />
          </Flex>
        </Flex>
      }

      <LoadingModal show={loadingGetAttendanceMonitor} />
    </Flex>
  )
}

export default AttendanceMonitoring