import React from 'react'
import JTypography from '../../JTypography/JTypography'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import { t } from 'i18next'
import { 
    Avatar, 
    Box, 
    Center, 
    Flex 
} from '@chakra-ui/react'
import { 
    format, 
    isSameDay, 
    isYesterday, 
    startOfToday 
} from 'date-fns'

interface IChatItem {
    _ref: any
    onClick: any
    task_id: string
    title: string
    member_count: number
    unread_count: number
    last_sender: string
    last_message: any
    last_message_time: any
    description: string
    isDeleted: any,
    customerPicture: any,
    customerName: any,
    isLastChild: boolean,
}

const ChatItem = (props: IChatItem) => {
    const {
        _ref,
        onClick,
        task_id,
        title,
        member_count,
        unread_count,
        last_sender,
        last_message,
        last_message_time,
        description,
        isDeleted,
        customerPicture,
        customerName,
        isLastChild,
    } = props

    const lastMessage = JSON.parse(last_message)

    function handleLastChatTime(time: string) {
        if (isSameDay(new Date(time), startOfToday())) {
            return format(new Date(time), 'HH:mm')
        } else if (isYesterday(new Date(time))) {
            return t('diskusi_tugas_kemarin')
        } else {
            return format(new Date(time), 'dd/MM/yyyy')
        }
    }

    function handleMaxWidth(time: string) {
        if (isSameDay(new Date(time), startOfToday())) {
            return '235px'
        } else if (isYesterday(new Date(time))) {
            return '215px'
        } else {
            return '195px'
        }
    }

    return (
        <Flex 
            ref={_ref}
            cursor='pointer'
            onClick={onClick}
            paddingY='16px'
            marginX='16px'
            borderBottomWidth={isLastChild ? '1px' : '0px'}
            borderBottomColor={isLastChild ? 'neutral30' : 'unset'}
            justifyContent='space-between'
        >
            <Flex gap='16px'>
                <Avatar 
                    name={customerName} 
                    src={customerPicture} 
                    height='48px' 
                    width='48px' 
                />
                <Flex 
                    flexDirection='column' 
                    position='relative' 
                    gap='8px'
                >
                    <JTypography size='label-small' fontWeight='400' color='onBackground'>
                        {task_id}
                    </JTypography>
                    <JTypography
                        width={handleMaxWidth(last_message_time)}
                        overflow='hidden' 
                        whiteSpace='nowrap'
                        textOverflow='ellipsis'  
                        size='title-medium'
                        color='onSurface'
                        fontWeight='500'
                        lineHeight='18.75px'
                    >
                        {title} ({member_count})
                    </JTypography>
                    <Flex 
                        overflow='hidden' 
                        whiteSpace='nowrap' 
                        gap='5px'
                        marginTop='1px'
                    >
                        <JTypography 
                            width={handleMaxWidth(last_message_time)}
                            overflow='hidden' 
                            whiteSpace='nowrap'
                            textOverflow='ellipsis' 
                            size='label-medium'
                            fontWeight='400'
                            lineHeight='20px'
                        >
                            {last_sender}:{' '}
                            {isDeleted 
                                ?   <>
                                        <JGoogleIcon 
                                            name='hide_source' 
                                            fontSize='body-medium' 
                                            margin='0px' 
                                            position='relative'
                                            top='2.5px'
                                        />
                                        {' '}
                                        [{t('diskusi_tugas_pesan_telah_dihapus')}]
                                    </>
                                :   lastMessage.text
                            }
                        </JTypography>
                    </Flex>
                    <JTypography
                        width={handleMaxWidth(last_message_time)}
                        overflow='hidden' 
                        whiteSpace='nowrap'
                        textOverflow='ellipsis'  
                        size='body-small'
                        fontWeight='400'
                        color='onSurface'
                        lineHeight='16px'
                    >
                        {description}
                    </JTypography>
                </Flex>
            </Flex>
            
            <Flex 
                flexDirection='column' 
                gap='8px' 
                alignItems='end'
            >
                <JTypography 
                    marginLeft='auto' 
                    size='body-medium' 
                    color='onSurface'
                    lineHeight='20px'
                >
                    {handleLastChatTime(last_message_time)}
                </JTypography>
                {unread_count !== 0 &&
                    <Center 
                        paddingX='5px'
                        borderRadius='16px' 
                        minHeight='16px'
                        minWidth='16px'
                        backgroundColor='errorContainer'
                    >
                        <JTypography 
                            size='label-small'
                            fontSize='label-small'
                            lineHeight='short'
                        >
                            {unread_count}
                        </JTypography>
                    </Center>
                }
            </Flex>
        </Flex>
    )
}

export default ChatItem