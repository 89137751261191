import { Avatar, Flex, Text } from '@chakra-ui/react'
import style from './member-item.module.css'

interface MemberItemProps {
  profile_picture: string
  fullname: string
  email: string
  onClick: any
}

const MemberItem = (props: MemberItemProps) => {
  return (
    <Flex className={style.member_item} onClick={props.onClick}>
      <Avatar src={props.profile_picture} name={props.fullname} />
      <Flex className={style.name_email}>
        <Text className={style.name}>{props.fullname}</Text>
        <Text className={style.email}>{props.email}</Text>
      </Flex>
    </Flex>
  )
}

export default MemberItem