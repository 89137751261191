import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { JButton, JTypography } from '../../../components'
import { Configuration } from '../../../query/attendanceSettingQuery'

interface AttendanceMandatoryProps {
    is_open: boolean
    on_close: any
    configuration: Configuration
    on_cancel: any
    on_toggle: any
    is_loading: boolean
}

const AttendanceMandatory = (props: AttendanceMandatoryProps) => {
    const { t } = useTranslation()
    const { 
        is_open, 
        on_close, 
        configuration, 
        on_cancel, 
        on_toggle,
        is_loading,
    } = props

    return (
        <Modal isOpen={is_open} onClose={on_close}>
                <ModalOverlay />
                <ModalContent
                    backgroundColor={'surfacePlus1'}
                    padding={'24px'}
                >
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        top={'24px'}
                        right={'24px'}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JTypography size={'title-medium'}>
                            {t(configuration?.is_absence_mandatory_for_check_in === 1
                                ? 'pengaturan_absen_modal_matikan_wajib_absen_judul'
                                : 'pengaturan_absen_modal_nyalakan_wajib_absen_judul'
                            )}
                        </JTypography>
                        <JTypography size={'body-medium'}>
                            {t(configuration?.is_absence_mandatory_for_check_in === 1
                                ? 'pengaturan_absen_modal_matikan_wajib_absen_subjudul'
                                : 'pengaturan_absen_modal_nyalakan_wajib_absen_subjudul'
                            )}
                        </JTypography>
                    </ModalBody>
                    <ModalFooter
                        gap={'33px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JButton
                            size={'normal'}
                            variant={'j-outline'}
                            onClick={on_cancel}
                        >
                            {t('pengaturan_absen_modal_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={on_toggle}
                            isLoading={is_loading}
                        >
                            {t(configuration?.is_absence_mandatory_for_check_in === 1
                                ? 'pengaturan_absen_modal_matikan'
                                : 'pengaturan_absen_modal_nyalakan'
                            )}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )
}

export default AttendanceMandatory