import http from './http';
import urlApi from './url';

export interface AttendanceSetting_Get_MemberList_ApiProps {
  search: string
}

export interface AttendanceSetting_Create_AttendanceLocation_ApiProps {
  label: string
  radius: number
  latitude: number
  longitude: number
  address: string
  member_profile_id: any[]
}

export interface AttendanceSetting_Update_Status_ApiProps {
  is_absence_strict_location: number
}

export interface AttendanceSetting_Update_Configuration_ApiProps {
  is_absence_mandatory_for_check_in: number
  is_absence_strict_time: number
  limit_clock_in: string
  limit_clock_out: string
}

export interface AttendanceSetting_Update_AttendanceLocation_ApiProps {
  label: string
  radius: number
  latitude: number
  longitude: number
  address: string
  member_profile_id: string
  absence_location_id: number
}

export interface AttendanceSetting_Update_AttendanceLocationStatus_ApiProps {
  absence_location_id: number
  is_active: number
}

export interface AttendanceSetting_Delete_AttendanceLocation_ApiProps {
  absence_location_id: number
}

export async function attendanceSetting_get_memberListApi() {
    const team = localStorage.getItem('current_team') || ''
    const payload = {
      profile_id: team
    }
    const response: any = await http(true).post(urlApi.attendanceSetting.get.memberList, payload)
    return response
}

export async function attendanceSetting_get_attendanceLocationListApi() {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.get.areaList, payload)
  return response
}

export async function attendanceSetting_get_attendanceLocationDetailApi() {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.get.areaDetail, payload)
  return response
}

export async function attendanceSetting_get_ConfigurationApi() {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.get.configuration, payload)
  return response
}

export async function attendanceSetting_create_attendanceLocationApi(props: AttendanceSetting_Create_AttendanceLocation_ApiProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    ...props,
    profile_id: team,
    member_profile_id: JSON.stringify(props.member_profile_id)
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.create.area, payload)
  return response
}

export async function attendanceSetting_update_status(props: AttendanceSetting_Update_Status_ApiProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.update.status, payload)
  return response
}

export async function attendanceSetting_update_configuration(props: AttendanceSetting_Update_Configuration_ApiProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.update.configuration, payload)
  return response
}

export async function attendanceSetting_update_attendanceLocation(props: AttendanceSetting_Update_AttendanceLocation_ApiProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.update.area, payload)
  return response
}

export async function attendanceSetting_update_attendanceLocationStatus(props: AttendanceSetting_Update_AttendanceLocationStatus_ApiProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.update.areaStatus, payload)
  return response
}

export async function attendanceSetting_delete_attendanceLocation(props: AttendanceSetting_Delete_AttendanceLocation_ApiProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props
  }
  const response: any = await http(true).post(urlApi.attendanceSetting.delete.area, payload)
  return response
}