import { Text, TextProps } from '@chakra-ui/react'

interface JGoogleIconProps extends TextProps {
    name: string
}

const JGoogleIcon = (props: JGoogleIconProps) => {
    return (
        <Text
            as={'span'}
            className={'material-symbols-outlined'}
            {...props}
        >
            {props.name}
        </Text>
    )
}

export default JGoogleIcon
