import axios from "axios"

const http = (withAuth = false) => {
    const config: any = {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {}
    }

    if (withAuth) config.headers.token = localStorage.getItem('access_token')

    const axiosInstance = axios.create(config)
    axiosInstance.interceptors.response.use(res => res.data)

    return axiosInstance
}

export default http


