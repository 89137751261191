import {
    Fragment, 
    useState
} from 'react'
import {
    Avatar,
    Checkbox,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Skeleton,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Switch,
    useToast,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {
    JButton, JGoogleIcon, JInput, JTypography 
} from '../../components'
import {
    useGetMemberSettingStatus,
    useGetMemberSettingAreas,
    useGetMemberSettingMemberList,
    useGetMemberSettingMemberListForNotify,
    useCreateMemberSettingArea,
    useEditMemberSettingStatus,
    useEditMemberSettingStatusArea,
    useEditMemberSettingArea,
    useDeleteMemberSettingArea,
} from '../../query'
import Area from './components/Area'
import PlacesAutocomplete, {
    geocodeByAddress, getLatLng
} from 'react-places-autocomplete'
import {
    Circle, GoogleMap, MarkerF 
} from '@react-google-maps/api'
import JToast from '../../components/JToast/JToast'

interface InitialAreaProps {
    centerPoint: string
    centerPointLatLng: {
        lat: number
        lng: number
    }
    centerPointAddress: string
    radius: number
    isRadiusTolranceOn: boolean
    radiusTolerance: number
    members: any[]
    notify: any[]
    label: string
    startTime: string
    endTime: string
}

const MemberSetting = () => {
    const {t} = useTranslation()
    const toast = useToast()

    const [isUpdateFlow, setIsUpdateFlow] = useState<any>(false)

    const [area, setArea] = useState<any>(null)
    const [searchArea, setSearchArea] = useState('')
    const [searchChooseMember, setSearchChooseMember] = useState('')
    const [searchSendAlert, setSearchSendAlert] = useState('')

    const [modalToggleStatus, setModalToggleStatus] = useState(false)
    const [modalToggleStatusArea, setModalToggleStatusArea] = useState(false)
    const [modalDeleteArea, setModalDeleteArea] = useState(false)

    const [initialAreaDefault] = useState<InitialAreaProps>({
        centerPoint: '',
        centerPointLatLng: {
            lat: -6.2213866,
            lng: 106.7796252,
        },
        centerPointAddress: '',
        radius: 50,
        isRadiusTolranceOn: false,
        radiusTolerance: 0,
        members: [],
        notify: [],
        label: '',
        startTime: '00:00:00',
        endTime: '00:00:00',
    })
    const [initialArea, setInitialArea] = useState<InitialAreaProps>(initialAreaDefault)

    const [modalTentukanLokasi, setModalTentukanLokasi] = useState(false)
    const [modalTentukanAnggota, setModalTentukanAnggota] = useState(false)
    const [modalTentukanPeringatan, setModalTentukanPeringatan] = useState(false)
    const [modalDetailAreaLokasi, setModalDetailAreaLokasi] = useState(false)

    const {
        data: memberSettingStatus,
        refetch: refetchMemberSettingStatus,
        isFetching: fetchingMemberSettingStatus,
    } = useGetMemberSettingStatus()
    const {
        isFetching: fetchingMemberSettingAreas,
        refetch: refetchMemberSettingAreas,
        data: memberSettingAreas,
    } = useGetMemberSettingAreas()
    const {
        isFetching: fetchingMemberSettingMemberList,
        data: memberSettingMemberList,
        refetch: refetchGetMemberSettingMemberList,
    } = useGetMemberSettingMemberList()
    const {
        isFetching: fetchingMemberSettingMemberListForNotify,
        data: memberSettingMemberListForNotify,
        refetch: refetchGetMemberSettingMemberListForNotify,
    } = useGetMemberSettingMemberListForNotify()
    const {
        isFetching: fetchingCreateMemberSettingArea, 
        refetch: refetchCreateMemberSettingArea
    } = useCreateMemberSettingArea(initialArea)
    const {
        isFetching: fetchingUpdateMemberSettingStatus, 
        refetch: refetchUpdateMemberSettingStatus
    } = useEditMemberSettingStatus(memberSettingStatus || false)
    const {
        isFetching: fetchingUpdateMemberSettingStatusArea, 
        refetch: refetchUpdateMemberSettingStatusArea
    } = useEditMemberSettingStatusArea(area)
    const {
        isFetching: fetchingUpdateMemberSettingArea, 
        refetch: refetchUpdateMemberSettingArea
    } = useEditMemberSettingArea({
        ...initialArea,
        id: isUpdateFlow?.id,
    })
    const {
        isFetching: fetchingDeleteMemberSettingArea, 
        refetch: refetchDeleteMemberSettingArea
    } = useDeleteMemberSettingArea(area)

    function handleSelectMember(user: any, type: 'chooseMember' | 'sendAlert') {
        const userId = user.id
        const tempMembers: any[] = []
        const actionType = type === 'chooseMember' ? 'members' : 'notify'
        if (
            !user.checked &&
            actionType === 'notify' &&
            initialArea[actionType].find((group: any) => group.members.some((member: any) => member.checked === true))
        )
            return
        initialArea[actionType].forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.id === userId) {
                    member.checked = !member.checked
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                members: actionType === 'members' ? tempMembers : prev.members,
                notify: actionType === 'notify' ? tempMembers : prev.notify,
            }
        })
    }

    function handleSelectGroupMember(group: any, type: 'chooseMember' | 'sendAlert') {
        const groupCheck = group.checked
        const groupMember = group.members.map((member: any) => member.id)
        const tempMembers: any = []
        const actionType = type === 'chooseMember' ? 'members' : 'notify'
        initialArea[actionType].forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (groupMember.includes(member.id)) {
                    member.checked = !groupCheck
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                members: type === 'chooseMember' ? tempMembers : prev.members,
                notify: type === 'sendAlert' ? tempMembers : prev.notify,
            }
        })
    }

    function handleDeleteMember(member: any, type: 'chooseMember' | 'sendAlert') {
        const memberId = member?.id
        const tempMembers: any = []
        const actionType = type === 'chooseMember' ? 'members' : 'notify'
        initialArea[actionType].forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.id === memberId) {
                    member.checked = !member.checked
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                members: type === 'chooseMember' ? tempMembers : prev.members,
                notify: type === 'sendAlert' ? tempMembers : prev.notify,
            }
        })
    }

    function handlePrepareaUpdate(area: any) {
        const tempMembers = memberSettingMemberList
        const tempNotify = memberSettingMemberListForNotify
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if (area.members.find((m: any) => m.id === member.id)) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
            if (group.members.find((m: any) => m.checked === false) === undefined) {
                group.checked = true
            } else {
                group.checked = false
            }
        })
        tempNotify.map((group: any) => {
            group.members.map((member: any) => {
                if (member.id === area.member_notify.id) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
        })

        setSearchChooseMember('')
        setSearchSendAlert('')
        setInitialArea({
            label: area.label,
            radius: area.radius,
            isRadiusTolranceOn: area.is_radius_tolerance_active === 1 ? true : false,
            radiusTolerance: area.radius_tolerance,
            centerPointLatLng: {
                lat: area.latitude,
                lng: area.longitude,
            },
            centerPointAddress: area.address,
            startTime: area.start_time,
            endTime: area.end_time,
            centerPoint: area.address,
            members: tempMembers,
            notify: tempNotify,
        })
        setIsUpdateFlow(area)
        setModalTentukanLokasi(true)
    }

    function RenderSelectedMembers(type: 'chooseMember' | 'sendAlert') {
        const selectedMembers: any = []
        const actionType = type === 'chooseMember' ? 'members' : 'notify'
        initialArea[actionType].forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.checked === true) {
                    if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                }
            })
        })

        return (
            <Flex
                direction={'column'}
                gap={'16px'}
            >
                {type === 'sendAlert' ? (
                    <JTypography size={'title-medium'}>
                        {t('pengaturan_anggota_anggota_terpilih', {total: selectedMembers.length})}
                    </JTypography>
                ) : (
                    <JTypography size={'title-medium'}>{t('pengaturan_anggota_anggota_terdaftar')}</JTypography>
                )}
                {selectedMembers.map((member: any) => (
                    <Flex
                        key={member.id}
                        gap={'24px'}
                        alignItems={'center'}
                    >
                        <Avatar name={member.display_name} />
                        <Flex
                            direction={'column'}
                            gap={'4px'}
                        >
                            <JTypography
                                size='title-medium'
                                wordBreak={'break-word'}
                            >
                                {member.display_name}
                            </JTypography>
                            <JTypography
                                size='body-medium'
                                wordBreak={'break-word'}
                            >
                                {member.username}
                            </JTypography>
                        </Flex>
                        <JGoogleIcon
                            name='delete'
                            cursor={'pointer'}
                            onClick={() => handleDeleteMember(member, type)}
                        />
                    </Flex>
                ))}
            </Flex>
        )
    }

    function RenderSelectedChooseMember() {
        const selectedMembers: any = []
        initialArea.members.map((group: any) => {
            group.members.map((member: any) => {
                if (member.checked && selectedMembers.find((sm: any) => sm.id === member.id) === undefined) selectedMembers.push(member)
            })
        })
        return (
            <Flex alignItems={'center'}>
                {selectedMembers.map((member: any, index: number) => {
                    if (index <= 3)
                        return (
                            <Avatar
                                width={'24px'}
                                height={'24px'}
                                key={index}
                                name={member.display_name}
                                src={member.photo_url}
                                marginLeft={'-6px'}
                            />
                        )
                })}
                <JTypography
                    size={'label-medium'}
                    marginLeft={'16px'}
                >
                    {t('pengaturan_anggota_area_jumlah_anggota', {total: selectedMembers.length})}
                </JTypography>
            </Flex>
        )
    }

    return (
        <Flex
            gap={'24px'}
            width={'100%'}
            direction={'column'}
        >
            <JTypography size='title-large'>{t('pengaturan_anggota_judul')}</JTypography>
            <Flex
                p='24px 16px'
                borderRadius={'16px'}
                backgroundColor={'surfacePlus1'}
                gap={'24px'}
                minHeight={'calc(100vh - var(--headerHeight) - 32px - 24px - 33px)'}
                width={'100%'}
            >
                <Flex
                    width='30%'
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    height='min-content'
                >
                    <Flex>
                        <JTypography size='label-large'>{t('pengaturan_anggota_live_tracking')}</JTypography>
                    </Flex>
                    <Flex
                        gap={'16px'}
                        alignItems={'center'}
                    >
                        {fetchingMemberSettingStatus ? (
                            <Skeleton
                                width={'48px'}
                                height={'20px'}
                            />
                        ) : (
                            <JTypography size='title-small'>
                                {memberSettingStatus
                                    ? t('pengaturan_anggota_live_tracking_aktif')
                                    : t('pengaturan_anggota_live_tracking_tidak_aktif')}
                            </JTypography>
                        )}
                        <JGoogleIcon name='navigate_next' />
                    </Flex>
                </Flex>
                <Divider
                    borderColor={'neutral.30'}
                    orientation='vertical'
                />
                <Flex
                    width='70%'
                    direction={'column'}
                    gap={'24px'}
                >
                    <Flex justifyContent={'space-between'}>
                        <Flex
                            direction={'column'}
                            gap={'8px'}
                        >
                            <JTypography size={'title-medium'}>{t('pengaturan_anggota_live_tracking')}</JTypography>
                            <JTypography size={'body-medium'}>{t('pengaturan_anggota_tujuan')}</JTypography>
                        </Flex>
                        {fetchingMemberSettingStatus ? (
                            <Skeleton
                                width={'48px'}
                                height={'20px'}
                            />
                        ) : (
                            <Switch
                                isChecked={memberSettingStatus}
                                onClickCapture={() => {
                                    setModalToggleStatus(true)
                                }}
                                height={'min-content'}
                            />
                        )}
                    </Flex>
                    <Divider
                        borderColor={'neutral.30'}
                        orientation={'horizontal'}
                    />
                    <Flex
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <JTypography size={'label-large'}>{t('pengaturan_anggota_daftar_live_tracking')}</JTypography>
                        <JButton
                            variant={'j-primary'}
                            leftIcon={<JGoogleIcon name='add' />}
                            size={'normal'}
                            disabled={
                                memberSettingStatus === false ||
                                memberSettingStatus === undefined ||
                                fetchingMemberSettingMemberList ||
                                fetchingMemberSettingMemberListForNotify
                            }
                            onClick={() => {
                                const tempMember = memberSettingMemberList
                                const tempNotify = memberSettingMemberListForNotify
                                tempMember.map((group: any) => {
                                    group.checked = false
                                    group.members.map((member: any) => {
                                        member.checked = false
                                    })
                                })
                                tempNotify.map((group: any) => {
                                    group.checked = false
                                    group.members.map((member: any) => {
                                        member.checked = false
                                    })
                                })
                                setIsUpdateFlow(null)
                                setSearchChooseMember('')
                                setSearchSendAlert('')
                                setInitialArea({
                                    ...initialAreaDefault,
                                    members: tempMember,
                                    notify: tempNotify,
                                })
                                setModalTentukanLokasi(true)
                            }}
                        >
                            {t('pengaturan_anggota_tambah_area_live_tracking')}
                        </JButton>
                    </Flex>
                    <JInput
                        type={'search'}
                        placeholder={t('pengaturan_anggota_cari_area_live_tracking')}
                        value={searchArea}
                        onChange={(e: any) => setSearchArea(e.target.value)}
                        leftIcon={<JGoogleIcon name={'search'} />}
                    />
                    {memberSettingAreas?.map((area: any, index: number) => {
                        const Element = () => (
                            <Area
                                onClickSwitch={() => {
                                    setArea(area)
                                    setModalToggleStatusArea(true)
                                }}
                                onClickDelete={() => {
                                    setArea(area)
                                    setModalDeleteArea(true)
                                }}
                                onClickUpdate={() => handlePrepareaUpdate(area)}
                                {...area}
                            />
                        )
                        if (searchArea === '') {
                            return <Element key={index} />
                        } else if (searchArea !== '' && area.label.toLowerCase().includes(searchArea)) {
                            return <Element key={index} />
                        }
                    })}
                    {fetchingMemberSettingAreas && (
                        <Flex
                            direction={'column'}
                            gap={'16px'}
                        >
                            <Skeleton
                                height={'252px'}
                                width={'100%'}
                                borderRadius={'16px'}
                            />
                            <Skeleton
                                height={'252px'}
                                width={'100%'}
                                borderRadius={'16px'}
                            />
                            <Skeleton
                                height={'252px'}
                                width={'100%'}
                                borderRadius={'16px'}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>

            {/* modal toggle status member setting */}
            <Modal
                isOpen={modalToggleStatus}
                onClose={() => setModalToggleStatus(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingUpdateMemberSettingStatus}
                        onClick={() => setModalToggleStatus(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <JTypography size={'title-medium'}>
                            {memberSettingStatus
                                ? t('pengaturan_anggota_live_tracking_status_modal_matikan_judul')
                                : t('pengaturan_anggota_live_tracking_status_modal_nyalakan_judul')}
                        </JTypography>
                        <JTypography size={'body-medium'}>{t('pengaturan_anggota_live_tracking_status_modal_subjudul')}</JTypography>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-text'}
                            onClick={() => setModalToggleStatus(false)}
                            isDisabled={fetchingUpdateMemberSettingStatus}
                        >
                            {t('pengaturan_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                await refetchUpdateMemberSettingStatus()
                                await refetchMemberSettingAreas()
                                await refetchMemberSettingStatus()
                                setModalToggleStatus(false)
                                toast({
                                    position: 'top-right',
                                    render: () => (
                                        <JToast
                                            type={'success'}
                                            message={t(
                                                memberSettingStatus
                                                    ? 'pengaturan_anggota_sukses_matikan_alt'
                                                    : 'pengaturan_anggota_sukses_nyalakan_alt',
                                            )}
                                        />
                                    ),
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }}
                            isLoading={fetchingUpdateMemberSettingStatus}
                        >
                            {memberSettingStatus
                                ? t('pengaturan_anggota_live_tracking_matikan')
                                : t('pengaturan_anggota_live_tracking_nyalakan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal toggle status area */}
            <Modal
                isOpen={modalToggleStatusArea}
                onClose={() => setModalToggleStatusArea(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingUpdateMemberSettingStatusArea}
                        onClick={() => setModalToggleStatusArea(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <JTypography size={'title-medium'}>
                            {area !== null && area.is_active === 1
                                ? t('pengaturan_anggota_live_tracking_area_status_modal_matikan_judul', {label: area?.label})
                                : t('pengaturan_anggota_live_tracking_area_status_modal_nyalakan_judul', {label: area?.label})}
                        </JTypography>
                        <JTypography size={'body-medium'}>
                            {area !== null && area.is_active === 1
                                ? t('pengaturan_anggota_live_tracking_area_status_modal_matikan_subjudul')
                                : t('pengaturan_anggota_live_tracking_area_status_modal_nyalakan_subjudul')}
                        </JTypography>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-text'}
                            onClick={() => setModalToggleStatusArea(false)}
                            isDisabled={fetchingUpdateMemberSettingStatusArea}
                        >
                            {t('pengaturan_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                await refetchUpdateMemberSettingStatusArea()
                                await refetchMemberSettingAreas()
                                setModalToggleStatusArea(false)
                                toast({
                                    position: 'top-right',
                                    render: () => (
                                        <JToast
                                            type={'success'}
                                            message={`${t(
                                                area !== null && area.is_active === 1
                                                    ? 'pengaturan_anggota_sukses_nonaktifkan_alt'
                                                    : 'pengaturan_anggota_sukses_aktifkan_alt',
                                                {label: area.label},
                                            )}`}
                                        />
                                    ),
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }}
                            isLoading={fetchingUpdateMemberSettingStatusArea}
                        >
                            {area !== null && area.is_active === 1
                                ? t('pengaturan_anggota_live_tracking_matikan')
                                : t('pengaturan_anggota_live_tracking_nyalakan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal delete area */}
            <Modal
                isOpen={modalDeleteArea}
                onClose={() => setModalDeleteArea(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingDeleteMemberSettingArea}
                        onClick={() => setModalDeleteArea(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <JTypography size={'title-medium'}>
                            {t('pengaturan_anggota_live_tracking_hapus_area_judul', {label: area?.label})}
                        </JTypography>
                        <JTypography size={'body-medium'}>{t('pengaturan_anggota_live_tracking_hapus_area_subjudul')}</JTypography>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-text'}
                            onClick={() => setModalDeleteArea(false)}
                            isDisabled={fetchingDeleteMemberSettingArea}
                        >
                            {t('pengaturan_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                await refetchDeleteMemberSettingArea()
                                await refetchMemberSettingAreas()
                                setModalDeleteArea(false)
                                toast({
                                    position: 'top-right',
                                    render: () => (
                                        <JToast
                                            type={'success'}
                                            message={t('pengaturan_anggota_sukses_hapus_alt')}
                                        />
                                    ),
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }}
                            isLoading={fetchingDeleteMemberSettingArea}
                        >
                            {t('pengaturan_anggota_hapus')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 1/4 tentukan lokasi */}
            <Modal
                isOpen={modalTentukanLokasi}
                onClose={() => setModalTentukanLokasi(false)}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'xl'}
                blockScrollOnMount={true}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={'12px'}
                    >
                        <JTypography size={'body-small'}>1/4</JTypography>
                        <JTypography size={'title-large'}>{t('pengaturan_anggota_tetapkan_letak_area')}</JTypography>
                    </ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingDeleteMemberSettingArea}
                        onClick={() => setModalTentukanLokasi(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'24px'}
                    >
                        <PlacesAutocomplete
                            value={initialArea.centerPoint}
                            onChange={(e: any) => {
                                setInitialArea((prev: any) => {
                                    return {
                                        ...prev,
                                        centerPoint: e,
                                    }
                                })
                            }}
                            onSelect={async (e: any) => {
                                const results = await geocodeByAddress(e)
                                const latLng = await getLatLng(results[0])
                                setInitialArea((prev: any) => {
                                    return {
                                        ...prev,
                                        centerPoint: e,
                                        centerPointLatLng: {
                                            lat: latLng.lat,
                                            lng: latLng.lng,
                                        },
                                        centerPointAddress: e,
                                    }
                                })
                            }}
                        >
                            {({
                                getInputProps, suggestions, getSuggestionItemProps, loading 
                            }) => (
                                <div>
                                    <JTypography
                                        size={'label-large'}
                                        marginBottom={'8px'}
                                    >
                                        {t('pengaturan_anggota_titik_pusat')}
                                    </JTypography>
                                    <JInput
                                        {...getInputProps({
                                            placeholder: t('pengaturan_anggota_cari_lokasi'),
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className='autocomplete-dropdown-container'>
                                        {loading && (
                                            <Progress
                                                marginTop={'10px'}
                                                size='xs'
                                                isIndeterminate
                                            />
                                        )}
                                        {suggestions.map((suggestion, index) => {
                                            return (
                                                <Flex
                                                    {...getSuggestionItemProps(suggestion)}
                                                    key={index}
                                                    padding={'5px'}
                                                    direction={'column'}
                                                    borderBottomColor={'neutral.30'}
                                                    borderBottomWidth={'1px'}
                                                    cursor={'pointer'}
                                                >
                                                    <JTypography size={'label-large'}>{suggestion.description}</JTypography>
                                                </Flex>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        <GoogleMap
                            mapContainerStyle={{
                                height: '218px',
                                width: '100%',
                                borderRadius: '16px',
                            }}
                            center={initialArea.centerPointLatLng}
                            zoom={15}
                            clickableIcons={false}
                        >
                            <MarkerF position={initialArea.centerPointLatLng} />
                            <Circle
                                center={initialArea.centerPointLatLng}
                                radius={initialArea.radius}
                                options={{
                                    strokeColor: 'transparent',
                                    fillColor: '#0079D2',
                                    fillOpacity: 0.19,
                                    zIndex: 2,
                                }}
                            />
                            {initialArea.radiusTolerance && (
                                <Circle
                                    center={initialArea.centerPointLatLng}
                                    radius={initialArea.radius + initialArea.radiusTolerance}
                                    options={{
                                        strokeColor: 'transparent',
                                        fillColor: '#D27E00',
                                        fillOpacity: 0.19,
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </GoogleMap>
                        {initialArea.centerPointAddress !== '' && (
                            <JTypography size={'body-medium'}>{initialArea.centerPointAddress}</JTypography>
                        )}
                        <Divider borderColor={'neutral.30'} />
                        <Flex
                            direction={'column'}
                            gap={'16px'}
                        >
                            <Flex>
                                <Flex
                                    gap={'8px'}
                                    direction={'column'}
                                >
                                    <JTypography size={'label-large'}>{t('pengaturan_anggota_radius_lacak')}*</JTypography>
                                    <JTypography size={'body-medium'}>{t('pengaturan_anggota_radius_lacak_sublabel')}</JTypography>
                                </Flex>
                                <JInput
                                    rightIcon={'m'}
                                    wrapperWidth={'90px'}
                                    width={'90px'}
                                    type={'number'}
                                    min={50}
                                    max={1000}
                                    value={initialArea.radius}
                                    onChange={(e: any) => {
                                        setInitialArea((prev: any) => {
                                            return {
                                                ...prev,
                                                radius: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </Flex>
                            <Slider
                                aria-label='slider-ex-1'
                                min={50}
                                max={1000}
                                value={initialArea.radius}
                                onChange={(e: any) => {
                                    setInitialArea((prev: any) => {
                                        return {
                                            ...prev,
                                            radius: e,
                                        }
                                    })
                                }}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                        </Flex>
                        <Divider borderColor={'neutral.30'} />
                        <Flex
                            direction={'column'}
                            gap={'16px'}
                        >
                            <Flex>
                                <Flex
                                    gap={'8px'}
                                    direction={'column'}
                                >
                                    <JTypography size={'label-large'}>{t('pengaturan_anggota_radius_toleransi')}</JTypography>
                                    <JTypography size={'body-medium'}>{t('pengaturan_anggota_radius_toleransi_sublabel')}</JTypography>
                                </Flex>
                                <Switch
                                    height={'min-content'}
                                    isChecked={initialArea.isRadiusTolranceOn}
                                    onChange={(e: any) => {
                                        setInitialArea((prev: any) => {
                                            return {
                                                ...prev,
                                                isRadiusTolranceOn: e.target.checked,
                                            }
                                        })
                                    }}
                                />
                            </Flex>
                            <Flex
                                direction={'column'}
                                gap={'8px'}
                                alignItems={'flex-end'}
                            >
                                <JInput
                                    rightIcon={'m'}
                                    wrapperWidth={'90px'}
                                    width={'90px'}
                                    type={'number'}
                                    min={0}
                                    max={100}
                                    value={initialArea.radiusTolerance}
                                    isDisabled={!initialArea.isRadiusTolranceOn}
                                    onChange={(e: any) => {
                                        setInitialArea((prev: any) => {
                                            return {
                                                ...prev,
                                                radiusTolerance: e.target.value,
                                            }
                                        })
                                    }}
                                />
                                {!initialArea.isRadiusTolranceOn ? (
                                    <Slider
                                        aria-label='slider-ex-1'
                                        min={0}
                                        max={100}
                                        value={initialArea.radiusTolerance}
                                        cursor={'not-allowed'}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                    </Slider>
                                ) : (
                                    <Slider
                                        aria-label='slider-ex-1'
                                        min={0}
                                        max={100}
                                        value={initialArea.radiusTolerance}
                                        cursor={'pointer'}
                                        onChange={(e: any) => {
                                            setInitialArea((prev: any) => {
                                                return {
                                                    ...prev,
                                                    radiusTolerance: e,
                                                }
                                            })
                                        }}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                    </Slider>
                                )}
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => {
                                setModalTentukanLokasi(false)
                                setModalTentukanAnggota(true)
                            }}
                            leftIcon={<JGoogleIcon name={'navigate_next'} />}
                            isDisabled={initialArea.centerPointAddress === ''}
                        >
                            {t('pengaturan_anggota_live_tracking_lanjut')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 2/4 tentukan anggota */}
            <Modal
                isOpen={modalTentukanAnggota}
                onClose={() => setModalTentukanAnggota(false)}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'xl'}
                blockScrollOnMount={true}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <JGoogleIcon
                        name={'arrow_back'}
                        cursor={'pointer'}
                        position={'absolute'}
                        top={'8px'}
                        left={'12px'}
                        onClick={() => {
                            setModalTentukanAnggota(false)
                            setModalTentukanLokasi(true)
                        }}
                    />
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={'12px'}
                    >
                        <JTypography size={'body-small'}>2/4</JTypography>
                        <JTypography size={'title-large'}>{t('pengaturan_anggota_tentukan_anggota')}</JTypography>
                    </ModalHeader>
                    <ModalCloseButton onClick={() => setModalTentukanAnggota(false)} />
                    <ModalBody
                        as={Flex}
                        gap={'24px'}
                    >
                        <Flex
                            direction={'column'}
                            gap={'8px'}
                            width={'50%'}
                        >
                            <JTypography size={'title-medium'}>{t('pengaturan_anggota_tambah_anggota')}</JTypography>
                            <JTypography size={'body-medium'}>{t('pengaturan_anggota_tambah_anggota_sublabel')}</JTypography>
                            <JTypography
                                size={'label-large'}
                                marginTop={'8px'}
                            >
                                {t('pengaturan_anggota_cari_anggota')}
                            </JTypography>
                            <JInput
                                leftIcon={<JGoogleIcon name={'search'} />}
                                type={'search'}
                                value={searchChooseMember}
                                onChange={(e: any) => setSearchChooseMember(e.target.value)}
                            />
                            <Flex
                                direction={'column'}
                                gap={'18px'}
                            >
                                {initialArea.members.map((member: any, index: number) => {
                                    return (
                                        <Fragment key={index}>
                                            <Checkbox
                                                isChecked={member.checked}
                                                onChange={() => handleSelectGroupMember(member, 'chooseMember')}
                                            >
                                                {member.display_name}
                                            </Checkbox>
                                            <Flex
                                                direction={'column'}
                                                gap={'24px'}
                                                marginLeft={'19px'}
                                            >
                                                {member.members.map((user: any, index: number) => {
                                                    const MemberChooseMember = () => (
                                                        <Checkbox
                                                            key={index}
                                                            isChecked={user.checked}
                                                            gap={'16px'}
                                                            onChange={() => {
                                                                handleSelectMember(user, 'chooseMember')
                                                            }}
                                                        >
                                                            <Flex
                                                                gap={'24px'}
                                                                alignItems={'center'}
                                                            >
                                                                <Avatar name={user.display_name} />
                                                                <Flex
                                                                    direction={'column'}
                                                                    gap={'4px'}
                                                                >
                                                                    <JTypography
                                                                        size='title-medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.display_name}
                                                                    </JTypography>
                                                                    <JTypography
                                                                        size='body-medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.username}
                                                                    </JTypography>
                                                                </Flex>
                                                            </Flex>
                                                        </Checkbox>
                                                    )
                                                    if (searchChooseMember === '') return <MemberChooseMember />
                                                    else if (
                                                        searchChooseMember !== '' &&
                                                        user.display_name.toLowerCase().includes(searchChooseMember)
                                                    )
                                                        return <MemberChooseMember />
                                                })}
                                            </Flex>
                                        </Fragment>
                                    )
                                })}
                            </Flex>
                        </Flex>
                        <Flex
                            width={'50%'}
                            direction={'column'}
                        >
                            {RenderSelectedMembers('chooseMember')}
                        </Flex>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => {
                                setModalTentukanAnggota(false)
                                setModalTentukanPeringatan(true)
                            }}
                            leftIcon={<JGoogleIcon name={'navigate_next'} />}
                            isDisabled={
                                initialArea.members.find((group: any) =>
                                    group.members.some((member: any) => member.checked === true),
                                ) === undefined
                            }
                        >
                            {t('pengaturan_anggota_live_tracking_lanjut')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 3/4 kirim peringatan */}
            <Modal
                isOpen={modalTentukanPeringatan}
                onClose={() => setModalTentukanPeringatan(false)}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'xl'}
                blockScrollOnMount={true}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <JGoogleIcon
                        name={'arrow_back'}
                        cursor={'pointer'}
                        position={'absolute'}
                        top={'8px'}
                        left={'12px'}
                        onClick={() => {
                            setModalTentukanPeringatan(false)
                            setModalTentukanAnggota(true)
                        }}
                    />
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={'12px'}
                    >
                        <JTypography size={'body-small'}>3/4</JTypography>
                        <JTypography size={'title-large'}>{t('pengaturan_anggota_kirim_peringatan')}</JTypography>
                    </ModalHeader>
                    <ModalCloseButton onClick={() => setModalTentukanPeringatan(false)} />
                    <ModalBody
                        as={Flex}
                        gap={'24px'}
                    >
                        <Flex
                            direction={'column'}
                            gap={'8px'}
                            width={'50%'}
                        >
                            <JTypography size={'title-medium'}>{t('pengaturan_anggota_kirim_peringatan')}</JTypography>
                            <JTypography size={'body-medium'}>{t('pengaturan_anggota_kirim_peringatan_sublabel')}</JTypography>
                            <JTypography
                                size={'label-large'}
                                marginTop={'8px'}
                            >
                                {t('pengaturan_anggota_cari_anggota')}
                            </JTypography>
                            <JInput
                                type={'search'}
                                leftIcon={<JGoogleIcon name={'search'} />}
                                value={searchSendAlert}
                                onChange={(e: any) => setSearchSendAlert(e.target.value)}
                            />
                            <Flex
                                direction={'column'}
                                gap={'18px'}
                            >
                                {initialArea.notify.map((member: any, index: number) => {
                                    return (
                                        <Fragment key={index}>
                                            <Checkbox
                                                isChecked={member.checked}
                                                cursor={'not-allowed'}
                                            >
                                                {member.display_name}
                                            </Checkbox>
                                            <Flex
                                                direction={'column'}
                                                gap={'24px'}
                                                marginLeft={'19px'}
                                            >
                                                {member.members.map((user: any, index: number) => {
                                                    const MemberSendAlert = () => (
                                                        <Checkbox
                                                            key={index}
                                                            isChecked={user.checked}
                                                            gap={'16px'}
                                                            onChange={() => handleSelectMember(user, 'sendAlert')}
                                                            cursor={
                                                                user.checked
                                                                    ? 'pointer'
                                                                    : initialArea.notify.find((group: any) =>
                                                                        group.members.some((member: any) => member.checked === true),
                                                                    )
                                                                        ? 'not-allowed'
                                                                        : 'pointer'
                                                            }
                                                        >
                                                            <Flex
                                                                gap={'24px'}
                                                                alignItems={'center'}
                                                            >
                                                                <Avatar name={user.display_name} />
                                                                <Flex
                                                                    direction={'column'}
                                                                    gap={'4px'}
                                                                >
                                                                    <JTypography
                                                                        size='title-medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.display_name}
                                                                    </JTypography>
                                                                    <JTypography
                                                                        size='body-medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.username}
                                                                    </JTypography>
                                                                </Flex>
                                                            </Flex>
                                                        </Checkbox>
                                                    )
                                                    if (searchSendAlert === '') return <MemberSendAlert />
                                                    else if (
                                                        searchSendAlert !== '' &&
                                                        user.display_name.toLowerCase().includes(searchSendAlert)
                                                    )
                                                        return <MemberSendAlert />
                                                })}
                                            </Flex>
                                        </Fragment>
                                    )
                                })}
                            </Flex>
                        </Flex>
                        <Flex
                            width={'50%'}
                            direction={'column'}
                        >
                            {RenderSelectedMembers('sendAlert')}
                        </Flex>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => {
                                setModalTentukanPeringatan(false)
                                setModalDetailAreaLokasi(true)
                            }}
                            leftIcon={<JGoogleIcon name={'navigate_next'} />}
                            isDisabled={
                                initialArea.notify.find((group: any) =>
                                    group.members.some((member: any) => member.checked === true),
                                ) === undefined
                            }
                        >
                            {t('pengaturan_anggota_live_tracking_lanjut')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 4/4 detail area lokasi */}
            <Modal
                isOpen={modalDetailAreaLokasi}
                onClose={() => setModalDetailAreaLokasi(false)}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'xl'}
                blockScrollOnMount={true}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <JGoogleIcon
                        name={'arrow_back'}
                        cursor={'pointer'}
                        position={'absolute'}
                        top={'8px'}
                        left={'12px'}
                        onClick={() => {
                            if (fetchingCreateMemberSettingArea) return
                            setModalDetailAreaLokasi(false)
                            setModalTentukanPeringatan(true)
                        }}
                    />
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={'12px'}
                    >
                        <JTypography size={'body-small'}>4/4</JTypography>
                        <JTypography size={'title-large'}>{t('pengaturan_anggota_berikan_detail_live_tracking')}</JTypography>
                    </ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingCreateMemberSettingArea}
                        onClick={() => setModalDetailAreaLokasi(false)}
                    />
                    <ModalBody>
                        <Flex gap={'24px'}>
                            <Flex
                                direction={'column'}
                                gap={'16px'}
                                width={'50%'}
                            >
                                <JTypography size='title-medium'>{t('pengaturan_anggota_detail_lokasi')}</JTypography>
                                <GoogleMap
                                    mapContainerStyle={{
                                        height: '346px',
                                        width: '100%',
                                        borderRadius: '16px',
                                    }}
                                    center={initialArea.centerPointLatLng}
                                    zoom={15}
                                    clickableIcons={false}
                                >
                                    <MarkerF position={initialArea.centerPointLatLng} />
                                    <Circle
                                        center={initialArea.centerPointLatLng}
                                        radius={initialArea.radius}
                                        options={{
                                            strokeColor: 'transparent',
                                            fillColor: '#0079D2',
                                            fillOpacity: 0.19,
                                            zIndex: 2,
                                        }}
                                    />
                                    {initialArea.radiusTolerance && (
                                        <Circle
                                            center={initialArea.centerPointLatLng}
                                            radius={initialArea.radius + initialArea.radiusTolerance}
                                            options={{
                                                strokeColor: 'transparent',
                                                fillColor: '#D27E00',
                                                fillOpacity: 0.19,
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </GoogleMap>
                                <JTypography size='body-medium'>{initialArea.centerPointAddress}</JTypography>
                                <JTypography size='body-medium'>
                                    {t('pengaturan_anggota_radius_sebesar', {radius: initialArea.radius})}
                                </JTypography>
                                {RenderSelectedChooseMember()}
                            </Flex>
                            <Flex
                                direction={'column'}
                                gap={'25px'}
                                width={'50%'}
                            >
                                <Flex
                                    direction={'column'}
                                    gap={'8px'}
                                >
                                    <JTypography size={'label-large'}>{t('pengaturan_anggota_label_lokasi')}*</JTypography>
                                    <JInput
                                        placeholder={t('pengaturan_anggota_label_lokasi')}
                                        value={initialArea.label}
                                        onChange={(e: any) =>
                                            setInitialArea((prev: any) => {
                                                return {
                                                    ...prev,
                                                    label: e.target.value,
                                                }
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex
                                    direction={'column'}
                                    gap={'8px'}
                                >
                                    <JTypography size={'label-large'}>{t('pengaturan_anggota_jam_tracking')}*</JTypography>
                                    <Flex gap={'8px'}>
                                        <JInput
                                            type={'time'}
                                            placeholder={t('pengaturan_anggota_jam_mulai')}
                                            value={initialArea.startTime}
                                            onChange={(e: any) =>
                                                setInitialArea((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        startTime: e.target.value,
                                                    }
                                                })
                                            }
                                        />
                                        <JInput
                                            type={'time'}
                                            placeholder={t('pengaturan_anggota_jam_akhir')}
                                            value={initialArea.endTime}
                                            onChange={(e: any) =>
                                                setInitialArea((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        endTime: e.target.value,
                                                    }
                                                })
                                            }
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter
                        gap={'33px'}
                        marginTop={'33px'}
                    >
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                if (!isUpdateFlow) {
                                    await refetchCreateMemberSettingArea()
                                } else {
                                    await refetchUpdateMemberSettingArea()
                                }
                                await refetchMemberSettingAreas()
                                await refetchMemberSettingStatus()
                                await refetchGetMemberSettingMemberList()
                                await refetchGetMemberSettingMemberListForNotify()
                                setModalDetailAreaLokasi(false)
                                if (!isUpdateFlow) {
                                    toast({
                                        position: 'top-right',
                                        render: () => (
                                            <JToast
                                                type={'success'}
                                                message={t('pengaturan_anggota_sukses_tambahkan_alt')}
                                            />
                                        ),
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }
                            }}
                            margin={'auto'}
                            isDisabled={
                                initialArea.label === '' || initialArea.startTime === '00:00:00' || initialArea.endTime === '00:00:00'
                            }
                            isLoading={fetchingCreateMemberSettingArea || fetchingUpdateMemberSettingArea}
                        >
                            {t('pengaturan_anggota_simpan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default MemberSetting
