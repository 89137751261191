import { Button as ChakraButton } from '@chakra-ui/react'
import style from "./button.module.css"

interface ButtonProps {
  width?: "full" | "fit"
  type?: "default" | "outline" | "text" | "tonal" | "elevated" | "icon"
  size?: "primary" | "small"
  loading?: boolean
  loadingText?: string
  text?: string
  icon?: any
  iconPosition?: "right" | "left"  
  gap?: string
  onClick?: any
  leftIcon?: string
  rightIcon?: string
  disable?: boolean

  // rest chakra props
  ml?: any
}

const Button = (props: ButtonProps) => {
  return (
    <ChakraButton 
      className={`
        ${style.button}
        ${props.width === "full" ? style.full : style.fit}
        ${props.type ? style[props.type] : style.default}
      `}
      onClick={props.onClick}
      isLoading={props.loading}
      loadingText={props.loadingText}
      isDisabled={props.disable}
      ml={props.ml}
      gap={props.gap || "10px"}
    >
      {props.leftIcon && <span className={`material-symbols-outlined ${style.icon}`}>{props.leftIcon}</span>}
      {props.text}
      {props.rightIcon && <span className={`material-symbols-outlined ${style.icon}`}>{props.rightIcon}</span>}
    </ChakraButton>
  )
}

export default Button