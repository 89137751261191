import { 
    Divider, 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    Progress, 
    Slider, 
    SliderFilledTrack, 
    SliderThumb, 
    SliderTrack,
} from '@chakra-ui/react'
import { 
    Circle, 
    GoogleMap, 
    MarkerF, 
} from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import { 
    JButton, 
    JGoogleIcon, 
    JInput, 
    JTypography,
} from '../../../components'

interface SpecifyLocationProps {
    is_open: boolean
    on_close: any
    on_next: any
    initial_area: any
    set_initial_area: any
    places_value: any
    places_on_change: any
    places_on_select: any
}

const SpecifyLocation = (props: SpecifyLocationProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        on_next,
        initial_area,
        set_initial_area,
        places_value,
        places_on_change,
        places_on_select,
    } = props

    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            size={'xl'}
            blockScrollOnMount={true}
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'surfacePlus1'}>
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={'12px'}
                >
                    <JTypography size={'body-small'}>1/3</JTypography>
                    <JTypography size={'title-large'}>{t('pengaturan_absen_modal_titik_lokasi_judul')}</JTypography>
                </ModalHeader>
                <ModalCloseButton onClick={on_close} />
                <ModalBody
                    as={Flex}
                    flexDirection={'column'}
                    gap={'24px'}
                >
                    <PlacesAutocomplete
                        value={places_value}
                        onChange={places_on_change}
                        onSelect={places_on_select}
                    >
                        {({
                            getInputProps, suggestions, getSuggestionItemProps, loading
                        }) => (
                            <div>
                                <JTypography
                                    size={'label-large'}
                                    marginBottom={'8px'}
                                >
                                    {t('pengaturan_absen_modal_titik_lokasi_cari_lokasi_label')}
                                </JTypography>
                                <JInput
                                    {...getInputProps({
                                        placeholder: t('pengaturan_absen_modal_titik_lokasi_pencarian_lokasi'),
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className='autocomplete-dropdown-container'>
                                    {loading && (
                                        <Progress
                                            marginTop={'10px'}
                                            size='xs'
                                            isIndeterminate
                                        />
                                    )}
                                    {suggestions.map((suggestion, index) => {
                                        return (
                                            <Flex
                                                {...getSuggestionItemProps(suggestion)}
                                                key={index}
                                                padding={'5px'}
                                                direction={'column'}
                                                borderBottomColor={'neutral.30'}
                                                borderBottomWidth={'1px'}
                                                cursor={'pointer'}
                                            >
                                                <JTypography size={'label-large'}>{suggestion.description}</JTypography>
                                            </Flex>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <GoogleMap
                        mapContainerStyle={{
                            height: '218px',
                            width: '100%',
                            borderRadius: '16px',
                        }}
                        center={{
                            lat: initial_area.latitude,
                            lng: initial_area.longitude
                        }}
                        zoom={15}
                        clickableIcons={false}
                    >
                        <MarkerF position={{
                            lat: initial_area.latitude,
                            lng: initial_area.longitude
                        }} />
                        <Circle
                            center={{
                                lat: initial_area.latitude,
                                lng: initial_area.longitude
                            }}
                            radius={initial_area.radius}
                            options={{
                                strokeColor: 'transparent',
                                fillColor: '#0079D2',
                                fillOpacity: 0.19,
                                zIndex: 2,
                            }}
                        />
                    </GoogleMap>
                    {initial_area.address !== '' && (
                        <JTypography size={'body-medium'}>{initial_area.address}</JTypography>
                    )}
                    <Divider borderColor={'neutral.30'} />
                    <Flex
                        direction={'column'}
                        gap={'16px'}
                    >
                        <Flex
                            gap={'8px'}
                            direction={'column'}
                        >
                            <JTypography size={'label-large'}>{t('pengaturan_absen_modal_titik_lokasi_radius_absen_label')}</JTypography>
                            <JTypography size={'body-medium'}>{t('pengaturan_absen_modal_titik_lokasi_radius_absen_sublabel')}</JTypography>
                        </Flex>
                        <Flex
                            alignItems={'center'}
                            width={'100%'}
                            justifyContent={'space-between'}
                        >
                            <JTypography size={'label-large'}>{t('pengaturan_absen_modal_titik_lokasi_radius')}</JTypography>
                            <JInput
                                rightIcon={'m'}
                                wrapperWidth={'90px'}
                                width={'90px'}
                                type={'number'}
                                min={50}
                                max={1000}
                                value={initial_area.radius}
                                onChange={(e: any) => {
                                    set_initial_area((prev: any) => {
                                        return {
                                            ...prev,
                                            radius: e.target.value,
                                        }
                                    })
                                }}
                            />
                        </Flex>
                        <Slider
                            aria-label='slider-ex-1'
                            min={50}
                            max={1000}
                            value={initial_area.radius}
                            onChange={(e: any) => {
                                set_initial_area((prev: any) => {
                                    return {
                                        ...prev,
                                        radius: e,
                                    }
                                })
                            }}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </Flex>
                </ModalBody>
                <ModalFooter gap={'33px'}>
                    <JButton
                        size={'normal'}
                        variant={'j-primary'}
                        onClick={on_next}
                        leftIcon={<JGoogleIcon name={'navigate_next'} />}
                    >
                        {t('pengaturan_anggota_live_tracking_lanjut')}
                    </JButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SpecifyLocation