function setLightColor() {
  document.documentElement.style.setProperty('--colorPrimary', '#0060A9');
  document.documentElement.style.setProperty('--colorOnPrimary', '#FFFFFF');
  document.documentElement.style.setProperty('--colorPrimaryContainer', '#D3E4FF');
  document.documentElement.style.setProperty('--colorOnPrimaryContainer', '#001C38');

  document.documentElement.style.setProperty('--colorSecondary', '#545F70');
  document.documentElement.style.setProperty('--colorOnSecondary', '#FFFFFF');
  document.documentElement.style.setProperty('--colorSecondaryContainer', '#D7E3F8');
  document.documentElement.style.setProperty('--colorOnSecondaryContainer', '#111C2B');
  
  document.documentElement.style.setProperty('--colorTertiary', '#6C5677');
  document.documentElement.style.setProperty('--colorOnTertiary', '#FFFFFF');
  document.documentElement.style.setProperty('--colorTertiaryContainer', '#F5D9FF');
  document.documentElement.style.setProperty('--colorOnTertiaryContainer', '#261431');
  
  document.documentElement.style.setProperty('--colorError', '#BA1A1A');
  document.documentElement.style.setProperty('--colorOnError', '#FFFFFF');
  document.documentElement.style.setProperty('--colorErrorContainer', '#FFDAD6');
  document.documentElement.style.setProperty('--colorOnErrorContainer', '#410002');
  
  document.documentElement.style.setProperty('--colorBackground', '#FDFCFF');
  document.documentElement.style.setProperty('--colorOnBackground', '#1A1C1E');
  
  document.documentElement.style.setProperty('--colorSurface', '#FDFCFF');
  document.documentElement.style.setProperty('--colorOnSurface', '#1A1C1E');
  
  document.documentElement.style.setProperty('--colorSurfaceVariant', '#DFE2EB');
  document.documentElement.style.setProperty('--colorOnSurfaceVariant', '#43474E');
  document.documentElement.style.setProperty('--outline', '#73777F');

  document.documentElement.style.setProperty('--colorSurface1', '#f0f4fa');
  document.documentElement.style.setProperty('--colorSurface2', '#e9f0f9');
  document.documentElement.style.setProperty('--colorSurface3', '#e1ebf6');
  document.documentElement.style.setProperty('--colorSurface4', '#dee9f4');
  document.documentElement.style.setProperty('--colorSurface5', '#d9e5f3');
};

export default setLightColor;