import { Avatar, Flex, Switch } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { JButton, JGoogleIcon, JTypography } from '../../../components'

const Area = (props: any) => {
    const { t } = useTranslation()

    return (
        <Flex
            padding={'24px 16px'}
            borderRadius={'16px'}
            borderColor={'neutral.30'}
            borderWidth={'1px'}
            direction={'column'}
            gap={'16px'}
        >
            <Flex justifyContent={'space-between'}>
                <JTypography size={'title-medium'}>{props.label}</JTypography>
                <Switch
                    isChecked={props.is_active}
                    height={'min-content'}
                    onClickCapture={props.onClickSwitch}
                />
            </Flex>
            <JTypography size={'body-medium'}>{props.address}</JTypography>
            <JTypography size={'body-medium'}>Radius: {props.radius} meter</JTypography>
            <Flex
                gap={'16px'}
                alignItems={'center'}
            >
                <Flex>
                    {props.members.map((member: any, index: number) => {
                        if (index <= 2)
                            return (
                                <Avatar
                                    key={index}
                                    width={'24px'}
                                    height={'24px'}
                                    name={member.user.display_name}
                                    src={member.user.photo_url}
                                    marginLeft={'-5px'}
                                />
                            )
                    })}
                </Flex>
                <JTypography size={'label-medium'}>
                    {t('pengaturan_absen_area_anggota_ditetepankan', { total: props.members.length })}
                </JTypography>
            </Flex>
            <Flex gap={'16px'}>
                <JButton
                    size={'normal'}
                    variant={'j-text'}
                    leftIcon={<JGoogleIcon name={'edit'} />}
                    onClick={props.onClickUpdate}
                >
                    {t('pengaturan_absen_edit')}
                </JButton>
                <JButton
                    size={'normal'}
                    variant={'j-text'}
                    leftIcon={<JGoogleIcon name={'delete'} />}
                    onClick={props.onClickDelete}
                >
                    {t('pengaturan_absen_hapus')}
                </JButton>
            </Flex>
        </Flex>
    )
}

export default Area
