import { 
    Avatar, 
    Checkbox, 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { 
    JButton, 
    JGoogleIcon, 
    JInput, 
    JTypography, 
} from '../../../components'

interface DefineMemberProps {
    is_open: boolean
    on_close: any
    on_back: any
    search_value: string
    search_on_change: any
    initial_area: any
    group_on_check: any
    member_on_check: any
    on_next: any
    selected_members: any
}

const DefineMember = (props: DefineMemberProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        on_back,
        search_value,
        search_on_change,
        initial_area,
        group_on_check,
        member_on_check,
        on_next,
        selected_members,
    } = props

    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            size={'xl'}
            blockScrollOnMount={true}
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'surfacePlus1'}>
                <JGoogleIcon
                    name={'arrow_back'}
                    cursor={'pointer'}
                    position={'absolute'}
                    top={'8px'}
                    left={'12px'}
                    onClick={on_back}
                />
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={'12px'}
                >
                    <JTypography size={'body-small'}>2/3</JTypography>
                    <JTypography size={'title-large'}>{t('pengaturan_absen_modal_tentukan_anggota_judul')}</JTypography>
                </ModalHeader>
                <ModalCloseButton onClick={on_close} />
                <ModalBody
                    as={Flex}
                    gap={'24px'}
                >
                    <Flex
                        direction={'column'}
                        gap={'8px'}
                        width={'50%'}
                    >
                        <JTypography size={'title-medium'}>{t('pengaturan_absen_modal_tentukan_anggota_label')}</JTypography>
                        <JTypography size={'body-medium'}>{t('pengaturan_absen_modal_tentukan_anggota_sublabel')}</JTypography>
                        <JTypography
                            size={'label-large'}
                            marginTop={'8px'}
                        >
                            {t('pengaturan_absen_modal_tentukan_anggota_cari_anggota_label')}
                        </JTypography>
                        <JInput
                            leftIcon={<JGoogleIcon name={'search'} />}
                            type={'search'}
                            value={search_value}
                            onChange={(e: any) => search_on_change(e.target.value)}
                            placeholder={t('pengaturan_absen_modal_tentukan_anggota_cari_anggota_pencarian')}
                        />
                        <Flex
                            direction={'column'}
                            gap={'18px'}
                        >
                            {initial_area.member_profile_id.map((member: any, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <Checkbox
                                            isChecked={member.checked}
                                            onChange={() => group_on_check(member)}
                                        >
                                            {member.display_name}
                                        </Checkbox>
                                        <Flex
                                            direction={'column'}
                                            gap={'24px'}
                                            marginLeft={'19px'}
                                        >
                                            {member.members.map((user: any, index: number) => {
                                                const MemberChooseMember = () => (
                                                    <Checkbox
                                                        key={index}
                                                        isChecked={user.checked}
                                                        gap={'16px'}
                                                        onChange={() => member_on_check(user)}
                                                    >
                                                        <Flex
                                                            gap={'24px'}
                                                            alignItems={'center'}
                                                        >
                                                            <Avatar name={user.display_name} />
                                                            <Flex
                                                                direction={'column'}
                                                                gap={'4px'}
                                                            >
                                                                <JTypography
                                                                    size='title-medium'
                                                                    wordBreak={'break-word'}
                                                                >
                                                                    {user.display_name}
                                                                </JTypography>
                                                                <JTypography
                                                                    size='body-medium'
                                                                    wordBreak={'break-word'}
                                                                >
                                                                    {user.username}
                                                                </JTypography>
                                                            </Flex>
                                                        </Flex>
                                                    </Checkbox>
                                                )
                                                if (search_value === '') {
                                                    return <MemberChooseMember />
                                                } else if (search_value !== '' && user.display_name.toLowerCase().includes(search_value)) {
                                                    return <MemberChooseMember />
                                                }
                                            })}
                                        </Flex>
                                    </Fragment>
                                )
                            })}
                        </Flex>
                    </Flex>
                    <Flex
                        width={'50%'}
                        direction={'column'}
                    >
                        {selected_members()}
                    </Flex>
                </ModalBody>
                <ModalFooter gap={'33px'}>
                    <JButton
                        size={'normal'}
                        variant={'j-primary'}
                        onClick={on_next}
                        leftIcon={<JGoogleIcon name={'navigate_next'} />}
                    >
                        {t('pengaturan_absen_lanjut')}
                    </JButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DefineMember