import React from 'react'
import { Heading, Stack } from '@chakra-ui/react'

const DesignSystemTypography: React.FC = () => {
    return (
        <Stack spacing='2rem'>
            <Stack>
                <Heading size='chakra_display_large'>Chakra Display Large</Heading>
                <Heading size='chakra_display_medium'>Chakra Display Medium</Heading>
                <Heading size='chakra_display_small'>Chakra Display Small</Heading>
            </Stack>

            <Stack>
                <Heading size='chakra_headline_large'>Chakra Headline Large</Heading>
                <Heading size='chakra_headline_medium'>Chakra Headline Medium</Heading>
                <Heading size='chakra_headline_small'>Chakra Headline Small</Heading>
            </Stack>

            <Stack>
                <Heading size='chakra_title_large'>Chakra Title Large</Heading>
                <Heading size='chakra_title_medium'>Chakra Title Medium</Heading>
                <Heading size='chakra_title_small'>Chakra Title Small</Heading>
            </Stack>

            <Stack>
                <Heading size='chakra_label_large'>Chakra Label Large</Heading>
                <Heading size='chakra_label_medium'>Chakra Label Medium</Heading>
                <Heading size='chakra_label_small'>Chakra Label Small</Heading>
            </Stack>

            <Stack>
                <Heading size='chakra_body_large'>Chakra Body Large</Heading>
                <Heading size='chakra_body_medium'>Chakra Body Medium</Heading>
                <Heading size='chakra_body_small'>Chakra Body Small</Heading>
            </Stack>
        </Stack>
    )
}

export default DesignSystemTypography