import React from 'react'
import { Flex } from '@chakra-ui/react'
import JTypography from '../../JTypography/JTypography'
import { useTranslation } from 'react-i18next'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'

interface IIncomingMessage {
    sender: string
    message: string
    time: string
    isDeleted: any
}

const IncomingMessage = (props: IIncomingMessage) => {
    const { t } = useTranslation()
    const { sender, message, time, isDeleted } = props

    return (
        <Flex flexDirection={'column'} gap={'8px'}>
            <JTypography size={'label-medium'} fontWeight='500' color='onSurface'>
                {sender}
            </JTypography>
            <Flex 
                backgroundColor={'primary.50'} 
                padding={'16px'} 
                borderRadius='0px 16px 16px 16px' 
                flexDirection={'column'}
                width='max-content'
                maxWidth='290px'
                minWidth='106px'
            >
                <Flex alignItems={'center'} gap={'6px'}>
                    <JTypography size={'body-medium'} color='onSurface' fontWeight='400' wordBreak='break-all'>
                        {isDeleted !== null ? `[${t('diskusi_tugas_pesan_telah_dihapus')}]` : message}
                    </JTypography>
                    {isDeleted !== null && <JGoogleIcon name={'hide_source'} fontSize={'body-medium'} />}
                </Flex>
                <JTypography size={'body-small'} marginTop={'5px'} color='onSurface' fontWeight={'400'} textAlign='right'>
                    {time.substr(11, 5)}
                </JTypography>
            </Flex>
        </Flex>
    )
}

export default IncomingMessage