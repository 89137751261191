import http from "./http";
import urlApi from "./url";

export const teamListApi = async () => {
  try {
    let response: any = await http(true).post(urlApi.team_list)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}

export const teamDetailApi = async () => {
  try {
    const team = localStorage.getItem('current_team') || ""
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)
    let response = await http(true).post(urlApi.team_list)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}