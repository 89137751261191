import { Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { JButton, JGoogleIcon, JTypography } from "../../../components"

const ButtonPage = () => {
    const { t } = useTranslation()

    return (
        <Flex direction={`column`} gap={`24px`}>
            <JTypography size="headline-large">{t("sidebar_pengembangan_tombol")}</JTypography>
            <Flex direction={`column`} gap={`12px`} >
                <Text>Primary</Text>
                <Flex gap={`12px`}>
                    <JButton 
                        variant="j-primary" 
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                    >
                        Button
                    </JButton>
                    <JButton 
                        variant="j-primary" 
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                        disabled
                    >
                        Button
                    </JButton>
                </Flex>
            </Flex>
            <Flex direction={`column`} gap={`12px`} >
                <Text>Outline</Text>
                <Flex gap={`12px`}>
                    <JButton
                        variant="j-outline"
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                    >
                        Button
                    </JButton>
                    <JButton
                        variant="j-outline"
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                        disabled
                    >
                        Button
                    </JButton>
                </Flex>
            </Flex>
            <Flex direction={`column`} gap={`12px`} >
                <Text>Text</Text>
                <Flex gap={`12px`}>
                    <JButton
                        variant="j-text"
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                    >
                        Button
                    </JButton>
                    <JButton
                        variant="j-text"
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                        disabled
                    >
                        Button
                    </JButton>
                </Flex>
            </Flex>
            <Flex direction={`column`} gap={`12px`} >
                <Text>Tonal</Text>
                <Flex gap={`12px`}>
                    <JButton
                        variant="j-tonal"
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                    >
                        Button
                    </JButton>
                    <JButton
                        variant="j-tonal"
                        size="normal"
                        leftIcon={<JGoogleIcon name="add" />}
                        disabled
                    >
                        Button
                    </JButton>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default ButtonPage