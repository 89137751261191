import React from 'react'
import JTypography from '../../JTypography/JTypography'
import ChatItem from './ChatItem'
import { useTranslation } from 'react-i18next'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Flex,
} from '@chakra-ui/react'

interface IChatListDrawer {
    isOpen: any
    onClose: any
    _ref: any
    chats: any
    _message_ref: any
    onClick: any
    setChatId: any
}

const ChatListDrawer = (props: IChatListDrawer) => {
    const { 
        onClose, 
        isOpen, 
        _ref, 
        chats,
        _message_ref,
        onClick,
        setChatId,
    } = props
    const { t } = useTranslation()

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={_ref}
            size='discussionDrawer'
        >
            <DrawerOverlay />
            <DrawerContent backgroundColor='background' width='372px'>
                <DrawerCloseButton marginTop='8px' />
                <DrawerHeader 
                    padding='15.5px 16px' 
                    shadow='lg' 
                >
                    <JTypography size='title-large' lineHeight='33px'>
                        {t('diskusi_tugas_judul')}
                    </JTypography>
                </DrawerHeader>

                <DrawerBody 
                    as={Flex} 
                    flexDirection='column'
                    marginTop='16px'
                    padding='0px'
                    maxWidth='372px'
                >
                    {chats?.map((chat: any, index: number) => {
                        return (
                            <ChatItem 
                                _ref={_message_ref}
                                key={index}
                                task_id={chat?.metadata?.task?.code}
                                title={chat?.title}
                                member_count={chat?.member_count}
                                unread_count={chat?.unread_count}
                                last_sender={chat?.last_message?.created_by?.display_name}
                                last_message={chat?.last_message?.data}
                                last_message_time={chat?.last_message?.datetime}
                                description={chat?.description}
                                isDeleted={chat?.last_message?.deleted_at === null ? false : true}
                                customerName={chat?.metadata?.task?.customer?.name}
                                customerPicture={chat?.metadata?.task?.customer?.photo}
                                isLastChild={index+1 !== chats?.length}
                                onClick={() => {
                                    onClick(chat?._id, chat?.metadata?.task_id)
                                }}
                            />
                        )
                    })}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default ChatListDrawer