import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { teamListApi, urlApi } from '../../apis';
import { teamDetailApi } from '../../apis/teamApi';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',

  list: null,

  // team detail
  isErrorTeamDetail: false,
  isSuccessTeamDetail: false,
  isLoadingTeamDetail: false,
  messageTeamDetail: '',
  teamDetail: null,
}

export const getTeams = createAsyncThunk(urlApi.team_list, async (_, thunkAPI) => {
  try {
    const response: any = await teamListApi()
    return response
  } catch (error) {
    const message = "error get teams"
    return thunkAPI.rejectWithValue(message)
  }
})

export const getTeamDetail = createAsyncThunk(`${urlApi.team_list}/detail`, async (_, thunkAPI) => {
  try {
    const response: any = await teamDetailApi()
    return response
  } catch (error) {
    const message = "error get team detail"
    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.list = null
    },

    resetTeamDetail: (state) => {
      state.isErrorTeamDetail = false
      state.isSuccessTeamDetail = false
      state.isLoadingTeamDetail = true
      state.messageTeamDetail = ''
      state.teamDetail = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.list = action.payload
      })
      .addCase(getTeams.rejected, (state, action: any) => {
        state.isLoading = false
        state.isError = true
        state.message = 'error get teams'
      })

      .addCase(getTeamDetail.pending, (state) => {
        state.isErrorTeamDetail = false
        state.isSuccessTeamDetail = false
        state.isLoadingTeamDetail = true
        state.messageTeamDetail = ''
        state.teamDetail = null
      })
      .addCase(getTeamDetail.fulfilled, (state, action) => {
        state.isLoadingTeamDetail = false
        state.isSuccessTeamDetail = true
        state.teamDetail = action.payload
      })
      .addCase(getTeamDetail.rejected, (state, action: any) => {
        state.isLoadingTeamDetail = false
        state.isErrorTeamDetail = true
        state.messageTeamDetail = 'error get team detail'
      })
  },
})

export const { reset, resetTeamDetail } = authSlice.actions
export default authSlice.reducer