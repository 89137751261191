import { Center, Flex, Spinner } from '@chakra-ui/react'
import { Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Header, Sidebar } from '../../components'
import { getMenus, getTeamDetail } from '../../redux'
import { urlRouter } from '../../router'
import style from './main-app.module.css'

const MainApp = () => {
  const location = useLocation()
  const dispatch = useDispatch<any>()
  const dontRenderSidebar = location.pathname === urlRouter.team
  const token = localStorage.getItem('access_token')
  const team = localStorage.getItem('current_team')
  let isHaveteam = null
  const isPageTeam = location.pathname === urlRouter.team

  if (isPageTeam) {
    isHaveteam = true
  } else {
    if (team) {
      isHaveteam = true
    } else {
      isHaveteam = false
    }
  }

  useEffect(() => {
    dispatch(getMenus())
    dispatch(getTeamDetail())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team])

  return (
    token
      ? isHaveteam
        ? <Flex>
            {!dontRenderSidebar && <Sidebar />}
            <Header />
            <Flex className={`${style.main_app} ${!dontRenderSidebar && style.main_sidebar_space}`}>
              <Suspense 
                fallback={
                  <Center width='100%'>
                    <Spinner />
                  </Center>
                }
              >
                <Outlet />
              </Suspense>
            </Flex>
          </Flex>
        : <Navigate to={urlRouter.team} />
      : <Navigate to={urlRouter.login} />
  )
}

export default MainApp