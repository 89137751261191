import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { 
    Circle, 
    GoogleMap, 
    MarkerF 
} from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import { 
    JButton, 
    JGoogleIcon, 
    JInput, 
    JTypography 
} from '../../../components'

interface LocationInformationProps {
    is_open: boolean
    on_close: any
    on_back: any
    initial_area: any
    render_selected_members: any
    on_submit: any
    is_loading: boolean
    label_value: string
    label_on_change: any
}

const LocationInformation = (props: LocationInformationProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        on_back,
        initial_area,
        render_selected_members,
        on_submit,
        is_loading,
        label_value,
        label_on_change,
    } = props

    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            size={'xl'}
            blockScrollOnMount={true}
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'surfacePlus1'}>
                <JGoogleIcon
                    name={'arrow_back'}
                    cursor={'pointer'}
                    position={'absolute'}
                    top={'8px'}
                    left={'12px'}
                    onClick={on_back}
                />
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={'12px'}
                >
                    <JTypography size={'body-small'}>3/3</JTypography>
                    <JTypography size={'title-large'}>{t('pengaturan_absen_modal_label_lokasi_judul')}</JTypography>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex gap={'24px'}>
                        <Flex
                            direction={'column'}
                            gap={'16px'}
                            width={'50%'}
                        >
                            <JTypography size='title-medium'>{t('pengaturan_absen_modal_label_lokasi_detail_lokasi_label')}</JTypography>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '346px',
                                    width: '100%',
                                    borderRadius: '16px',
                                }}
                                center={{
                                    lat: initial_area.latitude,
                                    lng: initial_area.longitude,
                                }}
                                zoom={15}
                                clickableIcons={false}
                            >
                                <MarkerF
                                    position={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude,
                                    }}
                                />
                                <Circle
                                    center={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude,
                                    }}
                                    radius={initial_area.radius}
                                    options={{
                                        strokeColor: 'transparent',
                                        fillColor: '#0079D2',
                                        fillOpacity: 0.19,
                                        zIndex: 2,
                                    }}
                                />
                            </GoogleMap>
                            <JTypography size='body-medium'>{initial_area.address}</JTypography>
                            <JTypography size='body-medium'>
                                {t('pengaturan_absen_area_besaran_radius', { radius: initial_area.radius })}
                            </JTypography>
                            {render_selected_members()}
                        </Flex>
                        <Flex
                            direction={'column'}
                            gap={'25px'}
                            width={'50%'}
                        >
                            <Flex
                                direction={'column'}
                                gap={'8px'}
                            >
                                <JTypography size={'label-large'}>{t('pengaturan_anggota_label_lokasi')}*</JTypography>
                                <JInput
                                    placeholder={t('pengaturan_anggota_label_lokasi')}
                                    value={label_value}
                                    onChange={label_on_change}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter
                    gap={'33px'}
                    marginTop={'33px'}
                >
                    <JButton
                        size={'normal'}
                        variant={'j-primary'}
                        margin={'auto'}
                        onClick={on_submit}
                        isLoading={is_loading}
                    >
                        {t('pengaturan_absen_simpan')}
                    </JButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default LocationInformation