import http from './http'
import urlApi from './url'
import md5 from 'md5'

export interface ApiGetGroupMembersProps {
    page: number
    search: string
}

export interface ApiGetMembersProps {}

export interface ApiCreateGroupMemberProps {
    display_name: string
    detail: string
}

export interface ApiGetGroupMemberProps {
    group_member_id: number
}

export interface ApiUpdateGroupMemberProps {
    group_member_id: number
    display_name: string
    detail: string
}

export interface ApiGetGroupMemberAccessProps {
    group_member_id: number
}

export interface ApiUpdateGroupMemberAccessProps {
    group_member_id: number
    access: string
}

export interface ApiGetCustomersProps {
    group_member_id: string
    page: number
    order_by: string
    order_type: string
    search: string
    is_active: number
}

export interface ApiGetCustomerGroupProps {
    group_member_id: number
}

export interface ApiUpdateCustomerGroupProps {
    group_member_id: number
    customer: string
}

export async function apiGetGroupMembers(props: ApiGetGroupMembersProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.groups, payload)
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch (error) {
        throw 'error get group members'
    }
}

export async function apiGetMembers(props: ApiGetMembersProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.members, payload)
        return response.data
    } catch (error) {
        throw 'error get members'
    }
}

export async function apiCreateGroupMember(props: ApiCreateGroupMemberProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const user = JSON.parse(localStorage.getItem('user_data') || '')
        const payload = {
            profile_id: team,
            code: md5(new Date()+user?.name),
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.create, payload)
        return response.data
    } catch (error) {
        throw 'error create group member'
    }
}

export async function apiGetGroupMember(props: ApiGetGroupMemberProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.group, payload)
        return response.data
    } catch (error) {
        throw 'error get group member'
    }
}

export async function apiUpdateGroupMember(props: ApiUpdateGroupMemberProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.updateGroup, payload)
        return response.data
    } catch (error) {
        throw 'error update group member'
    }
}

export async function apiGetGroupMemberAccess(props: ApiGetGroupMemberAccessProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.groupAccess, payload)
        return response.data
    } catch (error) {
        throw 'error get group member access'
    }
}

export async function apiUpdateGroupMemberAccess(props: ApiUpdateGroupMemberAccessProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.post.updateGroupAccess, payload)
        return response.data
    } catch (error) {
        throw 'error update group member access'
    }
}

export async function apiGetCustomers(props: ApiGetCustomersProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.get.customers, payload)
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch (error) {
        throw 'error get customers'
    }
}

export async function apiGetCustomerGroup(props: ApiGetCustomerGroupProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.get.customerGroup, payload)
        return response.data
    } catch (error) {
        throw 'error get customer group'
    }
}

export async function apiUpdateCustomerGroup(props: ApiUpdateCustomerGroupProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberGroup.update.customerGroup, payload)
        return response.data
    } catch (error) {
        throw 'error update customer group'
    }
}