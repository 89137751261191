import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Skeleton } from '@chakra-ui/react'
import { JButton, JTable, JTypography } from '../../../components'

interface ITableTaskType {
    data: any
    metadata: any
    handlePrevious: any
    handleNext: any
    isLoading: any
}

const TableTaskType = (props: ITableTaskType) => {
    const { t } = useTranslation()
    const {
        data,
        metadata,
        handlePrevious,
        handleNext,
        isLoading,
    } = props

    const tableData = useMemo(() => [...data], [data])
    const tableColumns = useMemo(
        () => [
            {
                Header: t("tipe_tugas_tabel_kode_tipe_tugas"),
                accessor: "code",
            },
            {
                Header: t("tipe_tugas_tabel_nama_tugas"),
                accessor: "name",
            },
        ],
        [t, data]
    )
    const tableHooks = (hooks: any) => {
        hooks.visibleColumns.push((tableColumns: any) => [
            {
                id: t("daftar_anggota_tabel_no"),
                Header: t("daftar_anggota_tabel_no"),
                Cell: ({ row }: any) => row.index + metadata.from,
            },

            ...tableColumns,
            {
                id: t("tipe_tugas_tabel_status"),
                Header: t("tipe_tugas_tabel_status"),
                Cell: ({ row }: any) =>
                    row.original.is_active === 1
                        ? t("customer_aktif")
                        : t("customer_inaktif"),
            },
        ]);
    };

    return (
        <Flex direction={"column"} gap={"0px"} w={"100%"}>
            {isLoading 
                ?   <Skeleton height={"571px"} width={"100%"} /> 
                :   <>
                        <JTable
                            data={tableData}
                            columns={tableColumns}
                            hooks={tableHooks}
                            max_width={`calc(100vw - 47px - 248px)`}
                        />
                        <Flex
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            backgroundColor={"surfacePlus4"}
                            padding={"8px 16px"}
                            borderBottomLeftRadius={"16px"}
                            borderBottomRightRadius={"16px"}
                        >
                            {isLoading || !data || !metadata ? (
                                <Skeleton height={"15px"} width={"215px"} />
                            ) : (
                                <JTypography size={"body-medium"}>
                                    {t("daftar_barang_tabel_keterangan", {
                                        from: metadata?.from,
                                        to: metadata?.to,
                                        total: metadata?.total,
                                    })}
                                </JTypography>
                            )}
                            <Flex gap={"10px"}>
                                <JButton
                                    size={"small"}
                                    variant={"j-primary"}
                                    isDisabled={isLoading || metadata?.current_page === 1}
                                    onClick={handlePrevious}
                                >
                                    {t("daftar_barang_tabel_sebelumnya")}
                                </JButton>
                                <JButton
                                    size={"small"}
                                    variant={"j-primary"}
                                    isDisabled={
                                        isLoading || metadata?.current_page === metadata?.last_page
                                    }
                                    onClick={handleNext}
                                >
                                    {t("daftar_barang_tabel_selanjutnya")}
                                </JButton>
                            </Flex>
                        </Flex>
                    </>
            }
        </Flex>
    )
}

export default TableTaskType