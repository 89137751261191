import { Avatar, Flex, Input, InputGroup, InputRightElement, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, PageTitle } from '../../components'
import { getLastLocation, getMemberPrivilages } from '../../redux'
import style from './last-location.module.css'
import { MemberItem } from './LastLocationComponents'
import { GoogleMap, MarkerClusterer, useLoadScript, MarkerF, InfoWindow } from '@react-google-maps/api'
import { IC_USER_NOT_FOUND } from '../../assets'
import { useTranslation } from 'react-i18next'

const LastLocation = () => {
    const [search, setSearch] = useState('')
    const dispatch = useDispatch<any>()
    const { t } = useTranslation()
    const { memberPrivilages, loadingGetMemberPrivilages } = useSelector((state: any) => state.member)
    const { lastLocation, successGetLastLocation, loadingGetLastLocation } = useSelector((state: any) => state.lastLocation)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBm33hfh55CLR5RH168wBbiFCxCNn06_pc',
    })
    const centerDefault = {
        lat: -6.2213866,
        lng: 106.7796252,
    }
    const zoomDefault = 10
    const [center, setCenter] = useState(centerDefault)
    const [zoom, setZoom] = useState(zoomDefault)
    const [target, setTarget] = useState<any>(null)

    function handleGetMemberPrivilages(search: string) {
        const data = {
            page: '',
            show: '',
            is_active: '',
            search: search,
            with_columns: '',
        }
        dispatch(getMemberPrivilages(data))
    }

    function handleGetLastLocation() {
        dispatch(getLastLocation())
    }

    function handleCheckDisplayPicture(photo: any) {
        const http = new XMLHttpRequest()
        http.open('HEAD', photo, false)
        http.send()
        if (http.status === 200) {
            return true
        } else {
            return false
        }
    }

    function handleClickMarker(location: any) {
        const tempCenter = {
            lat: location.latitude,
            lng: location.longitude,
        }
        const tempTarget = lastLocation.find((loc: any) => loc.user.id === location.user.id)
        setTarget(tempTarget)
        setCenter(tempCenter)
        setZoom(15)
    }

    function handleClickMember(member: any) {
        const tempLocation = lastLocation.find((loc: any) => loc.user.id === member.user.id)
        handleClickMarker(tempLocation)
    }

    useEffect(() => {
        handleGetMemberPrivilages('')
        handleGetLastLocation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Flex className={style.last_location}>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <PageTitle text={t('lokasi_judul')} />
                <Button
                    type='outline'
                    text={t('lokasi_perbarui_lokasi')}
                    leftIcon='sync'
                    loading={loadingGetLastLocation}
                    loadingText={t('lokasi_mendapatkan_lokasi')}
                    onClick={handleGetLastLocation}
                />
            </Flex>

            <Flex className={style.content_wrapper}>
                <Flex className={style.member_wrapper}>
                    {target === null && (
                        <InputGroup>
                            <InputRightElement children={<span className='material-symbols-outlined'>search</span>} />
                            <Input
                                type='search'
                                onChange={(e: any) => {
                                    setSearch(e.target.value)
                                    handleGetMemberPrivilages(e.target.value)
                                }}
                                value={search}
                            />
                        </InputGroup>
                    )}

                    {target === null && (
                        <Flex className={style.member_list}>
                            {loadingGetMemberPrivilages && (
                                <>
                                    <Skeleton
                                        borderRadius='5px'
                                        height='48px'
                                        width='100%'
                                    ></Skeleton>
                                    <Skeleton
                                        borderRadius='5px'
                                        height='48px'
                                        width='100%'
                                    ></Skeleton>
                                    <Skeleton
                                        borderRadius='5px'
                                        height='48px'
                                        width='100%'
                                    ></Skeleton>
                                </>
                            )}
                            {!loadingGetMemberPrivilages &&
                                memberPrivilages !== null &&
                                memberPrivilages.length !== 0 &&
                                memberPrivilages.map((member: any, index: number) => {
                                    return (
                                        <MemberItem
                                            key={index}
                                            profile_picture={member.user.photo_url}
                                            fullname={member.user.display_name}
                                            email={member.user.username}
                                            onClick={() => handleClickMember(member)}
                                        />
                                    )
                                })}
                        </Flex>
                    )}
                    {target !== null && (
                        <Flex
                            direction='column'
                            gap='10px'
                            overflow='scroll'
                            height='100%'
                        >
                            <span
                                onClick={() => setTarget(null)}
                                className={`material-symbols-outlined ${style.icon} ${style.pointer}`}
                            >
                                arrow_back
                            </span>
                            <Flex
                                gap='10px'
                                alignItems='center'
                            >
                                <Avatar
                                    src={target.user.photo_url}
                                    name={target.user.display_name}
                                />
                                <Text className={style.text}>{target.user.display_name}</Text>
                            </Flex>
                            <Flex
                                direction='column'
                                gap='5px'
                            >
                                <Text className={style.text}>{t('lokasi_posisi_sekarang')}</Text>
                                <Text className={style.text_sm}>{target.address}</Text>
                            </Flex>
                            <Text className={style.text_sm}>{t('lokasi_diperbarui_pada', { time: target.datetime })}</Text>
                            <Flex align='center'>
                                <span className={`material-symbols-outlined ${style.icon}`}>smartphone</span>
                                <Text className={style.text_sm}>{target.device_name}</Text>
                            </Flex>
                            <Flex gap='10px'>
                                <Flex alignItems='center'>
                                    <span className={`material-symbols-outlined ${style.icon}`}>battery_4_bar</span>
                                    <Text className={style.text_sm}>{target.battery_level}%</Text>
                                </Flex>
                                <Flex alignItems='center'>
                                    <span className={`material-symbols-outlined ${style.icon}`}>signal_cellular_2_bar</span>
                                    <Text className={style.text_sm}>{target.signal_strength} bars</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
                <Flex className={style.map_wrapper}>
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '16px',
                            }}
                            center={center}
                            zoom={zoom}
                            clickableIcons={false}
                        >
                            {successGetLastLocation && (
                                <MarkerClusterer maxZoom={10}>
                                    {(clusterer) =>
                                        lastLocation?.map((location: any, index: number) => {
                                            return (
                                                <MarkerF
                                                    key={index}
                                                    position={{
                                                        lat: Number(location.latitude),
                                                        lng: Number(location.longitude),
                                                    }}
                                                    clusterer={clusterer}
                                                    icon={{
                                                        // url: handleCheckDisplayPicture(location.user.photo_url) === true ? location.user.photo_url : IC_USER_NOT_FOUND,
                                                        url: IC_USER_NOT_FOUND,
                                                        rotation: 1,
                                                        scaledSize: { width: 35, height: 35, equals: () => true },
                                                    }}
                                                    onClick={() => handleClickMarker(location)}
                                                >
                                                    {target?.user.id === location.user.id && (
                                                        <InfoWindow
                                                            position={{
                                                                lat: Number(location.latitude),
                                                                lng: Number(location.longitude),
                                                            }}
                                                            onCloseClick={() => {
                                                                if (target.user.id === location.user.id) {
                                                                    setTarget(null)
                                                                }
                                                            }}
                                                        >
                                                            <Flex
                                                                color='black'
                                                                p='16px'
                                                            >
                                                                {location.user.display_name}
                                                            </Flex>
                                                        </InfoWindow>
                                                    )}
                                                </MarkerF>
                                            )
                                        })
                                    }
                                </MarkerClusterer>
                            )}
                        </GoogleMap>
                    ) : (
                        <Skeleton
                            height='100%'
                            width='100%'
                            borderRadius='16px'
                        ></Skeleton>
                    )}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default LastLocation
