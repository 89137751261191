import { Flex } from '@chakra-ui/react'
import JGoogleIcon from '../JGoogleIcon/JGoogleIcon'
import JTypography from '../JTypography/JTypography'

interface JToastProps {
    type: 'success' | 'error' | 'warning' | 'chat'
    message: string
}

const JToast = (props: JToastProps) => {
    return (
        <Flex
            padding={'24px'}
            gap={'18px'}
            borderRadius={'16px'}
            backgroundColor={
                props.type === 'success' 
                    ?   'successContainer' 
                    :   props.type === 'chat'
                        ?   'surface'
                        :   'errorContainer'
            }
            alignItems={'center'}
        >
            <JGoogleIcon
                name={
                    props.type === 'success' 
                        ?   'task_alt' 
                        :   props.type === 'chat' 
                            ?   'chat'
                            :   'error'
                }
                color={
                    props.type === 'success' 
                        ?   'onSuccessContainer' 
                        :   props.type === 'chat'
                            ?   'unset'
                            :   'onErrorContainer'
                }
            />
            <JTypography
                size='title-medium'
                color={
                    props.type === 'success' 
                        ?   'onSuccessContainer' 
                        :   props.type === 'chat'
                            ?   'unset'
                            :   'onErrorContainer'
                }
            >
                {props.message}
            </JTypography>
        </Flex>
    )
}

export default JToast
