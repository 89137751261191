import http from "./http";
import urlApi from "./url";
export interface GetTaskASsignmentProps {
  task_assignment_id: number
}
export interface GetTaskTypeProps {
  task_type_id: string
}
export const taskTypeApi = async (team: any, page: any, show: any, order_by: any, order_type: any, search: any, is_active: any) => {
  try {

    const bodyPayload = new FormData()
    team && bodyPayload.set('profile_id', team)
    page && bodyPayload.set('page', page)
    show && bodyPayload.set('show', show)
    order_by && bodyPayload.set('order_by', order_by)
    order_type && bodyPayload.set('order_type', order_type)
    search && bodyPayload.set('search', search)
    is_active && bodyPayload.set('is_active', is_active)

    let data: any = {}
    let response: any = await http(true).post(urlApi.taskType, bodyPayload)
    data.data = response.data
    data.metadata = response.metadata
    return data
  } catch (error) {
    console.error(error);
    return null
  }
}

export const taskSettingToggleCheckinApi = async (is_checkin_strict_location: any) => {
  try {
    const teamId = localStorage.getItem('current_team') || ""
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', teamId)
    bodyPayload.set('is_checkin_strict_location', is_checkin_strict_location)

    await http(true).post(urlApi.taskSettingToggleCheckIn, bodyPayload)
    return true
  } catch (error) {
    console.error(error);
    return null
  }
}

export const taskSettingEditRadiusApi = async (strict_checkin_radius: any) => {
  try {
    const teamId = localStorage.getItem('current_team') || ""
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', teamId)
    bodyPayload.set('strict_checkin_radius', strict_checkin_radius)

    await http(true).post(urlApi.taskSettingEditRadius, bodyPayload)
    return true
  } catch (error) {
    console.error(error);
    return null
  }
}
export async function getTaskAssignment(props: GetTaskASsignmentProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props,
  }
  const response: any = await http(true).post(urlApi.taskAssignment, payload)
  return response.data
}
export async function getTaskType(props: GetTaskTypeProps) {
  const team = localStorage.getItem('current_team') || ''
  const payload = {
    profile_id: team,
    ...props,
  }
  const response: any = await http(true).post(urlApi.taskTypeId, payload)
  return response.data
}
