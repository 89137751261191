import { Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import style from './page-title.module.css'

interface PageTitleProps {
  text: string
}

const PageTitle = (props: PageTitleProps) => {
  return (
    <Text 
      as={motion.p}
      className={style.title}
    >
      {props.text}
    </Text>
  )
}

export default PageTitle