import http from "./http";
import urlApi from "./url";

export const memberPrivilageList = async (team: any, page: any, show: any, is_active: any, search: any, with_columns: any) => {
  try {
    const bodyPayload = new FormData()
    team && bodyPayload.set('profile_id', team)
    page && bodyPayload.set('page', page)
    show && bodyPayload.set('show', show)
    is_active && bodyPayload.set('is_active', is_active)
    search && bodyPayload.set('search', search)
    with_columns && bodyPayload.set('with_columns', with_columns)

    let data: any = {}
    let response: any = await http(true).post(urlApi.member_privilage_list, bodyPayload)
    data.data = response.data
    data.metadata = response.metadata
    return data
  } catch (error) {
    console.error(error);
    return null
  }
}

export const memberList = async (team: any, page: any, show: any, is_active: any, search: any, with_columns: any) => {
  try {
    const bodyPayload = new FormData()
    team && bodyPayload.set('profile_id', team)
    page && bodyPayload.set('page', page)
    show && bodyPayload.set('show', show)
    is_active && bodyPayload.set('is_active', is_active)
    search && bodyPayload.set('search', search)
    with_columns && bodyPayload.set('with_columns', with_columns)

    let data: any = {}
    let response: any = await http(true).post(urlApi.member, bodyPayload)
    data.data = response.data
    data.metadata = response.metadata
    return data
  } catch (error) {
    console.error(error);
    return null
  }
}